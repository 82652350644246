import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Tooltip } from "react-tooltip";
import { Amount, Unit } from "uom";
import { Quantity, Units } from "uom-units";
import { AnyQuantity, convertTo, amountAs } from "@lcc/shared/src/uom";
import { AnnualOperationTimeInput, CalculationInputs } from "@lcc/shared/src/calculation";
import { ClimateCountry, texts, TranslateFn } from "@lcc/shared";
import * as SharedState from "../../../../../infrastructure/shared-state";
import { Action, DataReceived } from "../state";
import * as Page from "../../page";
import { getNumberFormat, getUserUnits } from "../../../../../infrastructure/shared-state";
import {
  ColumnValue,
  InfoIcon,
  InputAmountRow,
  InputSelectRadioRow,
  InputSelectRow,
  RequiredField,
  Row,
  SubHeading,
} from "./shared";
// import { createGetUnitInfoFunction } from "../../result/units";
// import { Input } from "../../../../../components/elements";

export function Conditions({
  sharedState,
  dispatch,
  pageState,
  state,
}: {
  readonly state: DataReceived;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly pageState: Page.StateCalculation;
}): JSX.Element {
  const translate = sharedState.lang.translate;
  const userUnits = getUserUnits(sharedState);

  const { calculationInput } = pageState;
  const numberFormat = getNumberFormat(sharedState.lang);

  return (
    <div>
      <SubHeading>{translate(texts.conditions)}</SubHeading>

      {/* <InputSelectTabRow
        required={true}
        translate={translate}
        name={translate(texts.annual_average_temperature_input)}
        multi={false}
        value={calculationInput.annualAverageTemperatureInput || "manual"}
        onValueChange={(v: AnnualAverageTemperatureInput | undefined) => {
          dispatch(
            Action.SetCalcInput({
              annualAverageTemperatureInput: v,
              annualAverageTemperatureCountry: undefined,
              annualAverageTemperatureLocation: undefined,
            })
          );
          if (v === "selection") {
            dispatch(
              Action.SetCalcInput({
                annualAverageTemperature: undefined,
              })
            );
          }
        }}
        options={[
          { value: "selection", text: translate(texts.selecting_location) },
          // { value: "manual", text: translate(texts.manual_input) },
        ]}
      />
      {calculationInput.annualAverageTemperatureInput !== "selection" ? (
        <InputAmountRow
          required={true}
          autoFocusOnLoad={true}
          translate={translate}
          name={translate(texts.annual_average_temperature)}
          value={calculationInput.annualAverageTemperature}
          defaultUnit={Units.Celsius}
          userUnits={userUnits}
          numberFormat={numberFormat}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          multi={false}
          onValueChange={(value: Amount.Amount<Quantity.Temperature> | undefined) =>
            dispatch(
              Action.SetCalcInput({
                annualAverageTemperature: value,
              })
            )
          }
        />
      ) : ( */}

      <InputSelectRow
        hideUnitColumn={true}
        required={true}
        autoFocusOnLoad={true}
        translate={translate}
        name={translate(texts.country_selection)}
        fieldName={"countrySelection"}
        multi={false}
        value={calculationInput.annualAverageTemperatureCountry}
        onValueChange={(v) => {
          const found = state.climateData.find((country) => country.countryId === v);

          const selectedCountry = state.carbonEmissionTable.find((row) => row.country === found?.countryName);

          const selectedCountryCarbonEmission = selectedCountry?.id || undefined;

          dispatch(
            Action.SetCalcInput({
              carbonEmissionCountry: selectedCountryCarbonEmission,

              annualAverageTemperatureCountry: v,
              annualAverageTemperatureLocation: "",
              annualAverageTemperature: undefined,
            })
          );
        }}
        placeholderText={translate(texts.select_an_option)}
        options={[
          ...state.climateData.map((data) => ({ text: data.countryName, value: data.countryId, key: data })),
        ].sort((a, b) => (a.text > b.text ? 1 : -1))}
      />

      {calculationInput.annualAverageTemperatureCountry &&
        state.climateData &&
        state.climateData.find((country) => country.countryId === calculationInput.annualAverageTemperatureCountry) && (
          <>
            <InputLocationRow
              translate={translate}
              dispatch={dispatch}
              pageState={pageState}
              state={state}
              country={calculationInput.annualAverageTemperatureCountry}
              fieldName={"locationSelection"}
              name="Location"
              message={translate(texts.location_info_message)}
            />
            {calculationInput.annualAverageTemperatureLocation !== "" && (
              <div className="ml-[11.5rem] mt-4">
                <RequiredField field={calculationInput.annualAverageTemperatureLocation} translate={translate} />
              </div>
            )}
          </>
        )}

      {calculationInput.annualAverageTemperature?.value &&
      calculationInput.annualAverageTemperatureInput !== "manual" ? (
        <table className="pr-[22px] ml-[-5px]">
          <thead>
            <tr>
              <th className={"mt-20"}>{translate(texts.annual_average_temperature)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {calculationInput.annualAverageTemperature?.value.toFixed(2) +
                  "\t" +
                  calculationInput.annualAverageTemperature.unit.name}
              </td>
            </tr>
          </tbody>
        </table>
      ) : undefined}
      {calculationInput.annualAverageTemperatureInput !== "manual" ? (
        <div>
          <p className={"mb-36 pr-[22px] ml-[-5px]"}>
            © 2021 ASHRAE,
            <a href={"http://www.ashrae.org"} target={"_blank"}>
              <span className="font-bold"> www.ashrae.org</span>
            </a>
            <span> {translate(texts.ashrae_permission_info)}</span>
          </p>
        </div>
      ) : undefined}

      <InputSelectRadioRow
        required={true}
        translate={translate}
        name={translate(texts.annual_operation_time_input)}
        multi={false}
        value={calculationInput.annualOperationTimeInput || "total"}
        onValueChange={(v) => {
          dispatch(
            Action.SetCalcInput({
              annualOperationTimeInput: v as AnnualOperationTimeInput,
            })
          );
        }}
        options={[
          { value: "total", text: translate(texts.operation_time_input_total) },
          { value: "detailed", text: translate(texts.operation_time_input_detailed) },
        ]}
      />

      {calculationInput.annualOperationTimeInput === "detailed" ? (
        <>
          <InputAmountRow
            hideUnitColumn={true}
            compactUnitSelector={true}
            singleColumnValue={true}
            required={true}
            translate={translate}
            message={translate(texts.operation_time_detailed_workdays_message)}
            key={"annualOperationTimeWorkdays"}
            name={translate(texts.operation_time_mon_fri)}
            value={calculationInput.annualOperationTimeWorkdays}
            defaultUnit={Units.Hour}
            numberFormat={numberFormat}
            userUnits={userUnits}
            fieldName={"operationTime"}
            unitCallback={(unit: Unit.Unit<AnyQuantity>) =>
              dispatch(Action.SetFieldUnit("operationTime", unit.name, 2))
            }
            multi={false}
            onValueChange={(value: Amount.Amount<Quantity.Duration> | undefined) =>
              dispatch(
                Action.SetCalcInput({
                  annualOperationTimeWorkdays: value,
                })
              )
            }
          />

          <InputAmountRow
            hideUnitColumn={true}
            compactUnitSelector={true}
            singleColumnValue={true}
            required={true}
            translate={translate}
            message={translate(texts.operation_time_detailed_weekends_message)}
            key={"annualOperationTimeWeekends"}
            name={translate(texts.operation_time_sat_sun)}
            value={calculationInput.annualOperationTimeWeekends}
            defaultUnit={Units.Hour}
            numberFormat={numberFormat}
            userUnits={userUnits}
            fieldName={"operationTime"}
            unitCallback={(unit: Unit.Unit<AnyQuantity>) =>
              dispatch(Action.SetFieldUnit("operationTime", unit.name, 2))
            }
            multi={false}
            onValueChange={(value: Amount.Amount<Quantity.Duration> | undefined) =>
              dispatch(
                Action.SetCalcInput({
                  annualOperationTimeWeekends: value,
                })
              )
            }
          />
        </>
      ) : (
        <InputAmountRow
          hideUnitColumn={true}
          compactUnitSelector={true}
          singleColumnValue={true}
          required={true}
          translate={translate}
          name={translate(texts.annual_operation_time)}
          value={calculationInput.annualOperationTime}
          defaultUnit={Units.Hour}
          numberFormat={numberFormat}
          userUnits={userUnits}
          fieldName={"operationTime"}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit("operationTime", unit.name, 2))}
          multi={false}
          onValueChange={(value: Amount.Amount<Quantity.Duration> | undefined) =>
            dispatch(
              Action.SetCalcInput({
                annualOperationTime: value,
              })
            )
          }
          errorMsg={(_, value, _2, currentUnit) => {
            if (!value) {
              return "";
            }
            const val = amountAs(currentUnit, value);
            const maxVal = convertTo(8760, Units.Hour, currentUnit);
            if (val > maxVal) {
              return translate(texts.value_should_be_between("0", maxVal.toString()));
            }
            return "";
          }}
        />
      )}

      <InputSelectRow
        hideUnitColumn={true}
        required={true}
        translate={translate}
        name={translate(texts.carbon_emission_country)}
        multi={false}
        value={calculationInput.carbonEmissionCountry}
        onValueChange={(v) => {
          dispatch(
            Action.SetCalcInput({
              carbonEmissionCountry: v,
            })
          );
        }}
        placeholderText={translate(texts.select_an_option)}
        options={state.carbonEmissionTable.map((row) => ({
          value: row.id || "",
          text: row.id ? translate(texts.carbon_emission_country_code(row.id.toLowerCase())) : "",
          key: row,
        }))}
      />

      {state.carbonEmissionTable.map((row) =>
        row.electricity && row.id === calculationInput.carbonEmissionCountry ? (
          <table key={row.id} className="pr-[22px] ml-[-5px]">
            <thead>
              <tr>
                <th className={"mt-20"}>{translate(texts.carbon_emission_number_electricity)}</th>
              </tr>
            </thead>
            <tbody>
              <tr key={row.id}>
                <td>{row.electricity + " gCO₂/kWh"}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          ""
        )
      )}
      <div>
        <>
          <p className="mb-26 pr-[22px] ml-[-5px]">
            {translate(texts.carbon_emission_info_first) + ":"}
            <a href={"https://ourworldindata.org/grapher/carbon-intensity-electricity?tab=table"} target={"_blank"}>
              <span className="font-bold"> {translate(texts.carbon_emission_info_second)}</span>
            </a>
          </p>
        </>
      </div>

      <InputAmountRow
        singleColumnValue={true}
        required={true}
        translate={translate}
        name={translate(texts.supply_air_temp)}
        multi={false}
        numberFormat={numberFormat}
        value={calculationInput.supplyAirTemperature}
        userUnits={userUnits}
        defaultUnit={Units.Celsius}
        unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
        onValueChange={(value: Amount.Amount<Quantity.Temperature> | undefined) =>
          dispatch(
            Action.SetCalcInput({
              supplyAirTemperature: value,
            })
          )
        }
        errorMsg={(_, value, defaultUnit, currentUnit) => {
          const val = value && amountAs(defaultUnit, value);
          const minTemp = 15;
          const maxTemp = 22;
          return val && (val < minTemp || val > maxTemp)
            ? translate(
                texts.value_should_be_between(
                  convertTo(minTemp, Units.Celsius, currentUnit).toString(),
                  convertTo(maxTemp, Units.Celsius, currentUnit).toString()
                )
              )
            : "";
        }}
      />

      <InputAmountRow
        singleColumnValue={true}
        required={true}
        translate={translate}
        key="extract_air_temp"
        name={translate(texts.extract_air_temp)}
        multi={false}
        numberFormat={numberFormat}
        value={calculationInput.exhaustAirTemperature}
        userUnits={userUnits}
        defaultUnit={Units.Celsius}
        unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
        onValueChange={(value: Amount.Amount<Quantity.Temperature> | undefined) =>
          dispatch(
            Action.SetCalcInput({
              exhaustAirTemperature: value,
            })
          )
        }
        errorMsg={(_, value, defaultUnit, currentUnit) => {
          const val = value && amountAs(defaultUnit, value);
          const minTemp = 18;
          const maxTemp = 25;

          return calculationInput.exhaustAirTemperature?.unit.name === "Celsius" &&
            val &&
            calculationInput.supplyAirTemperature?.value &&
            (val < minTemp || val > maxTemp || calculationInput.supplyAirTemperature?.value > val)
            ? translate(
                texts.validation_extract_air_temp(
                  convertTo(minTemp, Units.Celsius, currentUnit).toString(),
                  convertTo(maxTemp, Units.Celsius, currentUnit).toString()
                )
              )
            : calculationInput.exhaustAirTemperature?.unit.name === "Fahrenheit" &&
              val &&
              (val < minTemp || val > maxTemp)
            ? translate(
                texts.value_should_be_between(
                  convertTo(minTemp, Units.Celsius, currentUnit).toString(),
                  convertTo(maxTemp, Units.Celsius, currentUnit).toString()
                )
              )
            : "";
        }}
      />
    </div>
  );
}

// eslint-disable-next-line functional/no-let
let tooltip_id_counter = 0;

function InputLocationRow(props: {
  readonly name?: string;
  readonly country?: string | ClimateCountry;
  readonly state: DataReceived;
  readonly dispatch: Dispatch<Action>;
  readonly pageState: Page.StateCalculation;
  readonly translate: TranslateFn;
  readonly message?: string;
  readonly fieldName: string;
}): JSX.Element {
  const toolTipLocationId = React.useMemo(() => `tooltip_location_${tooltip_id_counter++}`, []);
  const { calculationInput } = props.pageState;
  const [showingLocationNotFoundMessage, setShowingLocationNotFoundMessage] = React.useState<string | null | undefined>(
    null
  );

  return (
    <>
      <Row className="">
        <div style={{ color: "#C23318" }}>
          {!calculationInput.annualAverageTemperatureLocation &&
          calculationInput.annualAverageTemperatureInput !== "manual" &&
          calculationInput.annualAverageTemperatureCountry &&
          calculationInput.annualAverageTemperatureLocation !== "" &&
          showingLocationNotFoundMessage ? (
            <div className="font-bold ml-[19.5rem]">{props.translate(texts.invalid_location_message)}</div>
          ) : null}
        </div>
      </Row>
      <Row>
        <div className="flex-1 flex flex-row space-x-4 max-w-[33.3%] items-center justify-between pr-[22px] ml-[-5px]">
          <div className="font-bold">{props.name}</div>
          <div>
            <a data-tooltip-id={toolTipLocationId}>
              <InfoIcon message={props.message} className="ml-4 cursor-pointer" />
            </a>
            <Tooltip
              className="z-11 max-w-[700px]"
              id={toolTipLocationId}
              content={props.message}
              events={["click", "hover"]}
            />
          </div>
        </div>
        <ColumnValue>
          <input
            className="w-full form-input border py-6 px-6 h-24 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light spin-button-none"
            onChange={(v) => {
              const location = v.currentTarget.value;

              const wmo = props.state.climateData
                .find((country) => country.countryId === calculationInput.annualAverageTemperatureCountry)
                ?.locations.find((city) => city.locationName === location)?.wmo;

              const showLocationName = props.state.climateData
                .find((country) => country.countryId === calculationInput.annualAverageTemperatureCountry)
                ?.locations.find((city) => city.locationName === location)?.locationName;

              setShowingLocationNotFoundMessage(location);

              props.dispatch(
                Action.SetCalcInput({
                  annualAverageTemperatureLocation: showLocationName,
                })
              );

              if (wmo === undefined) {
                props.dispatch(Action.SetCalcInput({ annualAverageTemperature: undefined }));
              } else {
                props.dispatch(
                  Action.CalculateAverageTemperatureLocation(calculationInput.annualAverageTemperatureCountry!, wmo)
                );
              }
            }}
            type="text"
            list="list-display"
            name="display"
            id="display"
            value={calculationInput.annualAverageTemperatureLocation}
            placeholder="Search Location Here"
            autoComplete="off"
          />
          <datalist id="list-display" key="dataListKey">
            {props.state.climateData
              .find((country) => country.countryId === calculationInput.annualAverageTemperatureCountry)!
              .locations.map((data) => data.locationName)
              .sort((a, b) => (a > b ? 1 : -1))
              .map((choice) => (
                <option key={choice} value={choice} />
              ))}
          </datalist>
        </ColumnValue>

        {/* <div className="flex-1 max-w-unit-column"></div> */}
      </Row>
    </>
  );
}

export function validateConditions(input: CalculationInputs): boolean {
  if (
    ((input.annualAverageTemperatureInput === "manual" && input.annualAverageTemperature) ||
      (input.annualAverageTemperatureInput === "selection" &&
        input.annualAverageTemperatureCountry &&
        input.annualAverageTemperatureLocation)) &&
    ((input.annualOperationTimeInput === "total" &&
      input.annualOperationTime &&
      validOperationTime(input.annualOperationTime, input.annualOperationTime.unit)) ||
      (input.annualOperationTimeInput === "detailed" &&
        input.annualOperationTimeWeekends &&
        input.annualOperationTimeWorkdays)) &&
    input.currentEnergyPrice &&
    input.currentHeatingPrice &&
    input.carbonEmissionCountry &&
    input.supplyAirTemperature &&
    validSupplyAirTemperature(input.supplyAirTemperature) &&
    input.exhaustAirTemperature &&
    validExhaustAirTemperature(input.exhaustAirTemperature, input.supplyAirTemperature)
  ) {
    return true;
  }

  return false;
}

const validOperationTime = (value: Amount.Amount<Quantity.Duration>, currentUnit: Unit.Unit<AnyQuantity>): boolean => {
  if (!value) {
    return true;
  }
  const val = amountAs(currentUnit, value);
  const maxVal = convertTo(8760, Units.Hour, currentUnit);
  if (val > maxVal) {
    return false;
  }
  return true;
};

const validSupplyAirTemperature = (value: Amount.Amount<Quantity.Temperature>): boolean => {
  const val = value && amountAs(Units.Celsius, value);
  const minTemp = 15;
  const maxTemp = 22;

  return val && (val < minTemp || val > maxTemp) ? false : true;
};

const validExhaustAirTemperature = (
  value: Amount.Amount<Quantity.Temperature>,
  validSupplyAirTemperature: Amount.Amount<Quantity.Temperature>
): boolean => {
  const val = value && amountAs(Units.Celsius, value);
  const minTemp = 18;
  const maxTemp = 25;

  if (validSupplyAirTemperature?.value === undefined) {
    return false;
  }

  return val && (val < minTemp || val > maxTemp || validSupplyAirTemperature?.value > val) ? false : true;
};
