import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { createRoute, NoParams, UrlMatch } from "../../route";

/**
 * How the routing works:
 *
 * The basic building block is a "Route" object which only has to pure functions:
 * - parseUrl(string) => Location
 * - buildUrl(Location) => string
 * Location is a union type of all locations available in the application.
 * Each location type is a data-only object that represents a location within the application.
 * Each location can have its own params which are url-segments or query params.
 *
 * As the application grows, having global routes is not practical becuse we need to
 * match all locations in all parts of the application. Therefore we nest the locations
 * so a root location may have child locations. A nested location has a location prop that
 * will contain the child location. So the root location object will be a wrapper for the
 * child location object. However the Route objects are not nested but they can create a nested location.
 * The URLs are always matched as root urls by the parseUrl/buildUrl functions in the Route
 * but the function can produce a nested/wrapped location.
 * The nested location object helps in the fractal style of nested in of views/state so a parent
 * view can pass the location inside the nested location down to a child view.
 * TL;DR: The locations are nested but the Routes are NOT nested.
 *
 * The parsing and the building of the URL can be generalized (but the parameters handling cannot
 * since the parameters are different for every route).
 * So we only need to provide functions that maps Params->Location and vice versa.
 *
 * Only the top-level init() and update(UrlChanged) should handle the url as a string and parse it.
 * Once it is parsed the rest of the application will only see it as a Location.
 * The location can be passed down in child init() functions and be unwrapped if nested.
 * The child location should not be stored in the child state becuase then we don't have a single source of truth.
 * Instead the location can be stored in sharedstate and each child can have a function to retrieve its location.
 *
 * When the url is changed (regardless of how it was changed) this occurs:
 * 1. The url that is being navigated to is parsed into a Location.
 * 2. The location is stored in state.
 * 3. The view renders based on location stored in state.
 * When you want to build an Url for a location, eg. for use in a <a href="xxx">
 * you call the buildUrl() function passing the location you want.
 *
 */

export const RootLocation = ctorsUnion({
  LoginCallback: () => ({}),
  LoggedOut: () => ({}),
  MainLocation: (location: MainLocation) => ({ location }),
});
export type RootLocation = CtorsUnion<typeof RootLocation>;

export const MainLocation = ctorsUnion({
  Lcc: (location: LccLocation) => ({ location }),
  UserSettings: () => ({}),
  StartPage: () => ({}),
});
export type MainLocation = CtorsUnion<typeof MainLocation>;

export const LccLocation = ctorsUnion({
  Calculate: (unitId: string) => ({ params: { unitId } }),
  Result: (unitId: string) => ({ params: { unitId } }),
});
export type LccLocation = CtorsUnion<typeof LccLocation>;

// This object cannot have an explicit type becuse then type inference for each key is lost
// Each key in this map contains a "Route" which is just a parse and buildUrl function for that route
const rootRoutes = {
  LoginCallback: createRoute("/login-callback", RootLocation.LoginCallback, NoParams),
  LoggedOut: createRoute("/logged-out", RootLocation.LoggedOut, NoParams),
  MainLocation: createRoute(
    "/:rest(.*)",
    (params) => {
      // The rest of the url should be parsed by the main parser....
      const match = parseMainUrl(params["rest"]);
      return match?.location && RootLocation.MainLocation(match.location);
    },
    (location) => {
      const mainUrl = buildMainUrl(location.location);
      return { rest: mainUrl };
    },
    (a) => a,
    false
  ),
};

// This object cannot have an explicit type becuse then type inference for each key is lost
// Each key in this map contains a "Route" which is just a parse and buildUrl function for that route
const mainRoutes = {
  UserSettings: createRoute(
    "user-settings",
    () => MainLocation.UserSettings(),
    () => ({})
  ),
  StartPage: createRoute(
    "start",
    () => MainLocation.StartPage(),
    () => ({})
  ),

  LccCalculation: createRoute(
    "lcc/:rest(.*)",
    (params) => {
      // The rest of the url should be parsed by the unit editor parser....
      const match = parseUnitEditorUrl(params["rest"]);
      return match?.location && MainLocation.Lcc(match.location);
    },
    (location) => {
      const ueUrl = buildUnitEditorUrl(location.location);
      return { rest: ueUrl };
    },
    (a) => a,
    false
  ),
};

// This object cannot have an explicit type becuse then type inference for each key is lost
// The functions are used to map the params to and from a location
const lccRoutes = {
  Calculate: createRoute(
    "calculate",
    (params) => LccLocation.Calculate(params["unitId"]),
    (location) => location.params
  ),
  Result: createRoute(
    "result",
    (params) => LccLocation.Result(params["unitId"]),
    (location) => location.params
  ),
};

function buildUnitEditorUrl(location: LccLocation): string {
  switch (location.type) {
    case "Calculate":
      return lccRoutes.Calculate.buildUrl(location);
    case "Result":
      return lccRoutes.Result.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}

function parseUnitEditorUrl(url: string): UrlMatch<LccLocation> | undefined {
  for (const p of Object.values(lccRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

function parseMainUrl(url: string): UrlMatch<MainLocation> | undefined {
  for (const p of Object.values(mainRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

export function parseUrl(url: string): UrlMatch<RootLocation> | undefined {
  for (const p of Object.values(rootRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

export function buildUrl(location: RootLocation): string {
  switch (location.type) {
    case "LoggedOut":
      return rootRoutes.LoggedOut.buildUrl(location);
    case "LoginCallback":
      return rootRoutes.LoginCallback.buildUrl(location);
    case "MainLocation":
      return rootRoutes.MainLocation.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}

export function buildMainUrl(location: MainLocation): string {
  switch (location.type) {
    case "Lcc":
      return mainRoutes.LccCalculation.buildUrl(location);
    case "UserSettings":
      return mainRoutes.UserSettings.buildUrl(location);
    case "StartPage":
      return mainRoutes.StartPage.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}
