import * as AbstractImage from "abstract-image";
import { clampTextToWidth, getTextWidth } from "../utils/text-width";
import { createPolygon, createRectangle, createText } from "../abstract-image-proxy";

const chartPadding = 10;
const chartWidth = 500;
const chartHeight = 150;
const pieRadius = (chartHeight - chartPadding * 2) / 2;
const legendPosition = { x: pieRadius * 2 + 30, y: chartPadding };
const center = { x: chartWidth / 2 + chartPadding - (chartWidth / 2 - pieRadius), y: chartHeight / 2 };

export function pieChart(pieInput: readonly PieInput[]): string {
  const components = generatePie(pieInput);

  const image = AbstractImage.createAbstractImage(
    AbstractImage.createPoint(0, 0),
    AbstractImage.createSize(chartWidth, chartHeight),
    AbstractImage.white,
    components
  );

  return AbstractImage.createSVG(image);
}

export type PieInput = {
  readonly label: string;
  readonly valueText?: string;
  readonly percent: number;
  readonly color: AbstractImage.Color;
};

// eslint-disable-next-line functional/prefer-readonly-type
function generatePie(slices: readonly PieInput[]): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [...generatePieSlices(slices), ...generatePieLegend(slices)];
  return components;
}

// eslint-disable-next-line functional/prefer-readonly-type
function generatePieSlices(slices: readonly PieInput[]): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [];

  let lastRadian = (50 / 100) * 360 * (Math.PI / 180);
  for (const slice of slices) {
    const newRadian = (slice.percent / 100) * 360 * (Math.PI / 180);
    const dataPoints: AbstractImage.Point[] = [];
    dataPoints.push(center);
    for (let radian = lastRadian; radian > lastRadian - newRadian - 0.01; radian -= 0.01) {
      dataPoints.push({ x: center.x + Math.sin(radian) * pieRadius, y: center.y + Math.cos(radian) * pieRadius });
    }
    dataPoints.push(center);
    components.push(
      createPolygon({
        points: dataPoints,
        strokeColor: AbstractImage.black,
        strokeThickness: 0.5,
        fillColor: slice.color,
      })
    );
    lastRadian -= newRadian;
  }

  return components;
}

// eslint-disable-next-line functional/prefer-readonly-type
function generatePieLegend(slices: readonly PieInput[]): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [];

  const font = "9.5pt helvetica";

  const getValueText = (slice: PieInput): string =>
    `${slice.valueText || ""}${slice.valueText ? " (" : ""}${slice.percent}%${slice.valueText ? ")" : ""}`;

  const maxValueTextWidth = Math.max(
    ...slices.map((slice) => {
      const valueText = getValueText(slice);
      return getTextWidth(valueText, font);
    })
  );
  const maxWidth = chartWidth - legendPosition.x - maxValueTextWidth;
  const maxTextWidth = Math.max(
    ...slices.map((slice) => clampTextToWidth(slice.label, maxWidth, font)).map((text) => getTextWidth(text, font))
  );

  const rectangleSize = 10;
  let y = legendPosition.y;
  for (const slice of slices) {
    components.push(
      createRectangle({
        topLeft: { x: legendPosition.x, y: y },
        bottomRight: { x: legendPosition.x + rectangleSize, y: y + rectangleSize },
        strokeColor: AbstractImage.black,
        strokeThickness: 0.5,
        fillColor: slice.color,
      })
    );
    components.push(
      createText({
        position: { x: legendPosition.x + 15, y: y + 3 },
        text: clampTextToWidth(slice.label, maxWidth, font),
      })
    );
    components.push(
      createText({
        position: { x: legendPosition.x + 35 + Math.min(maxTextWidth, maxWidth) + maxValueTextWidth, y: y + 3 },
        text: getValueText(slice),
        textAlignment: "right",
        horizontalGrowthDirection: "left",
      })
    );
    y += 20;
  }

  return components;
}
