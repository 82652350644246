import { AbstractDoc as AD } from "abstract-document";
import { getReportAdapters } from "./adapter-registry";
import { getReportCreator } from "./creator-registry";
import { PrintProps, ReportType } from "./types";

export async function runReportAsync(reportName: ReportType, props: PrintProps): Promise<AD.AbstractDoc.AbstractDoc> {
  const dataAdapter = getReportAdapters(reportName);
  const reportCreator = getReportCreator(reportName);
  if (!dataAdapter || !reportCreator) {
    throw new Error(`Can't find data adapter or report creator for report name: ${reportName}`);
  }
  const data = dataAdapter.execute(props);

  const doc: AD.AbstractDoc.AbstractDoc = reportCreator.create(data);
  return doc;
}
