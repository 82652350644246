// Used to encode from javascript object to URL safe string and back
import LZ from "lz-string";

export function objToUrl(object: object): string {
  const a = LZ.compressToEncodedURIComponent(JSON.stringify(object)).replace(/\+/g, "__");
  // console.log(a);
  return a;
}

export function urlToObj<T>(url: string): T | undefined {
  const decompressed = LZ.decompressFromEncodedURIComponent(url.replace(/__/g, "+"));

  if (decompressed === null) {
    return undefined;
  }
  if (decompressed !== null) {
    return JSON.parse(decompressed);
  }
  return {} as T;
}

// const encode = (str: string) =>
//   encodeURIComponent(str)
//     .replace(/\-/g, "%2D")
//     .replace(/\_/g, "%5F")
//     .replace(/\./g, "%2E")
//     .replace(/\!/g, "%21")
//     .replace(/\~/g, "%7E")
//     .replace(/\*/g, "%2A")
//     .replace(/\'/g, "%27")
//     .replace(/\(/g, "%28")
//     .replace(/\)/g, "%29");

// const decode = (str: string) =>
//   decodeURIComponent(
//     str
//       .replace(/\\%2D/g, "-")
//       .replace(/\\%5F/g, "_")
//       .replace(/\\%2E/g, ".")
//       .replace(/\\%21/g, "!")
//       .replace(/\\%7E/g, "~")
//       .replace(/\\%2A/g, "*")
//       .replace(/\\%27/g, "'")
//       .replace(/\\%28/g, "(")
//       .replace(/\\%29/g, ")")
//   );
