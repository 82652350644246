import * as React from "react";
import { AbstractDoc as AD } from "abstract-document";
import {
  AbstractDoc,
  Section,
  Table,
  TableRow,
  Paragraph,
  TextRun,
  Group,
  render,
  TableCell,
} from "abstract-document/lib/abstract-document-jsx";
import { TextKey, TranslateFn, texts } from "../../lang-texts";
import {
  ahuTables,
  isResultRowEmpty,
  mainTables,
  projectHeader,
  Result,
  Table as TableStructure,
  TableKeys,
  valuesFromResults,
} from "../../calculation-results";
import { PrintProps } from "../types";
import { defaultStyles } from "./styles";
import { carbonEmissionGraph, changedRealEstateValue, renderPaybackChart } from "./charts";
import { createHeader } from "../creator/header";

export const Name = "results";

export type CreatorType = (props: PrintProps) => AD.AbstractDoc.AbstractDoc;

const style = {
  darkBorderColor: "#D9DDE1",
  lightBorderColor: "#EDEFF1",
  selectedBackground: "#F0F8FF",
  background: "#F6F7F8",
};

export function create(props: PrintProps): AD.AbstractDoc.AbstractDoc {
  const { results, fonts: fontsData } = props;

  const page = AD.MasterPage.create({
    style: AD.PageStyle.create({
      contentMargins: AD.LayoutFoundation.create({ left: 30, right: 30, top: 10, bottom: 30 }),
    }),
    header: createHeader(),
  });

  const fonts = {
    DaxlinePro: !fontsData.normal
      ? AD.Font.create({
          normal: "Helvetica",
          bold: "Helvetica",
          italic: "Helvetica",
          boldItalic: "Helvetica",
        })
      : AD.Font.create(fontsData),
  };

  const ad = (
    <AbstractDoc fonts={fonts} styles={defaultStyles()}>
      <Section id={"test"} page={page}>
        {/* <Paragraph style={headerparagraphStyle}>
          <TextRun text={translate(texts.results_title)} />
        </Paragraph> */}
        <Group>
          {/* <Paragraph styleName={"H1"} style={reportTitle}>
            <TextRun text={translate(texts.lcc_energy)} />
          </Paragraph> */}
          {renderResult(props, projectHeader, [results[0]], "projectHeader")}
          {renderResult(props, mainTables, [results[0]], "mainTables")}
          {renderResult(props, ahuTables, results, "ahuTables")}
          {renderPaybackChart(props)}
          {carbonEmissionGraph(props)}
          {changedRealEstateValue(props)}
        </Group>
      </Section>
    </AbstractDoc>
  );

  return render(ad);
}

function renderResult(
  props: PrintProps,
  tables: ReadonlyArray<TableStructure>,
  sectionResults: ReadonlyArray<Result>,
  tableKey: TableKeys
): JSX.Element {
  const rendered = tables.flatMap((table) => {
    const rows = table.rows.filter((r) => !isResultRowEmpty(r.valuePath, sectionResults));
    if (rows.length === 0 || (table.hideIf && table.hideIf(sectionResults))) {
      return [];
    }

    return (
      <Group key={tableKey} keepTogether={true}>
        <Table columnWidths={[250, ...Array(props.results.length + 1).fill(Infinity)]}>
          {renderHeader(props.translate, table.title, tableKey, sectionResults, props.results)}
          {rows.map((row, index) => {
            const isFirst = index === 0;
            const isLast = rows.length - 1 === index;
            return renderRow(
              sectionResults,
              tableKey,
              row.fieldName,
              row.label,
              row.valuePath,
              row.decimals,
              props,
              isFirst,
              isLast
            );
          })}
        </Table>
      </Group>
    );
  });
  return render(rendered);
}

function renderHeader(
  translate: TranslateFn,
  name: TextKey,
  tableKey: TableKeys,
  sectionResults: readonly Result[],
  allResults: readonly Result[]
): JSX.Element {
  const tableCellStyle = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({ bottom: 1 }),
    borderColors: AD.LayoutFoundationColor.create({ bottom: style.darkBorderColor }),
    padding: AD.LayoutFoundation.create({ right: 3 }),
  });
  const innerPaddingTitle = AD.LayoutFoundation.create({ top: 18, bottom: 4 });
  const innerPadding = AD.LayoutFoundation.create({ top: 27, left: 4, right: 6, bottom: 8 });

  const paragraphStyle = AD.ParagraphStyle.create({
    textStyle: AD.TextStyle.create({
      alignment: "right",
    }),
  });

  return (
    <TableRow>
      <TableCell style={tableCellStyle}>
        <Table columnWidths={[Infinity]}>
          <TableRow>
            <TableCell style={AD.TableCellStyle.create({ padding: innerPaddingTitle })}>
              <Paragraph styleName="H1">
                <TextRun text={translate(name)} />
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>
      </TableCell>
      {allResults.map((res) => {
        const backgroundStyle = AD.TableCellStyle.create({
          background:
            sectionResults.length > 1
              ? res.result.ahuUserInput.selectedForCompare
                ? style.selectedBackground
                : style.background
              : "",
          padding: innerPadding,
        });
        return (
          <TableCell key={res.id} style={tableCellStyle}>
            <Table columnWidths={[Infinity]}>
              <TableRow>
                <TableCell style={backgroundStyle}>
                  {tableKey === "ahuTables" ? (
                    <Paragraph style={paragraphStyle}>
                      <TextRun
                        style={AD.TextStyle.create({ bold: true })}
                        text={res.result.ahuUserInput.unitName || ""}
                      />
                    </Paragraph>
                  ) : (
                    []
                  )}
                </TableCell>
              </TableRow>
            </Table>
          </TableCell>
        );
      })}

      <TableCell style={tableCellStyle} />
    </TableRow>
  );
}

function renderRow(
  sectionResults: ReadonlyArray<Result>,
  tableKey: TableKeys,
  fieldName: string | undefined,
  name: TextKey,
  valuePath: string,
  decimals: number | undefined,
  props: PrintProps,
  isFirst: boolean,
  isLast: boolean
): JSX.Element {
  const { getUnitInfo, translate, numberFormat } = props;
  const unitInfo = getUnitInfo(fieldName, decimals);

  const values = valuesFromResults(unitInfo, valuePath, sectionResults, numberFormat);

  const border = {
    borders: AD.LayoutFoundation.create({ bottom: !isLast ? 1 : undefined, top: 1 }),
    borderColors: AD.LayoutFoundationColor.create({
      bottom: style.lightBorderColor,
      top: isFirst ? style.darkBorderColor : style.lightBorderColor,
    }),
  };
  const bottomTop = { top: 10, bottom: 5 };

  const ahuValueCellStyle = AD.TableCellStyle.create({
    ...border,
    padding: AD.LayoutFoundation.create({ right: 4 }),
  });
  const cellStyle = AD.TableCellStyle.create({
    ...border,
    padding: AD.LayoutFoundation.create({ ...bottomTop }),
  });

  const paragraphStyle = AD.ParagraphStyle.create({
    textStyle: AD.TextStyle.create({
      alignment: "right",
    }),
  });

  return (
    <TableRow key={valuePath}>
      <TableCell style={cellStyle}>
        <Paragraph>
          <TextRun text={translate(name)} />
        </Paragraph>
      </TableCell>
      {tableKey === "ahuTables"
        ? values.map((value, index) => {
            const backgroundStyle = AD.TableCellStyle.create({
              background:
                sectionResults.length > 1
                  ? props.results[index].result.ahuUserInput.selectedForCompare
                    ? style.selectedBackground
                    : style.background
                  : "",
              padding: AD.LayoutFoundation.create({ ...bottomTop, right: 6 }),
            });

            return (
              <TableCell key={index} style={ahuValueCellStyle}>
                <Table columnWidths={[Infinity]}>
                  <TableRow>
                    <TableCell style={backgroundStyle}>
                      <Paragraph style={paragraphStyle}>
                        <TextRun
                          text={
                            value === "constant"
                              ? props.translate(texts.constant_flow)
                              : value === "variable"
                              ? props.translate(texts.variable_flow)
                              : value || " "
                          }
                        />
                      </Paragraph>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableCell>
            );
          })
        : props.results.map((_, index) => <TableCell key={index} style={cellStyle} />)}
      <TableCell style={cellStyle}>
        <Paragraph style={paragraphStyle}>
          <TextRun
            text={
              tableKey === "ahuTables"
                ? unitInfo.unitName
                : fieldName === "lifeExpectancy"
                ? `${values[0]} ${props.translate(texts.life_expectancy_years)}`
                : fieldName === "operationTime"
                ? `${values[0]} ${props.translate(texts.operation_time_hour)}`
                : `${values[0]} ${unitInfo.unitName}`
            }
          />
        </Paragraph>
      </TableCell>
    </TableRow>
  );
}
