import * as React from "react";
import { Amount, Unit } from "uom";
import { NumberFormat } from "@lcc/shared";
import { AnyQuantity } from "@lcc/shared/src/uom";
import { NumberInputBox } from ".";

export interface AmountInputBoxProps {
  readonly value: Amount.Amount<AnyQuantity> | undefined;
  readonly unit: Unit.Unit<AnyQuantity>;
  readonly decimals: number;
  readonly notNumericMessage: string;
  readonly isRequiredMessage: string;
  readonly numberFormat: NumberFormat;
  readonly disabled: boolean;
  readonly className?: string;
  readonly autoFocus?: boolean;
  readonly valueChanged: (newAmount: Amount.Amount<AnyQuantity> | undefined) => void;
  readonly errorMsg?: (newAmount: Amount.Amount<AnyQuantity> | undefined) => string;
}

// What the optimal debounce is may vary between users. 350ms seems like a nice value...

export function AmountInputBox(props: AmountInputBoxProps): React.ReactElement<AmountInputBoxProps> {
  const { disabled, errorMsg, notNumericMessage, isRequiredMessage, value, decimals, unit, className, numberFormat } =
    props;

  return (
    <NumberInputBox
      autoFocus={props.autoFocus}
      value={getString(value, decimals, unit)}
      decimals={decimals}
      errorMsg={errorMsg && ((val) => errorMsg(val !== undefined ? getAmount(val, unit, undefined) : undefined))}
      notNumericMessage={notNumericMessage}
      isRequiredMessage={isRequiredMessage}
      disabled={disabled}
      numberFormat={numberFormat}
      className={className}
      valueChanged={(val, newDecimals) =>
        props.valueChanged(val !== undefined ? getAmount(val, unit, Math.min(newDecimals, decimals)) : undefined)
      }
    />
  );
}

function getString<T extends AnyQuantity>(
  amount: Amount.Amount<T> | undefined,
  decimalCount: number,
  unit: Unit.Unit<T>
): string {
  if (!amount) {
    return "";
  }

  const valueToUse = Amount.valueAs(unit, amount);
  const currentDecimals = amount.decimalCount;
  return numberToString(valueToUse, Math.min(currentDecimals, decimalCount));
}

function getAmount<T extends AnyQuantity>(
  text: string,
  unit: Unit.Unit<T>,
  decimals: number | undefined
): Amount.Amount<T> | undefined {
  if (!text || text.length === 0) {
    return undefined;
  }
  const parsedFloatValue = parseFloat(text);
  if (Number.isNaN(parsedFloatValue)) {
    return undefined;
  }

  return Amount.create(parsedFloatValue, unit, decimals);
}

function numberToString(num: number | undefined, decimals: number): string {
  if (num === undefined) {
    return "";
  }

  if (decimals === undefined) {
    return num.toString();
  }
  return num.toFixed(decimals === undefined ? 0 : decimals);
}
