import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import { amountAs } from "../../uom";
import { CalculationAhuInputs, FanCalculationType } from "../types";

export function calculateSpecificFanPower(
  ahuInputs: CalculationAhuInputs,
  fanCalculationType: FanCalculationType | undefined
): {
  readonly supply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly extract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
} {
  switch (fanCalculationType) {
    case "sfpPerFan": {
      return { supply: ahuInputs.specificFanPowerSupply, extract: ahuInputs.specificFanPowerExtract };
    }
    case "sfpePerFan": {
      return { supply: ahuInputs.specificFanPowerSupply, extract: ahuInputs.specificFanPowerExtract };
    }
    case "sfpvPerFan": {
      return { supply: ahuInputs.specificFanPowerSupply, extract: ahuInputs.specificFanPowerExtract };
    }

    case "powerPerFan": {
      if (
        ahuInputs.fanPowerSupply === undefined ||
        ahuInputs.fanPowerExtract === undefined ||
        ahuInputs.nominalFlowSupply === undefined ||
        ahuInputs.nominalFlowExtract === undefined
      ) {
        return { supply: undefined, extract: undefined };
      }
      const supplyPower = amountAs(Units.KiloWatt, ahuInputs.fanPowerSupply);
      const extractPower = amountAs(Units.KiloWatt, ahuInputs.fanPowerExtract);
      const supplyAirflow = amountAs(Units.CubicMeterPerSecond, ahuInputs.nominalFlowSupply);
      const extractAirflow = amountAs(Units.CubicMeterPerSecond, ahuInputs.nominalFlowExtract);
      const supplySFP = supplyPower / supplyAirflow;
      const extractSFP = extractPower / extractAirflow;
      return {
        supply: Amount.create(supplySFP, Units.KiloWattPerCubicMeterPerSecond, Math.min(getDecimals(supplySFP), 2)),
        extract: Amount.create(extractSFP, Units.KiloWattPerCubicMeterPerSecond, Math.min(getDecimals(extractSFP), 2)),
      };
    }

    case "sfpeEntireUnit": {
      return calculateSfpTotal(ahuInputs);
    }
    case "sfpvEntireUnit": {
      return calculateSfpTotal(ahuInputs);
    }

    case "sfpTotal": {
      return calculateSfpTotal(ahuInputs);
    }

    default:
      return { supply: undefined, extract: undefined };
  }
}

function getDecimals(value: number | undefined): number {
  if (value === undefined) {
    return 0;
  }

  const parts = value.toString().split(".");
  if (parts.length === 1) {
    return 0;
  }

  return parts[1].length;
}

function calculateSfpTotal(ahuInputs: CalculationAhuInputs): {
  readonly supply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly extract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
} {
  if (
    ahuInputs.specificFanPowerTotal === undefined ||
    ahuInputs.nominalFlowSupply === undefined ||
    ahuInputs.nominalFlowExtract === undefined
  ) {
    return { supply: undefined, extract: undefined };
  }

  const sfpTotal = amountAs(Units.KiloWattPerCubicMeterPerSecond, ahuInputs.specificFanPowerTotal);
  const supplyAir = amountAs(Units.CubicMeterPerSecond, ahuInputs.nominalFlowSupply);
  const extractAir = amountAs(Units.CubicMeterPerSecond, ahuInputs.nominalFlowExtract);

  const totalAir = supplyAir + extractAir;
  const supplyP = supplyAir * sfpTotal * (supplyAir / totalAir);
  const extractP = extractAir * sfpTotal * (extractAir / totalAir);

  const supplySFP = supplyP / supplyAir;
  const extractSFP = extractP / extractAir;

  return {
    supply: Amount.create(supplySFP, Units.KiloWattPerCubicMeterPerSecond, Math.min(getDecimals(supplySFP), 2)),
    extract: Amount.create(extractSFP, Units.KiloWattPerCubicMeterPerSecond, Math.min(getDecimals(supplySFP), 2)),
  };
}
