import ReactGA from "react-ga4";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { MailQuery, marketTableQuery } from "@lcc/shared";
import { MarketTableQuery, MarketTableQueryVariables } from "@lcc/shared/src/generated/generated-operations";
import { SharedState, SharedStateAction } from "../infrastructure/shared-state";
import { post } from "../infrastructure/effect-managers/http-fetch";
import { buildAuthHeaders, graphQLProductQueryWithAuth } from "../infrastructure/graphql";
import { config } from "../config";

// STATE

export interface State {
  readonly expandedShareInput: boolean;
  readonly marketTable: MarketTableQuery | undefined;
  readonly shareProjectState: "waiting" | "idle" | "finished" | "error";
}

export function init(prevState: State | undefined, sharedState: SharedState): readonly [State, Cmd<Action>?] {
  // Only init the header once
  if (prevState !== undefined) {
    return [prevState];
  }
  const graphQLProductQuery = graphQLProductQueryWithAuth(sharedState.activeUser);

  return [
    { expandedShareInput: false, shareProjectState: "idle", marketTable: undefined },
    graphQLProductQuery<MarketTableQuery, MarketTableQueryVariables, Action>(
      marketTableQuery,
      { productId: config.promaster_meta_id },
      (data) => {
        return Action.MarketTableRecieved(data);
      }
    ),
  ];
}

export const Action = ctorsUnion({
  ChangeMarket: (newMarket: string) => ({ newMarket }),
  Logout: () => ({}),
  SetExpandedShareInput: (input: boolean) => ({ input }),
  ShareProject: (email: string) => ({ email }),
  ProjectShared: (response: { readonly error?: string }) => ({ response }),
  MarketTableRecieved: (marketTable: MarketTableQuery) => ({ marketTable }),
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState
): readonly [State, Cmd<Action>?, SharedStateAction?] {
  switch (action.type) {
    case "Logout": {
      return [state, undefined, SharedStateAction.Logout()];
    }
    case "ChangeMarket": {
      return [state, undefined, SharedStateAction.SetMarket(action.newMarket)];
    }
    case "SetExpandedShareInput": {
      return [{ ...state, expandedShareInput: action.input, shareProjectState: "idle" }];
    }

    case "ShareProject": {
      if (!sharedState.projectName) {
        //TODO: show error
        return [state];
      }

      const url = window.location.href;
      const currentDate = new Date().toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });

      const data: MailQuery = {
        toEmail: action.email,
        url: url,
        projectName: sharedState.projectName,
        currentDate: currentDate,
        userName: sharedState.activeUser.userName,
      };
      const shareProjectQuery = post<Action, { error?: string }>(
        buildAuthHeaders(sharedState.activeUser.accessToken),
        "/share-project",
        "json",
        "application/json",
        JSON.stringify(data),
        (response) => {
          ReactGA.event({
            category: "Click",
            action: "Share Calculation Click",
          });
          return Action.ProjectShared(response);
        }
      );
      return [{ ...state, shareProjectState: "waiting" }, shareProjectQuery];
    }

    case "ProjectShared": {
      if (action.response.error) {
        return [{ ...state, shareProjectState: "error" }];
      }
      return [{ ...state, shareProjectState: "finished" }];
    }

    case "MarketTableRecieved": {
      // eslint-disable-next-line no-console

      return [{ ...state, marketTable: action.marketTable }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
