import gql from "graphql-tag";

export const Market_QueryFragment = gql`
  fragment Market_Query on Market {
    market
    language
    decimal_separator
    thousand_separator
    weather_country
    co_country
    currency
    fan_calculation
  }
`;

export const productTextsQuery = gql`
  query Root_ProductTexts($productId: ID!, $language: String!) {
    product(id: $productId) {
      key
      modules {
        texts {
          text(language: $language) {
            name
            text
          }
        }
        text_en: texts {
          text(language: "en") {
            name
            text
          }
        }
      }
    }
  }
`;

export const marketTableQuery = gql`
  query MarketTable($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          Market {
            ...Market_Query
          }
        }
      }
    }
  }
  ${Market_QueryFragment}
`;
