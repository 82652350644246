import { KeyParams } from "./normalized-text";

/**
 * {paramName}
 */
export function replaceCurly(text: string, keyParams: KeyParams): string {
  return text.replace(/({.+?})/g, (curly) => {
    const curlyInner = curly.substring(1, curly.length - 1);
    const replacement = keyParams[curlyInner];
    if (replacement === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return `{MISSING TEXT PARAMETER: '${curlyInner}'}`;
    }
    return replacement;
  });
}

// /**
//  * {propertyName}
//  * {propertyName:Unit}
//  * {propertyName:Unit:DecimalCount}
//  */
// export function replaceCurlyProps(text: string, args: PropertyValueSet.PropertyValueSet): string {
//   return text.replace(/({.+?})/g, curly => {
//     let curlyInner = curly.substring(1, curly.length - 1);
//     const parts = curlyInner.split(":");
//     let replacement: string | undefined = undefined;
//     // Get the PropertyName
//     let propertyName: string = parts[0];
//     // Get the PropertyValue
//     const propValue = PropertyValueSet.getValue(propertyName, args);
//     if (propValue) {
//       // Format according to the property type
//       if (propValue.type === "amount" && parts.length > 0) {
//         // Unit
//         let unit: Unit.Unit<any> = Units.getUnitFromString(parts[1]); //tslint:disable-line
//         // DecimalCount
//         let decimalCount = 2;
//         if (parts.length > 2) {
//           decimalCount = parseInt(parts[2], 10);
//         }
//         const amountValue = PropertyValue.getAmount(propValue);
//         if (amountValue !== undefined) {
//           replacement = Amount.valueAs(unit, amountValue).toFixed(decimalCount);
//         }
//       } else if (propValue.type === "integer") {
//         const integerValue = PropertyValue.getInteger(propValue);
//         if (integerValue !== undefined) {
//           replacement = integerValue.toString();
//         }
//       } else if (propValue.type === "text") {
//         const textValue = PropertyValue.getText(propValue);
//         if (textValue !== undefined) {
//           replacement = textValue;
//         }
//       }
//     }
//     if (replacement === undefined) {
//       //throw new Error(`Parameter {${j}} not supplied for langauge text '${text}'.`);
//       replacement = curly;
//     }
//     return replacement;
//   });
// }
