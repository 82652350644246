/* eslint-disable @typescript-eslint/no-explicit-any */
import * as AbstractImage from "abstract-image";
import { fromArgb } from "abstract-image";
import { TranslateFn, NumberFormat, texts } from "@lcc/shared/src/lang-texts";
import * as CalculationResults from "@lcc/shared/src/calculation-results";
import { StackedBarInput, BarData } from "@lcc/shared/src/charts";
import { stackedBarChart } from "../charts/bar";

export function carbonEmissionBarGraph(
  results: readonly CalculationResults.Result[],
  translate: TranslateFn,
  numberFormat: NumberFormat
): AbstractImage.AbstractImage | undefined {
  const bars: BarData[] = results
    .map((result): BarData | undefined => {
      const electricityPower = result.result.fanTotalEnergy;
      const heatingPower = result.result.heatingEnergy;
      const electricityFactor = result.result.userInput.carbonEmissionElectricityFactor;
      const heatingFactor = result.result.userInput.carbonEmissionHeatingFactor;

      if (!(electricityFactor && electricityPower) || !(heatingPower && heatingFactor)) {
        undefined;
      }

      const electricityCarbonUsageKG = ((electricityFactor || 0) * (electricityPower || 0)) / 1000;
      // const heatingCarbonUsageKG = ((heatingFactor || 0) * (heatingPower || 0)) / 1000;
      return {
        label: result.result.ahuUserInput.unitName || "",
        values: [electricityCarbonUsageKG],
      };
    })
    .filter((bar): bar is BarData => bar !== undefined);

  if (bars.length < 1) {
    return undefined;
  }

  const barInput: StackedBarInput = {
    bars: bars,
    sections: [{ label: translate(texts.carbon_emission_electric), color: fromArgb(0xff, 70, 110, 178) }],
    numberFormat: numberFormat,
    verticalAxisLabel: `${translate(texts.carbon_emission)} (kg)`,
  };

  return stackedBarChart(barInput);
}

export function changedRealEstateValueBarGraph(
  results: readonly CalculationResults.Result[],
  translate: TranslateFn,
  numberFormat: NumberFormat
): AbstractImage.AbstractImage | undefined {
  const bars: BarData[] = results
    .map((result): BarData | undefined => {
      const realEstateBeforeSaving = result.result.userInput.currentRealEstateValue;
      const realEstateBeforeSavingValue = realEstateBeforeSaving && parseFloat(realEstateBeforeSaving);
      const calculatedIncreasedValue = result.result.calculatedIncreasedValue;
      if (!realEstateBeforeSavingValue) {
        return undefined;
      }

      return {
        label: result.result.ahuUserInput.unitName || "",
        values: [realEstateBeforeSavingValue, calculatedIncreasedValue === undefined ? 0 : calculatedIncreasedValue],
      };
    })
    .filter((bar): bar is BarData => bar !== undefined);

  if (bars.length < 2) {
    return undefined;
  }

  const barInput: StackedBarInput = {
    bars: bars,
    sections: [
      { label: translate(texts.value_before_investments), color: fromArgb(0xff, 70, 110, 178) },
      { label: translate(texts.calculated_increased_value), color: fromArgb(0xff, 71, 160, 69) },
    ],
    numberFormat: numberFormat,
    verticalAxisLabel: `${translate(texts.real_estate_value)} (${results[0].result.userInput.currency || ""})`,
  };
  return stackedBarChart(barInput);
}
