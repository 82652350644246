import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export function initSentry(
  dsn: string | undefined,
  release: string,
  environment: string,
  sampleRate: number,
  tracesSampleRate: number
): void {
  if (dsn) {
    Sentry.init({
      dsn: dsn,
      environment: environment,
      integrations: [new Integrations.BrowserTracing()],
      sampleRate: sampleRate,
      tracesSampleRate: tracesSampleRate,
      release: release,
      maxValueLength: 2000,
    });
  }
}

export function setUser(email: string): void {
  Sentry.setUser({ email: email });
}
