import gql from "graphql-tag";

export const PlateHeatExchanger_QueryFragment = gql`
  fragment PlateHeatExchanger_Query on PlateHeatExchanger {
    efficiency
    temperature
    type
    value
    delta_value
  }
`;

export const FreezingCorrectionPlate_QueryFragment = gql`
  fragment FreezingCorrectionPlate_Query on FreezingCorrectionPlate {
    efficiency
    temperature
    value
  }
`;

export const FreezingCorrectionBattery_QueryFragment = gql`
  fragment FreezingCorrectionBattery_Query on FreezingCorrectionBattery {
    efficiency
    temperature
    value
  }
`;

export const CorrectedHeatingDegree_QueryFragment = gql`
  fragment CorrectedHeatingDegree_Query on CorrectedHeatingDegree {
    heating_type
    efficiency
    temperature
    value
  }
`;

export const HeatingDegreeHoursAdditionalHeat_QueryFragment = gql`
  fragment HeatingDegreeHoursAdditionalHeat_Query on HeatingDegreeHoursAdditionalHeat {
    supply_air_temp
    annual_average_temp
    value
  }
`;

export const CarbonEmission_QueryFragment = gql`
  fragment CarbonEmission_Query on CarbonEmissionPerCountry {
    id
    electricity
    heating
  }
`;

export const productCalculateTables = gql`
  query productCalculateTables_Query($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          CorrectedHeatingDegree {
            ...CorrectedHeatingDegree_Query
          }
          HeatingDegreeHoursAdditionalHeat {
            ...HeatingDegreeHoursAdditionalHeat_Query
          }
          CarbonEmissionPerCountry {
            ...CarbonEmission_Query
          }
        }
      }
    }
  }
  ${CorrectedHeatingDegree_QueryFragment}
  ${HeatingDegreeHoursAdditionalHeat_QueryFragment}
  ${CarbonEmission_QueryFragment}
`;
