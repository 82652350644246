export type ClimateResponse = readonly ClimateCountry[];
export type ClimateCountry = {
  readonly countryId: string;
  readonly countryName: string;
  readonly locations: readonly ClimateLocation[];
};
export type ClimateLocation = {
  readonly wmo: string;
  readonly latitudeN: number;
  readonly longitudeW: number;
  readonly locationName: string;
  readonly locationId: string;
  readonly region: string;
};

export type ClimateLocationResponse = readonly ClimateLocationResponseData[];
export type ClimateLocationResponseData = {
  readonly date: string;
  readonly hour: number;
  readonly humidity: number;
  readonly pressure: number;
  readonly windspeed: number;
  readonly temperature: number;
};
