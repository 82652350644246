/* eslint-disable no-console */
import { Cmd } from "@typescript-tea/core";
import { ClimateLocationResponse, ClimateResponse, productCalculateTables } from "@lcc/shared";
import {
  ProductCalculateTables_QueryQuery,
  ProductCalculateTables_QueryQueryVariables,
} from "@lcc/shared/src/generated/generated-operations";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { createFonts, FontData, Fonts } from "@lcc/shared/src/reports/types";
// import { exhaustiveCheck } from "ts-exhaustive-check";
import { SharedState } from "../../../../infrastructure/shared-state";
import { config } from "../../../../config";
import { buildAuthHeaders, graphQLProductQueryWithAuth } from "../../../../infrastructure/graphql";
import * as Page from "../page";
import { fetchMultiple, fetchOne } from "../../../../infrastructure/effect-managers/http-fetch";

export type State = WaitingOnData | DataReceived;

export type ClimateLocationData = {
  readonly climateLocationData: ClimateLocationResponse;
  readonly countryName: string;
  readonly locationName: string;
};

export type WaitingOnData = {
  readonly type: "WaitingOnData";
  readonly productCalculateTables?: ProductCalculateTables_QueryQuery;
  readonly climateLocationData?: ClimateLocationData | "n/a";
  readonly fonts?: FontData;
};

export type DataReceived = {
  readonly type: "DataReceived";
  readonly productCalculateTables: ProductCalculateTables_QueryQuery;
  readonly climateLocationData: ClimateLocationData | "n/a";
  readonly fonts: FontData;
};

export function init(
  state: SharedState,
  pageState: Page.State | undefined,
  prevstate: State | undefined
): readonly [State, Cmd<Action>?] {
  const inputCountry =
    pageState?.type === "CalculationState" ? pageState.calculationInput.annualAverageTemperatureCountry : undefined;
  const inputLocation =
    pageState?.type === "CalculationState" ? pageState.calculationInput.annualAverageTemperatureLocation : undefined;
  const prevCountry =
    prevstate?.climateLocationData !== "n/a" ? prevstate?.climateLocationData?.countryName : undefined;
  const prevLocation =
    prevstate?.climateLocationData !== "n/a" ? prevstate?.climateLocationData?.locationName : undefined;
  if (prevstate && inputCountry === prevCountry && inputLocation === prevLocation) {
    return [prevstate];
  }

  let newState: WaitingOnData = { type: "WaitingOnData" };
  const cmds = [];

  const graphQLProductQuery = graphQLProductQueryWithAuth(state.activeUser);
  cmds.push(
    graphQLProductQuery<ProductCalculateTables_QueryQuery, ProductCalculateTables_QueryQueryVariables, Action>(
      productCalculateTables,
      { productId: config.promaster_meta_id },
      (data) => {
        return Action.DataReceived(data);
      }
    )
  );

  cmds.push(
    fetchMultiple(buildAuthHeaders(state.activeUser.accessToken), Fonts, "arrayBuffer", (data) => {
      return Action.DataReceivedFonts(data);
    })
  );

  if (inputCountry && inputLocation) {
    cmds.push(
      fetchOne<Action, ClimateResponse>(
        buildAuthHeaders(state.activeUser.accessToken),
        `/climate-data`,
        "json",
        (data) => {
          return Action.DataReceivedClimate(data, inputCountry, inputLocation);
        }
      )
    );
  } else {
    newState = {
      ...newState,
      climateLocationData: "n/a",
    };
  }

  return [newState, Cmd.batch(cmds)];
}
export const Action = ctorsUnion({
  DataReceived: (data: ProductCalculateTables_QueryQuery) => ({
    data,
  }),
  DataReceivedClimate: (data: ClimateResponse, climateCountry: string, climateLocation: string) => ({
    data,
    climateCountry,
    climateLocation,
  }),
  DataReceivedClimateLocation: (data: ClimateLocationResponse, climateCountry: string, climateLocation: string) => ({
    data,
    climateCountry,
    climateLocation,
  }),
  DataReceivedFonts: (data: ReadonlyArray<ArrayBuffer>) => ({ data }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState
): readonly [State, Cmd<Action>?, Page.Action?] {
  switch (action.type) {
    case "DataReceived": {
      return [updateState(state, { ...state, productCalculateTables: action.data })];
    }
    case "DataReceivedFonts": {
      return [updateState(state, { ...state, fonts: createFonts(action.data) })];
    }
    case "DataReceivedClimate": {
      if (state.type !== "WaitingOnData") {
        return [state];
      }
      const wmo = action.data
        .find((country) => country.countryId === action.climateCountry)
        ?.locations.find((city) => city.locationName === action.climateLocation)?.wmo;
      if (!wmo) {
        return [{ ...state, climateLocationData: "n/a" }];
      }
      return [
        state,
        fetchOne<Action, ClimateLocationResponse>(
          buildAuthHeaders(sharedState.activeUser.accessToken),
          `/climate-data?country=${action.climateCountry}&location=${wmo}`,
          "json",
          (data) => {
            return Action.DataReceivedClimateLocation(data, action.climateCountry, action.climateLocation);
          }
        ),
      ];
    }
    case "DataReceivedClimateLocation": {
      return [
        updateState(state, {
          ...state,
          climateLocationData: {
            climateLocationData: action.data,
            countryName: action.climateCountry,
            locationName: action.climateLocation,
          },
        }),
      ];
    }
    default: {
      return [state];
    }
  }
}

function updateState(state: State, patch: Partial<State>): State {
  if (state.type === "DataReceived") {
    return state;
  }
  const newState: WaitingOnData = { ...state, ...patch, type: "WaitingOnData" };
  if (newState.climateLocationData && newState.productCalculateTables && newState.fonts) {
    return {
      type: "DataReceived",
      productCalculateTables: newState.productCalculateTables,
      climateLocationData: newState.climateLocationData,
      fonts: newState.fonts,
    };
  } else {
    return newState;
  }
}
