/* eslint-disable functional/prefer-readonly-type */
import {
  Axis,
  ChartLine,
  ChartPoint,
  ChartStack,
  createChartStack,
  createLinearAxis,
  generateBackground,
  generatePoints,
  generateStack,
  generateXAxisBottom,
  generateXAxisTop,
  generateYAxisLabel,
  generateYAxisLabels,
  generateYAxisLines,
  generateYAxisRight,
  getTicks,
  LabelLayout,
  transformPoint,
  XAxis,
  YAxis,
} from "abstract-chart";
import * as AbstractImage from "abstract-image";
import { exhaustiveCheck } from "ts-exhaustive-check";

export interface Chart {
  readonly width: number;
  readonly height: number;
  readonly chartAreas: Array<ChartArea>;
  readonly chartPoints: Array<ChartPoint>;
  readonly chartLines: Array<ChartLine>;
  readonly chartStack: ChartStack;
  readonly xAxisBottom: Axis | undefined;
  readonly xAxisTop: Axis | undefined;
  readonly yAxisLeft: Axis | undefined;
  readonly yAxisRight: Axis | undefined;
  readonly backgroundColor: AbstractImage.Color;
  readonly gridColor: AbstractImage.Color;
  readonly gridThickness: number;
  readonly font: string;
  readonly fontSize: number;
  readonly labelLayout: LabelLayout;
}
export type ChartProps = Partial<Chart>;

export function createChart(props: ChartProps): Chart {
  const {
    width = 600,
    height = 400,
    chartAreas = [],
    chartPoints = [],
    chartLines = [],
    chartStack = createChartStack({}),
    xAxisBottom = createLinearAxis(0, 100, ""),
    xAxisTop = undefined,
    yAxisLeft = createLinearAxis(0, 100, ""),
    yAxisRight = undefined,
    backgroundColor = AbstractImage.white,
    gridColor = AbstractImage.gray,
    gridThickness = 1,
    font = "Arial",
    fontSize = 12,
    labelLayout = "original",
  } = props || {};
  return {
    width,
    height,
    chartAreas,
    chartPoints,
    chartLines,
    chartStack,
    xAxisBottom,
    xAxisTop,
    yAxisLeft,
    yAxisRight,
    backgroundColor,
    gridColor,
    gridThickness,
    font,
    fontSize,
    labelLayout,
  };
}

const padding = 80;

export interface ChartArea {
  readonly points: Array<AbstractImage.Point>;
  readonly strokeColor: AbstractImage.Color;
  readonly strokeThickness: number;
  readonly fillColor: AbstractImage.Color;
  readonly xAxis: XAxis;
  readonly yAxis: YAxis;
}

export function renderChart(chart: Chart): AbstractImage.AbstractImage {
  const { width, height, xAxisBottom, xAxisTop, yAxisLeft, yAxisRight } = chart;

  const gridWidth = width - 2 * padding;
  const gridHeight = height - padding;

  const xMin = padding;
  const xMax = width - padding;
  const yMin = height - 0.5 * padding;
  const yMax = 0.5 * padding;

  const renderedBackground = generateBackground(xMin, xMax, yMin, yMax, chart);

  const xNumTicks = gridWidth / 40;
  const renderedXAxisBottom = generateXAxisBottom(xNumTicks, xAxisBottom, xMin, xMax, yMin, yMax, chart);
  const renderedXAxisTop = generateXAxisTop(xNumTicks, xAxisTop, xMin, xMax, yMax, chart);

  const yNumTicks = gridHeight / 40;
  const renderedYAxisLeft = generateYAxisLeft(yNumTicks, yAxisLeft, xMin, xMax, yMin, yMax, chart);
  const renderedYAxisRight = generateYAxisRight(yNumTicks, yAxisRight, xMax, yMin, yMax, chart);

  const renderedAreas = generateAreas(xMin, xMax, yMin, yMax, chart);
  const renderedPoints = generatePoints(xMin, xMax, yMin, yMax, chart);
  const renderedLines = generateLines(xMin, xMax, yMin, yMax, chart);
  const renderedStack = generateStack(xMin, xMax, yMin, yMax, chart);

  const components = [
    renderedBackground,
    renderedXAxisBottom,
    renderedXAxisTop,
    renderedYAxisLeft,
    renderedYAxisRight,
    renderedAreas,
    renderedStack,
    renderedLines,
    renderedPoints,
  ];
  const topLeft = AbstractImage.createPoint(0, 0);
  const size = AbstractImage.createSize(width, height);
  return AbstractImage.createAbstractImage(topLeft, size, AbstractImage.white, components);
}

export function generateAreas(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart
): AbstractImage.Component {
  const lines = chart.chartAreas.map((l: ChartArea) => {
    if (l.points.length < 3) {
      return AbstractImage.createGroup("", []);
    }
    const xAxis = l.xAxis === "top" ? chart.xAxisTop : chart.xAxisBottom;
    const yAxis = l.yAxis === "right" ? chart.yAxisRight : chart.yAxisLeft;
    const points = l.points.map((p) => transformPoint(p, xMin, xMax, yMin, yMax, xAxis, yAxis));
    return AbstractImage.createPolygon(points, l.strokeColor, l.strokeThickness, l.fillColor);
  });
  return AbstractImage.createGroup("Lines", lines);
}

export function generateLines(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart
): AbstractImage.Component {
  const lines = chart.chartLines.map((l: ChartLine) => {
    if (l.points.length < 2) {
      return AbstractImage.createGroup(l.label, []);
    }
    const xAxis = l.xAxis === "top" ? chart.xAxisTop : chart.xAxisBottom;
    const yAxis = l.yAxis === "right" ? chart.yAxisRight : chart.yAxisLeft;
    const points = l.points.map((p) => transformPoint(p, xMin, xMax, yMin, yMax, xAxis, yAxis));
    const last = points[points.length - 1];
    return AbstractImage.createGroup(l.label, [
      AbstractImage.createPolyLine(points, l.color, l.thickness),
      AbstractImage.createText(
        { x: last.x + 4, y: last.y },
        l.label,
        chart.font,
        chart.fontSize,
        AbstractImage.black,
        "normal",
        0,
        "center",
        "right",
        "uniform",
        0,
        AbstractImage.black,
        false
      ),
    ]);
  });
  return AbstractImage.createGroup("Lines", lines);
}

export function generateYAxisLeft(
  yNumTicks: number,
  yAxisLeft: Axis | undefined,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart
): AbstractImage.Component {
  if (!yAxisLeft) {
    return AbstractImage.createGroup("YAxisLeft", []);
  }
  const yTicks = getTicks(yNumTicks, yAxisLeft);
  const yLines = generateYAxisLines(xMin - 5, xMax, yMin, yMax, yTicks, yAxisLeft, chart);
  const yLabels = generateYAxisLabels(xMin - 5, yMin, yMax, "left", yTicks, yAxisLeft, chart);

  const labelPaddingLeft = labelPadding(formatNumber(yAxisLeft.max).length, chart.fontSize, 0.5);

  let yLabel: AbstractImage.Component;
  switch (chart.labelLayout) {
    case "original":
      yLabel = generateYAxisLabel(
        xMin - labelPaddingLeft,
        yMax + 0.5 * padding,
        "uniform",
        "up",
        yAxisLeft.label,
        chart
      );
      break;

    case "end":
      yLabel = generateYAxisLabel(xMin - labelPaddingLeft, yMax, "left", "up", yAxisLeft.label, chart);
      break;

    case "center":
      yLabel = generateYAxisLabel(xMin - labelPaddingLeft, (yMin + yMax) / 2, "uniform", "up", yAxisLeft.label, chart);
      break;

    default:
      return exhaustiveCheck(chart.labelLayout);
  }

  return AbstractImage.createGroup("YAxisLeft", [yLines, yLabels, yLabel]);
}

function labelPadding(numberOfCharacters: number, fontSize: number, characterOffset: number): number {
  return ((numberOfCharacters + 1 + characterOffset) * fontSize * 2) / 4;
}

function formatNumber(n: number): string {
  if (n >= 10000000) {
    return numberToString(n / 1000000) + "m";
  }
  if (n >= 10000) {
    return numberToString(n / 1000) + "k";
  }
  return numberToString(n);
}

function numberToString(n: number): string {
  return parseFloat(n.toPrecision(5)).toString();
}
