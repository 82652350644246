import { Cmd } from "@typescript-tea/core";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CalculationAhuInputs, CalculationInputs, ClimateLocationResponse, ClimateResponse } from "@lcc/shared";
import gql from "graphql-tag";
import { Amount } from "uom";
import { Units } from "uom-units";
import { SharedState, SharedStateAction } from "../../../../infrastructure/shared-state";
import * as Page from "../page";
import { graphQLProductQueryWithAuth, buildAuthHeaders } from "../../../../infrastructure/graphql";
import { config } from "../../../../config";
import * as GQLOps from "../../../../generated/generated-operations";
import { fetchOne } from "../../../../infrastructure/effect-managers/http-fetch";

export const CarbonEmission_QueryFragment = gql`
  fragment CarbonEmission_Query on CarbonEmissionPerCountry {
    id
    electricity
    heating
    country
  }

  fragment Currency_Query on Currency {
    code
  }

  fragment DefaultInputs_Query on DefaultInputsPerMarket {
    market
    field_name
    value
    unit
  }
`;

export const carbonEmissionTable = gql`
  query carbonEmissionTable_Query($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          CarbonEmissionPerCountry {
            ...CarbonEmission_Query
          }
          Currency {
            ...Currency_Query
          }
          DefaultInputsPerMarket {
            ...DefaultInputs_Query
          }
        }
      }
    }
  }
  ${CarbonEmission_QueryFragment}
`;

export type State = WaitingOnData | DataReceived;

export type WaitingOnData = {
  readonly type: "WaitingOnData";
  readonly carbonEmissionTable: readonly GQLOps.CarbonEmission_QueryFragment[] | undefined;
  readonly currencyTable: readonly GQLOps.Currency_QueryFragment[] | undefined;
  readonly defaultInputsTable: readonly GQLOps.DefaultInputs_QueryFragment[] | undefined;
  readonly climateData: ClimateResponse | undefined;
};

export type DataReceived = {
  readonly type: "DataReceived";
  readonly carbonEmissionTable: readonly GQLOps.CarbonEmission_QueryFragment[];
  readonly currencyTable: readonly GQLOps.Currency_QueryFragment[];
  readonly defaultInputsTable: readonly GQLOps.DefaultInputs_QueryFragment[];
  readonly climateData: ClimateResponse;
};

export function init(state: SharedState, prevstate: State | undefined): readonly [State, Cmd<Action>?] {
  if (prevstate) {
    return [prevstate];
  }
  const graphQLProductQuery = graphQLProductQueryWithAuth(state.activeUser);
  const carbonEmissionQuery = graphQLProductQuery<
    GQLOps.CarbonEmissionTable_QueryQuery,
    GQLOps.CarbonEmissionTable_QueryQueryVariables,
    Action
  >(carbonEmissionTable, { productId: config.promaster_meta_id }, (data) => {
    return Action.DataReceivedCarbon(data);
  });

  const climateQuery = fetchOne<Action, ClimateResponse>(
    buildAuthHeaders(state.activeUser.accessToken),
    "/climate-data",
    "json",
    (data) => {
      return Action.DataReceivedClimate(data);
    }
  );
  return [
    {
      ...state,
      type: "WaitingOnData",
      carbonEmissionTable: undefined,
      climateData: undefined,
      currencyTable: undefined,
      defaultInputsTable: undefined,
    },
    Cmd.batch([carbonEmissionQuery, climateQuery]),
  ];
}

export const Action = ctorsUnion({
  SetCalcInput: (input: CalculationInputs) => ({ input }),
  SetCalcAhuInput: (ahuId: string, input: CalculationAhuInputs) => ({ ahuId, input }),
  SetCalcAhuSelected: (ahuId: string) => ({ ahuId }),
  SetFieldUnit: (field: string, unit: string, decimalCount: number) => ({
    field,
    unit,
    decimalCount,
  }),
  AddAhu: () => ({}),
  RemoveAhu: (id) => ({ id }),
  ClearCalculationInput: () => ({}),
  CalculateAverageTemperatureLocation: (country: string, location: string) => ({
    country,
    location,
  }),
  DataReceivedCarbon: (data: GQLOps.CarbonEmissionTable_QueryQuery) => ({
    data,
  }),
  DataReceivedClimate: (data: ClimateResponse) => ({
    data,
  }),
  DataReceivedClimateLocation: (data: ClimateLocationResponse) => ({
    data,
  }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState
): readonly [State, Cmd<Action>?, Page.Action?] {
  switch (action.type) {
    case "SetCalcInput":
      return [state, undefined, Page.PageAction.SetCalcInput(action.input)];

    case "SetCalcAhuInput":
      return [state, undefined, Page.PageAction.SetCalcAhuInput(action.ahuId, action.input)];

    case "SetCalcAhuSelected":
      return [state, undefined, Page.PageAction.SetCalcAhuSelected(action.ahuId)];

    case "SetFieldUnit":
      return [state, undefined, SharedStateAction.SetFieldUnit(action.field, action.unit, action.decimalCount)];

    case "AddAhu":
      return [state, undefined, Page.PageAction.AddAhu()];

    case "RemoveAhu":
      return [state, undefined, Page.PageAction.RemoveAhu(action.id)];

    case "ClearCalculationInput":
      return [state, undefined, Page.PageAction.ClearCalculationInput()];

    case "CalculateAverageTemperatureLocation": {
      const climateQuery = fetchOne<Action, ClimateLocationResponse>(
        buildAuthHeaders(sharedState.activeUser.accessToken),
        `/climate-data?country=${action.country}&location=${action.location}`,
        "json",
        (data) => {
          return Action.DataReceivedClimateLocation(data);
        }
      );
      return [state, climateQuery];
    }

    case "DataReceivedCarbon": {
      if (state.climateData === undefined) {
        return [
          {
            ...state,
            type: "WaitingOnData",
            carbonEmissionTable: action.data.product?.modules.custom_tables.CarbonEmissionPerCountry || [],
            currencyTable: action.data.product?.modules.custom_tables.Currency || [],
            defaultInputsTable: action.data.product?.modules.custom_tables.DefaultInputsPerMarket || [],
          },
        ];
      } else {
        return [
          {
            ...state,
            type: "DataReceived",
            climateData: state.climateData,
            carbonEmissionTable: action.data.product?.modules.custom_tables.CarbonEmissionPerCountry || [],
            currencyTable: action.data.product?.modules.custom_tables.Currency || [],
            defaultInputsTable: action.data.product?.modules.custom_tables.DefaultInputsPerMarket || [],
          },
        ];
      }
    }
    case "DataReceivedClimate": {
      if (
        state.carbonEmissionTable === undefined ||
        state.currencyTable === undefined ||
        state.defaultInputsTable === undefined
      ) {
        return [
          {
            ...state,
            type: "WaitingOnData",
            climateData: action.data,
          },
        ];
      } else {
        return [
          {
            ...state,
            type: "DataReceived",
            climateData: action.data,
            carbonEmissionTable: state.carbonEmissionTable,
            currencyTable: state.currencyTable,
            defaultInputsTable: state.defaultInputsTable,
          },
        ];
      }
    }
    case "DataReceivedClimateLocation": {
      const averageTemp =
        action.data.reduce((accumulator, current) => {
          return accumulator + current.temperature;
        }, 0) / action.data.length;
      return [
        state,
        undefined,
        Page.PageAction.SetCalcInput({ annualAverageTemperature: Amount.create(averageTemp, Units.Celsius, 2) }),
      ];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
