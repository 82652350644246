/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import isPropValid from "@emotion/is-prop-valid";

// type Props<ElementProps> = { readonly elementref?: React.RefObject<ElementProps> }; //'ref' on withTw elements doesn't work, use a custom elementRef field instead
// type ElementProps<ElementTag extends keyof JSX.IntrinsicElements> = JSX.IntrinsicElements[ElementTag];
// type FullProps<ElementTag extends keyof JSX.IntrinsicElements> = Props<ElementTag> & ElementProps<ElementTag>;

type PropsRef = { readonly elementref?: React.RefObject<unknown> };

export function withTw<
  ElementTag extends keyof JSX.IntrinsicElements,
  ElementProps = JSX.IntrinsicElements[ElementTag],
  Props = {},
  FullProps = Props & ElementProps & PropsRef
>(
  elementTag: ElementTag,
  ...classNames: readonly (string | ((p: Props) => string))[]
): (props: FullProps) => JSX.Element {
  if (classNames.every((c) => typeof c === "string")) {
    const className = classNames.join(" ");

    return (props) => {
      return React.createElement(elementTag, {
        ...getValidProps(props),
        ref: (props as PropsRef).elementref,
        className: [className, (props as React.HTMLAttributes<{}>).className || ""].join(" "),
      });
    };
  } else {
    return (props) => {
      const className = classNames
        .reduce<string[]>((sofar, c) => {
          if (typeof c === "string") {
            sofar.push(c);
          } else {
            sofar.push(c(props as unknown as Props));
          }
          return sofar;
        }, [])
        .join(" ");
      return React.createElement(elementTag, {
        ...getValidProps(props),
        ref: (props as PropsRef).elementref,
        className: [className, (props as React.HTMLAttributes<{}>).className || ""].join(" "),
      });
    };
  }
}

export const ErrorWrapper = withTw("div", "w-full", (p: { readonly isvalid: boolean }) =>
  !p.isvalid ? "was-invalidated" : ""
);
export const Input = withTw(
  "input",
  "w-full form-input border py-8 px-8 h-24 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light spin-button-none"
);
export const Select = withTw(
  "select",
  "w-full lg:w-full border py-4 h-24 px-4 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light"
);

export const H1 = withTw("h1", "h1");
export const H2 = withTw("h2", "text-style-heading-h2");
export const H3 = withTw("h3", "text-style-heading-h3 ");
export const PrimaryButton = withTw("button", "btn btn-primary btn-tiny");
export const DangerButton = withTw("button", "btn btn-danger btn-tiny");

// Props that aren't React props shouldn't be forwarded to createElement. This
// function removes all props that aren't valid React props.
function getValidProps(props: any): any {
  const validProps: any = {};
  for (const key of Object.keys(props)) {
    if (isPropValid(key)) {
      validProps[key] = props[key];
    }
  }
  return validProps;
}
