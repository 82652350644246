import { Cmd } from "@typescript-tea/core";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { v4 as uuid } from "uuid";
import { CalculationAhuInputs, CalculationInputs, texts, TranslateFn } from "@lcc/shared";
import { Amount, Unit } from "uom";
import { Units } from "uom-units";
import { Market_QueryFragment } from "@lcc/shared/src/generated/generated-operations";
import * as Navigation from "../../../infrastructure/effect-managers/navigation";
import { objToUrl, urlToObj } from "../../../infrastructure/object-to-url";
import * as GQLOps from "../../../generated/generated-operations";

export type CalculationAhuInputsWithId = CalculationAhuInputs & { readonly id: string };

// Data shared between the different pages, for example inputs to a calculation
export type State = { readonly type: "WatingForLocalStorageState" } | StateCalculation;
export interface StateCalculation {
  readonly type: "CalculationState";
  readonly calculationInput: CalculationInputs;
  readonly calculationInputAhu: readonly CalculationAhuInputsWithId[];
  readonly selectedSection: ViewSections;
}

export const Sections = ["project_info", "economic_factors", "conditions", "ahu_inputs", "result"] as const;

export type ViewSections = typeof Sections[number];

export type CalculationInputsState = Pick<StateCalculation, "calculationInput" | "calculationInputAhu">;

export interface ToggleShow {
  readonly type: "product-tree-menu/TOGGLE_SHOW";
}

export function toggleShow(): ToggleShow {
  return {
    type: "product-tree-menu/TOGGLE_SHOW",
  };
}

export const PageAction = ctorsUnion({
  SetSelectedSection: (section: ViewSections) => ({ section }),
  SetCalcInput: (input: CalculationInputs) => ({ input }),
  SetCalcAhuInput: (ahuId: string, input: CalculationAhuInputs) => ({ ahuId, input }),
  SetCalcAhuSelected: (ahuId: string) => ({ ahuId }),
  SetFieldUnit: (field: string, unit: string, decimalCount: number) => ({
    field,
    unit,
    decimalCount,
  }),
  NextSection: () => ({}),
  BackSection: () => ({}),
  AddAhu: () => ({}),
  RemoveAhu: (id) => ({ id }),
  CalculationInputReceived: (calculationInputs: CalculationInputsState) => ({ calculationInputs }),
  CalculationInputStored: () => ({}),
  ClearCalculationInput: () => ({}),
});
export type Action = CtorsUnion<typeof PageAction>;

export function removeAhuInputs(state: State, id: string): State {
  if (state.type === "CalculationState") {
    return {
      ...state,
      calculationInputAhu: state.calculationInputAhu.filter((i) => i.id !== id),
    };
  } else {
    return state;
  }
}

export function addAhuInputs(state: State, translate: TranslateFn): State {
  if (state.type === "CalculationState") {
    return {
      ...state,
      calculationInputAhu: [
        ...state.calculationInputAhu,
        {
          id: uuid(),
          unitName: `${translate(texts.unit)} ${state.calculationInputAhu.length + 1}`,
          fanFlowType: "constant",
          heatingType: "none",
        },
      ],
    };
  } else {
    return state;
  }
}

export function getClearedCalculationInput(
  translate: TranslateFn,
  defaultInput: readonly GQLOps.DefaultInputs_QueryFragment[],
  market: Market_QueryFragment | undefined
): CalculationInputsState {
  const marketCode = market?.market;
  const getValue = <T>(
    fieldName: string,
    defaultValue: number | undefined,
    unit: Unit.Unit<T>
  ): Amount.Amount<T> | undefined => {
    const value = defaultInput.find((r) => r.field_name === fieldName && r.market === marketCode)?.value;
    const unitName = defaultInput.find((r) => r.field_name === fieldName && r.market === marketCode)?.unit;

    if (value !== undefined && value !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const unitCheck = unitName && ((Units as any)[unitName] as Unit.Unit<T>);

      if (!unitCheck) {
        return undefined;
      }
      return Amount.create(value, unitCheck);
    } else if (defaultValue !== undefined) {
      return Amount.create(defaultValue, unit);
    } else {
      return undefined;
    }
  };

  const getTextValue = (fieldName: string, defaultValue: string | undefined): string | undefined => {
    const value = defaultInput.find((r) => r.field_name === fieldName && r.market === marketCode)?.value?.toString();
    if (value !== undefined && value !== null) {
      return value;
    } else if (defaultValue !== undefined) {
      return defaultValue;
    } else {
      return undefined;
    }
  };

  return {
    calculationInput: {
      currency: market?.currency?.toString() || undefined,
      annualAverageTemperatureInput: "selection",
      annualOperationTimeInput: "total",
      exhaustAirTemperature: Amount.create(20, Units.Celsius),
      lifeExpectancy: getValue("lifeExpectancy", 20, Units.Year),
      realCalculatedInterest: getValue("calculatedInterest", undefined, Units.Percent),
      expectedIncreaseHeatingPrice: getValue("annualHeatingPrice", undefined, Units.Percent),
      currentEnergyPrice: getTextValue("currentEnergyPrice", undefined),
      expectedIncreaseEnergyPrice: getValue("expectedIncreaseEnergyPrice", undefined, Units.Percent),
      currentHeatingPrice: getTextValue("currentHeatingPrice", undefined),
      annualAverageTemperatureCountry: market?.weather_country || undefined,
      carbonEmissionCountry: market?.co_country || undefined,
      fanCalculationType:
        market?.market === "Canada" || market?.market === "United States of America" ? "powerPerFan" : "sfpeEntireUnit",
    },
    calculationInputAhu: [
      {
        id: uuid(),
        unitName: `${translate(texts.unit)} 1`,
        fanFlowType: "constant",
        selectedForCompare: true,
        heatingType: "none",
      },
    ],
  };
}

export function restoreCalculationInputsFromParams(): CalculationInputsState | undefined {
  const url = window.location;
  const params = new URLSearchParams(url.search);
  const nr = params.get("input");

  if (nr === null) {
    return undefined;
  }

  const newLzState = decodeURIComponent(nr);

  const lzState = urlToObj<CalculationInputsState>(newLzState);
  return lzState;
}

export function storeCalculationInputs<A>(state: CalculationInputsState): Cmd<A> {
  const url = window.location;
  const lzState = objToUrl(state);

  const newLzState = encodeURIComponent(lzState);

  const newUrl = `${url.origin}${url.pathname}?input=${newLzState}`;

  return Cmd.batch([Navigation.replaceUrl(newUrl)]);
}
