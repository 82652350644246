import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import {
  translationsFromProductTexts,
  DecimalSeparator,
  ThousandSeparator,
  SeparatorType,
  NumberFormat,
  isValidUser,
  buildActiveUser,
  User,
  ActiveUser,
  productTextsQuery,
  marketTableQuery,
} from "@lcc/shared";
import {
  MarketTableQuery,
  MarketTableQueryVariables,
  Market_QueryFragment,
  Root_ProductTextsQuery,
  Root_ProductTextsQueryVariables,
} from "@lcc/shared/src/generated/generated-operations";
import { LanguageCode, createTranslateFn } from "@lcc/shared/src/lang-texts";
import * as Main from "../main/index";
import * as Routes from "../infrastructure/routes";
import * as OidcClient from "../infrastructure/effect-managers/oidc-client";
import * as Navigation from "../infrastructure/effect-managers/navigation";
import * as Url from "../infrastructure/effect-managers/navigation/url";
import * as LocalStorage from "../infrastructure/effect-managers/local-storage";
import { userManagerSettings } from "../user-manager-settings";
import { SharedState, SharedStateAction, Lang } from "../infrastructure/shared-state";
import { graphQLProductQueryWithAuth } from "../infrastructure/graphql";
import { UrlMatch } from "../route";
import { config } from "../config";
import { setUser } from "../sentry";

const user_settings_local_storage_key = "userSettings";

export type State =
  | WaitingForUserSessionState
  | WaitingForUserSettingsState
  | WaitingForUserLanguageTextsState
  | LoggedInState
  | LoggedOutState
  | LoginErrorState
  | WaitingForUserMarketState;

export type WaitingForUserSessionState = {
  readonly type: "WaitingForUserSessionState";
  readonly urlPath: string;
  readonly urlQuery: string;
};

export type WaitingForUserSettingsState = {
  readonly type: "WaitingForUserSettingsState";
  readonly activeUser: ActiveUser;
  readonly marketCode: string | undefined;
  readonly marketTable: ReadonlyArray<Market_QueryFragment>;
  readonly originalUrl: string;
  readonly lang: Lang | undefined;
  readonly mainState: Main.State | undefined;
  readonly urlMatch: UrlMatch<Routes.RootLocation> | undefined;
};

export type WaitingForUserLanguageTextsState = {
  readonly type: "WaitingForUserLanguageTextsState";
  readonly activeUser: ActiveUser;
  readonly marketCode: string;
  readonly marketTable: ReadonlyArray<Market_QueryFragment>;
  readonly originalUrl: string;
  readonly userSettings: { readonly [key: string]: string } | undefined;
};

export type WaitingForUserMarketState = {
  readonly type: "WaitingForUserMarketState";
  readonly activeUser: ActiveUser;
  readonly marketCode: string;
  readonly originalUrl: string;
  readonly userSettings: { readonly [key: string]: string } | undefined;
};

export type LoggedOutState = {
  readonly type: "LoggedOutState";
};

export type LoginErrorState = {
  readonly type: "LoginErrorState";
  readonly reason: string;
};

export type LoggedInState = {
  readonly type: "LoggedInState";
  readonly activeUser: ActiveUser;
  readonly marketCode: string;
  readonly marketTable: ReadonlyArray<Market_QueryFragment>;
  readonly lang: Lang;
  readonly projectName: string | undefined;
  readonly urlMatch: UrlMatch<Routes.RootLocation> | undefined;
  readonly userSettings: { readonly [key: string]: string } | undefined;
  readonly mainState: Main.State | undefined;
};

type RedirectState = { readonly redirectUrl: string | undefined };

export function init(url: Navigation.Url): readonly [State, Cmd<Action>?] {
  const initialUrl = url.path + (url.query ?? "");
  const urlMatch = Routes.parseUrl(initialUrl);

  if (
    urlMatch === undefined ||
    (urlMatch.location.type !== "LoginCallback" && urlMatch.location.type !== "LoggedOut")
  ) {
    // Since this is the init() function we never have a user in our state at this point,
    // so the only thing we can do is to try to login which will either result in a user being
    // found directly (becuase we were already have a token in local storage), or a redirect to the login server
    // If we are already logged in we will have our user session subscription triggered.
    // If we are nog logged in then we will be redirected to the login server.
    // Use the current url as the state to save in the redirect round-trip
    const redirectState: RedirectState = { redirectUrl: initialUrl };
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcClient.login(userManagerSettings, redirectState),
    ];
  }

  if (urlMatch.location.type === "LoginCallback") {
    // We got the login callback, let's process it and if successful the subscription will get a user session
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcClient.processSigninCallback(userManagerSettings),
    ];
  }

  if (urlMatch.location.type === "LoggedOut") {
    return [{ type: "LoggedOutState" }];
  }

  // Should never get here
  return exhaustiveCheck(urlMatch.location, true);
}

export const Action = ctorsUnion({
  UrlChanged: (url: Navigation.Url) => ({ url }),
  UrlRequested: (urlRequest: Navigation.UrlRequest) => ({ urlRequest }),
  UserSettingsChanged: () => ({}),
  Logout: () => ({}),
  UserSessionChanged: (user: User | undefined) => ({ user }),
  NoLang: () => ({}),
  AccessTokenRefreshed: (user: User) => ({ user }),
  DispatchMain: (action: Main.Action) => ({ action }),
  UserSettingsRecieved: (data: string | undefined) => ({ data }),
  UserLanguageTextsRecieved: (code: LanguageCode, langTexts: Root_ProductTextsQuery) => ({ code, langTexts }),
  // OnUserSettingsEvent: (data: GQLOps.Root_UserSettingsEventSubscription | undefined) => ({ data }),
  MarketTableRecieved: (marketTableData: MarketTableQuery) => ({ marketTableData }),
});

export type Action = CtorsUnion<typeof Action>;

export function update(action: Action, state: State): readonly [State, Cmd<Action>?] {
  if (state.type === "LoginErrorState") {
    return [state];
  }

  switch (action.type) {
    case "Logout":
      if (state.type === "LoggedInState") {
        return [{ type: "LoggedOutState" }, OidcClient.logout(state.activeUser.idToken)];
      }
      return [state];

    case "AccessTokenRefreshed": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const { user } = action;
      const activeUser = buildActiveUser(user);
      if (!isValidUser(activeUser)) {
        return [
          {
            ...state,
            type: "LoginErrorState",
            reason: activeUser.reason,
          },
        ];
      }
      return [{ ...state, activeUser: activeUser }];
    }
    case "UserSessionChanged": {
      const { user } = action;
      switch (state.type) {
        case "WaitingForUserLanguageTextsState":
        case "WaitingForUserMarketState":
        case "WaitingForUserSettingsState": {
          // If we got here then there was some error in the login flow
          return [
            {
              type: "LoginErrorState",
              reason: `Error Invalid Action UserSessionChanged during state ${state.type}`,
            },
          ];
        }
        case "LoggedInState": {
          // If we have no user then set state as logged out
          if (user === undefined) {
            return [{ type: "LoggedOutState" }];
          }
          return [state];
        }
        case "WaitingForUserSessionState": {
          // If we got an undefind user then there was some error in the login flow
          if (user === undefined) {
            return [{ type: "LoginErrorState", reason: "OIDC user is undefined" }];
          }
          const activeUser = buildActiveUser(user);
          if (!isValidUser(activeUser)) {
            return [
              {
                ...state,
                type: "LoginErrorState",
                reason: activeUser.reason,
              },
            ];
          }

          // Set active user for sentry reporting, side-effect in reducer, not nice!!
          setUser(activeUser.email);

          // There are two main cases for how we got here and what url we should go to next
          // 1. We got a callback from login, in this case we are currently at /signin-callback and originally requested url is in user.state
          // 2. There was no callback (user found in local storage), so we are still at the originally requested url which is in state.initUrl
          // In both cases we can replace the current url with the originally requested url, becuase that will trigger an UrlChanged action
          // We use the user.state to check if it was a callback with original url saved in user.state
          // Since UrlChange handles invalid url we do not need to check valid url here

          const redirectState = user.state as RedirectState;
          const originalUrl =
            redirectState && redirectState.redirectUrl ? redirectState.redirectUrl : state.urlPath + state.urlQuery;

          return [
            {
              type: "WaitingForUserSettingsState",
              activeUser,
              originalUrl,
              urlMatch: undefined,
              mainState: undefined,
              lang: undefined,
              marketCode: undefined,
              marketTable: [],
            },
            LocalStorage.get(user_settings_local_storage_key, (result) => {
              if (result.type === "Err") {
                throw new Error("NO LOCAL STORAGE"); // TODO
              }
              return Action.UserSettingsRecieved(result.value);
            }),
          ];
        }
        case "LoggedOutState": {
          // Once logged out, anything else that happens is in error
          return [{ type: "LoginErrorState", reason: "LoggedOutState" }];
        }
        default:
          return exhaustiveCheck(state, true);
      }
    }
    case "UrlChanged": {
      switch (state.type) {
        case "LoggedInState": {
          const urlMatch = Routes.parseUrl(action.url.path + (action.url.query ?? ""));
          if (urlMatch === undefined) {
            // If the current location is undefined then goto the default location
            const defaultLocation = Routes.RootLocation.MainLocation(
              // Routes.MainLocation.Lcc(Routes.LccLocation.Calculate(""))
              Routes.MainLocation.StartPage()
            );
            const defaultUrl = Routes.buildUrl(defaultLocation);

            // Safety-check that defaultUrl really has a match becuase otherwise we will be stuck in client-side redirect-loop
            const defaultMatch = Routes.parseUrl(defaultUrl);
            if (defaultMatch === undefined) {
              throw new Error("Default URL does not match a route.");
            }

            return [state, Navigation.replaceUrl<Action>(defaultUrl)];
          }
          const newState = { ...state, urlMatch };
          switch (urlMatch.location.type) {
            case "LoginCallback":
              // LoginCallback can only be triggered in init() as it starts the application
              return [{ type: "LoginErrorState", reason: "LoginCallback error" }];
            case "LoggedOut":
              return [{ type: "LoggedOutState" }];
            case "MainLocation": {
              const [mainState, mainCmd] = Main.init(
                urlMatch.location.location,
                state.mainState,
                buildSharedState(newState)
              );
              return [{ ...newState, urlMatch, mainState }, Cmd.batch([Cmd.map(Action.DispatchMain, mainCmd)])];
            }
            default:
              return exhaustiveCheck(urlMatch.location, true);
          }
        }
        default:
          // In other states this action has no relevance
          return [state];
      }
    }
    case "UrlRequested":
      switch (action.urlRequest.type) {
        case "InternalUrlRequest":
          return [state, Navigation.pushUrl(action.urlRequest.url)];
        case "ExternalUrlRequest":
          return [state, Navigation.load(Url.toString(action.urlRequest.url))];
        default:
          return exhaustiveCheck(action.urlRequest);
      }
    case "UserSettingsChanged": {
      if (state.type === "LoggedInState" && state.activeUser) {
        // const graphQLQuery = graphQLQueryWithAuth(state.activeUser);
        return [
          {
            type: "WaitingForUserSettingsState",
            activeUser: state.activeUser,
            originalUrl: window.location.href,
            lang: state.lang,
            marketCode: state.marketCode,
            marketTable: state.marketTable,
            urlMatch: state.urlMatch,
            mainState: state.mainState,
          },
          LocalStorage.get(user_settings_local_storage_key, (result) => {
            if (result.type === "Err") {
              throw new Error("NO LOCAL STORAGE"); // TODO
            }
            return Action.UserSettingsRecieved(result.value); // TODO ERROR
          }),
        ];
      }
      return [state];
    }
    case "UserSettingsRecieved": {
      if (state.type !== "WaitingForUserSettingsState") {
        // If we got here then there was some error in the login flow
        return [
          {
            type: "LoginErrorState",
            reason: `Error Invalid State transition UserSettingsRecieved from ${state.type}`,
          },
        ];
      }

      const newUserSettings: { readonly [key: string]: string } = (action.data && JSON.parse(action.data)) || {};

      const defaultMarket: string = "Global";

      const userSettingMarket = newUserSettings && (newUserSettings["market"] as string);

      const market: string = userSettingMarket ? userSettingMarket : defaultMarket;

      const findMarket = state.marketTable.find((r) => r.market === market);

      if (
        state.marketCode &&
        state.marketTable.length > 0 &&
        state.marketCode === market &&
        state.lang &&
        findMarket?.language === state.lang?.code
      ) {
        return [
          {
            type: "LoggedInState",
            activeUser: state.activeUser,
            marketCode: state.marketCode,
            marketTable: state.marketTable,
            urlMatch: state.urlMatch,
            userSettings: newUserSettings,
            mainState: state.mainState,
            projectName: undefined,
            lang: state.lang,
          },
        ];
      }

      const graphQLProductQuery = graphQLProductQueryWithAuth(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        buildSharedState(state as any).activeUser
      );
      const url = window.location;

      const newUrl = `${url.origin}${url.pathname}`;

      return [
        {
          type: "WaitingForUserMarketState",
          activeUser: state.activeUser,
          originalUrl: state.mainState ? newUrl : state.originalUrl,
          userSettings: newUserSettings,
          marketCode: market,
        },
        graphQLProductQuery<MarketTableQuery, MarketTableQueryVariables, Action>(
          marketTableQuery,
          { productId: config.promaster_meta_id },
          (data) => {
            return Action.MarketTableRecieved(data);
          }
        ),
      ];
    }
    case "MarketTableRecieved": {
      if (state.type !== "WaitingForUserMarketState") {
        // If we got here then there was some error in the login flow
        return [
          {
            type: "LoginErrorState",
            reason: `Error Invalid State transition UserSettingsRecieved from ${state.type}`,
          },
        ];
      }
      const marketTable = action.marketTableData.product?.modules.custom_tables.Market || [];

      const defaultLanguage: LanguageCode = "en";
      const marketLanguage = marketTable.find((r) => r.market === state.marketCode)?.language;
      const language: LanguageCode = marketLanguage ? marketLanguage : defaultLanguage;

      const graphQLProductQuery = graphQLProductQueryWithAuth(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        buildSharedState(state as any).activeUser
      );
      return [
        {
          type: "WaitingForUserLanguageTextsState",
          activeUser: state.activeUser,
          originalUrl: state.originalUrl,
          userSettings: state.userSettings,
          marketCode: state.marketCode,
          marketTable: marketTable,
        },
        graphQLProductQuery<Root_ProductTextsQuery, Root_ProductTextsQueryVariables, Action>(
          productTextsQuery,
          { productId: config.promaster_meta_id, language },
          (data) => {
            return Action.UserLanguageTextsRecieved(language, data);
          }
        ),
      ];
    }
    case "UserLanguageTextsRecieved": {
      if (state.type !== "WaitingForUserLanguageTextsState") {
        // If we got here then there was some error in the login flow
        return [
          {
            type: "LoginErrorState",
            reason: `Error Invalid State transition UserLanguageTextsRecieved from ${state.type}`,
          },
        ];
      }
      const prod = action.langTexts.product;
      if (!prod) {
        return [
          {
            type: "LoginErrorState",
            reason: `Error fetching langTexts`,
          },
        ];
      }

      const translations = translationsFromProductTexts(prod?.modules.texts.text, prod?.modules.text_en.text);
      const translate = createTranslateFn(prod.key, translations);

      let marketRow = state.marketTable.find((market) => market.language === action.code);
      let setDefaultLanguageCmd = undefined;
      if (!marketRow) {
        // Selected language does not exist in Promaster, set en
        setDefaultLanguageCmd = LocalStorage.set(
          user_settings_local_storage_key,
          JSON.stringify({
            ...state.userSettings,
            language: "en",
          }),
          Action.UserSettingsChanged
        );
        // Set to default language code if we can't find the selected language in Promaster
        marketRow = state.marketTable.find((market) => market.language === "en");
      }
      const { decimalSeparator, thousandSeparator } = getNumberFormat(marketRow!);

      return [
        {
          type: "LoggedInState",
          activeUser: state.activeUser,
          lang: { code: action.code, translate, decimalSeparator, thousandSeparator },
          urlMatch: undefined,
          userSettings: state.userSettings,
          mainState: undefined,
          projectName: undefined,
          marketCode: state.marketCode,
          marketTable: state.marketTable,
        },
        Cmd.batch([Navigation.replaceUrl(state.originalUrl), setDefaultLanguageCmd]),
      ];
    }
    case "DispatchMain": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const [mainState, mainCmd, sharedStateActions] = Main.update(
        action.action,
        state.mainState!,
        buildSharedState(state)
      );

      let newState = state;
      const sharedCmds = [];

      for (const sharedStateAction of sharedStateActions || []) {
        const [newSharedState, sharedCmd] = handleSharedStateAction(newState, sharedStateAction);
        if (newSharedState.type === "LoggedOutState") {
          return [newSharedState, sharedCmd];
        }
        newState = newSharedState;
        sharedCmds.push(sharedCmd);
      }

      if (newState.type === "LoggedInState") {
        return [{ ...newState, mainState }, Cmd.batch<Action>([Cmd.map(Action.DispatchMain, mainCmd), ...sharedCmds])];
      }
      return [newState, Cmd.batch<Action>(sharedCmds)];
    }
    case "NoLang": {
      return [state];
    }
    // case "OnUserSettingsEvent": {
    //   log.jonas("OnUserSettingsEvent", action);
    //   if (state.type !== "LoggedInState" || state.userSettings === undefined) {
    //     return [state];
    //   }
    //   if (action.data?.userSettingsEvent.__typename === "UserSettingsEvent_UserSettingsSetEvent") {
    //     const newSettings = Object.fromEntries(action.data?.userSettingsEvent.settings.map((s) => [s.name, s.value]));
    //     return [{ ...state, userSettings: { ...state.userSettings, ...newSettings } }];
    //   }
    //   return [state];
    // }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function handleSharedStateAction(
  state: LoggedInState,
  action: SharedStateAction | undefined
): readonly [LoggedInState | LoggedOutState, Cmd<Action>?] {
  if (action === undefined) {
    return [state];
  }
  // const graphQLMutation = graphQLMutationWithAuth(state.activeUser);
  switch (action.type) {
    case "Logout": {
      if (state.type === "LoggedInState") {
        return [{ type: "LoggedOutState" }, OidcClient.logout(state.activeUser.idToken)];
      }
      return [state];
    }
    case "SetShowCodes": {
      return [state];
      // return [
      //   { ...state, showCodes: action.showCodes },
      //   graphQLMutation<GQLOps.Root_SetUserSettingMutation, GQLOps.Root_SetUserSettingMutationVariables, Action>(
      //     setUserSettingMutation,
      //     { input: { settings: [{ name: `showCodes`, value: action.showCodes ? "true" : "false" }] } },
      //     Action.UserSettingsChanged
      //   ),
      // ];
    }
    case "SetMarket": {
      return [
        state,
        LocalStorage.set(
          user_settings_local_storage_key,
          JSON.stringify({
            ...state.userSettings,
            market: action.newMarket,
          }),
          Action.UserSettingsChanged
        ),
      ];
      // return [
      //   state,
      //   graphQLMutation<GQLOps.Root_SetUserSettingMutation, GQLOps.Root_SetUserSettingMutationVariables, Action>(
      //     setUserSettingMutation,
      //     { input: { settings: [{ name: `language`, value: action.newLang }] } },
      //     Action.UserSettingsChanged
      //   ),
      // ];
    }
    case "SetFieldUnit": {
      return [
        state,
        LocalStorage.set(
          user_settings_local_storage_key,
          JSON.stringify({
            ...state.userSettings,
            [fieldUnitSettingKey(action.field)]: fieldUnitSettingValue(action.unit, action.decimalCount),
          }),
          Action.UserSettingsChanged
        ),
      ];
    }
    case "SetProjectName": {
      return [{ ...state, projectName: action.projectName }];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function fieldUnitSettingKey(fieldName: string): string {
  return `fu:${fieldName}`;
}

function fieldUnitSettingValue(unit: string, decimalCount: number): string {
  return `${unit}:${decimalCount.toString()}`;
}

export function buildSharedState(state: LoggedInState): SharedState {
  const market = (state.marketTable || []).find((r) => r.market === state.marketCode);

  return {
    activeUser: state.activeUser,
    lang: state.lang,
    marketCode: state.marketCode,
    userSettings: state.userSettings,
    projectName: state.projectName,
    market: market,
  };
}

function getNumberFormat(marketRow: Market_QueryFragment): NumberFormat {
  const separators = { comma: ",", dot: ".", space: " " };

  const decimalSeparatorType = (marketRow.decimal_separator || "comma") as SeparatorType;
  const thousandSeparatorType = (marketRow.thousand_separator || "dot") as SeparatorType;

  const thousandSeparator = separators[thousandSeparatorType] as ThousandSeparator;
  const decimalSeparator = separators[decimalSeparatorType] as DecimalSeparator;

  return { thousandSeparator, decimalSeparator };
}
