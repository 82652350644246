import React from "react";
import { Paragraph, Group, Image, render } from "abstract-document/lib/abstract-document-jsx";
import { AbstractDoc as AD } from "abstract-document";
import * as AbstractImage from "abstract-image";
import { headerLogo } from "./logo";

const logoWidth = 639;
const logoHeight = 128;
const logoScale = 0.2;

// eslint-disable-next-line functional/prefer-readonly-type
export function createHeader(): AD.SectionElement.SectionElement[] {
  const imageBuffer = Buffer.from(headerLogo);

  const imageComponent = AbstractImage.createBinaryImage(
    AbstractImage.createPoint(0, 0),
    AbstractImage.createPoint(logoWidth, logoHeight),
    "svg",
    {
      type: "bytes",
      bytes: imageBuffer,
    }
  );
  const image = AbstractImage.createAbstractImage(
    AbstractImage.createPoint(0, 0),
    AbstractImage.createSize(logoWidth, logoHeight),
    AbstractImage.white,
    [imageComponent]
  );

  const imageResource = AD.ImageResource.create({
    abstractImage: image,
    id: "paybackChart",
  });

  return [
    render(
      <Group>
        <Paragraph
          style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 30, left: 30, bottom: 0 }) })}
        >
          <Image imageResource={imageResource} width={logoWidth * logoScale} height={logoHeight * logoScale}></Image>
        </Paragraph>
      </Group>
    ),
  ];
}
