/* eslint-disable functional/prefer-readonly-type */
import { Point } from "abstract-document/lib/abstract-document";
import * as AbstractImage from "abstract-image";

export type PolygonInput = {
  points: Array<Point.Point>;
  strokeColor: AbstractImage.Color;
  strokeThickness: number;
  fillColor: AbstractImage.Color;
  id?: string;
};

export function createPolygon(polygonInput: PolygonInput): AbstractImage.Polygon {
  return AbstractImage.createPolygon(
    polygonInput.points,
    polygonInput.strokeColor,
    polygonInput.strokeThickness,
    polygonInput.fillColor,
    polygonInput.id || undefined
  );
}

export type RectangleInput = {
  topLeft: AbstractImage.Point;
  bottomRight: AbstractImage.Point;
  strokeColor?: AbstractImage.Color;
  strokeThickness?: number;
  fillColor: AbstractImage.Color;
  id?: string;
};

export function createRectangle(rectangleInput: RectangleInput): AbstractImage.Rectangle {
  return AbstractImage.createRectangle(
    rectangleInput.topLeft,
    rectangleInput.bottomRight,
    rectangleInput.strokeColor || AbstractImage.black,
    rectangleInput.strokeThickness || 0,
    rectangleInput.fillColor,
    rectangleInput.id || undefined
  );
}

export type TextInput = {
  position: AbstractImage.Point;
  text: string;
  fontFamily?: string;
  fontSize?: number;
  textColor?: AbstractImage.Color;
  fontWeight?: AbstractImage.AbstractFontWeight;
  clockwiseRotationDegrees?: number;
  textAlignment?: AbstractImage.TextAlignment;
  horizontalGrowthDirection?: AbstractImage.GrowthDirection;
  verticalGrowthDirection?: AbstractImage.GrowthDirection;
  strokeThickness?: number;
  strokeColor?: AbstractImage.Color;
  italic?: boolean;
};

export function createText(textInput: TextInput): AbstractImage.Text {
  return AbstractImage.createText(
    textInput.position,
    textInput.text,
    textInput.fontFamily || "Helvetica",
    textInput.fontSize || 12,
    textInput.textColor || AbstractImage.black,
    textInput.fontWeight || "normal",
    textInput.clockwiseRotationDegrees || 0,
    textInput.textAlignment || "left",
    textInput.horizontalGrowthDirection || "right",
    textInput.verticalGrowthDirection || "uniform",
    textInput.strokeThickness || 0,
    textInput.strokeColor || AbstractImage.black,
    textInput.italic || false
  );
}

export type LineInput = {
  start: Point.Point;
  end: Point.Point;
  strokeColor?: AbstractImage.Color;
  strokeThickness?: number;
};

export function createLine(lineInput: LineInput): AbstractImage.Line {
  return AbstractImage.createLine(
    lineInput.start,
    lineInput.end,
    lineInput.strokeColor || AbstractImage.black,
    lineInput.strokeThickness || 1
  );
}
