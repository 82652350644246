import { InputTableType, InterpResult } from "../types";
import { interpolate2Axis } from "./interpolate";

type KeysMatching<T, V> = { readonly [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

export function interpolateHeatingDegree<T extends InputTableType>(
  table: readonly T[],
  xVal: number,
  yVal: number,
  xAxisField: KeysMatching<T, number | null>,
  yAxisField: KeysMatching<T, number | null>
): InterpResult {
  const xAxis = getSortedAxis(table, xAxisField);
  const yAxis = getSortedAxis(table, yAxisField);

  const [xMin, xMax] = findClosestValues(xAxis, xVal);
  const [yMin, yMax] = findClosestValues(yAxis, yVal);

  //Get values for the closest points
  //Can be optimized by searching for all 4 points in one go
  const findCorrection = (item: T, yAxisVal: number, xAxisVal: number): boolean =>
    (item[yAxisField] as unknown as number) === yAxisVal && (item[xAxisField] as unknown as number) === xAxisVal;

  const xMinYMin = table.find((item) => findCorrection(item, yMin, xMin));
  const xMinYMax = table.find((item) => findCorrection(item, yMax, xMin));
  const xMaxYMin = table.find((item) => findCorrection(item, yMin, xMax));
  const xMaxYMax = table.find((item) => findCorrection(item, yMax, xMax));

  if (
    xMinYMin?.value === undefined ||
    xMinYMin?.value === null ||
    xMinYMax?.value === undefined ||
    xMinYMax?.value === null ||
    xMaxYMin?.value === undefined ||
    xMaxYMin?.value === null ||
    xMaxYMax?.value === undefined ||
    xMaxYMax?.value === null
  ) {
    return undefined;
  }

  //Interpolate temperatures to find min and max temperatures
  const interpValue = interpolate2Axis(
    xVal,
    yVal,
    xMin,
    xMax,
    yMin,
    yMax,
    xMinYMin.value,
    xMinYMax.value,
    xMaxYMin.value,
    xMaxYMax.value
  );

  return interpValue;
}

//Finds the closest values in a sorted array
function findClosestValues(values: readonly number[], value: number): readonly [number, number] {
  const index = values.findIndex((v) => v && v >= value);
  if (index === -1) {
    //Value is bigger than all values
    return [values[values.length - 2], values[values.length - 1]];
  }
  if (index === 0) {
    //Value is smaller than all values
    return [values[0], values[1]];
  }
  if (values[index] === value) {
    return [value, value];
  }

  //Value is between two values
  return [values[index - 1], values[index]];
}

function getSortedAxis<T extends InputTableType>(
  array: readonly T[],
  fieldName: KeysMatching<T, number | null>
): readonly number[] {
  const values = array.map((item) => item[fieldName]).filter((value) => value !== null);
  const numberValues = values as unknown as number[];
  numberValues.sort((a, b) => a - b);
  return [...new Set(numberValues)];
}
