import { Amount } from "uom";
import { Units } from "uom-units";
import { PaybackData, Point, SelectedUnitResult } from "../types";

export function calculatePaybackData(
  selectedForCompare: SelectedUnitResult | undefined,
  isSelected: boolean | undefined,
  lifeExpectancy: Amount.Amount<"Duration"> | undefined,
  offerAmount: string | undefined,
  currentEnergyPrice: string | undefined,
  currentHeatingPrice: string | undefined,
  expectedIncreaseEnergyPrice: Amount.Amount<"Dimensionless"> | undefined,
  expectedIncreaseHeatingPrice: Amount.Amount<"Dimensionless"> | undefined,
  fanTotalEnergy: number,
  heatingEnergy: number | undefined
): PaybackData {
  const selectedSerie = selectedForCompare?.points;

  const currentSerie = generateDataPoints(
    lifeExpectancy,
    offerAmount,
    currentEnergyPrice,
    currentHeatingPrice,
    expectedIncreaseEnergyPrice,
    expectedIncreaseHeatingPrice,
    fanTotalEnergy,
    heatingEnergy
  );

  if (isSelected || !selectedSerie) {
    return { points: currentSerie };
  }

  const selectedSerieMaxValue = selectedSerie[selectedSerie.length - 1].y;

  const currentSeriePercent = currentSerie[currentSerie.length - 1].y / selectedSerieMaxValue;
  const timeUntilEvenPos = getIntersectionFromPoints(selectedSerie, currentSerie);
  const timeUntilEvenValue = timeUntilEvenPos?.x === 0 ? undefined : timeUntilEvenPos?.x;

  const profitPercent = (1 - currentSeriePercent) * 100;

  const profitPercentRounded = Math.round(profitPercent * 10) / 10;

  return {
    points: currentSerie,
    timeUntilEvenPos: timeUntilEvenPos,
    timeUntilEven: timeUntilEvenValue ? Amount.create(timeUntilEvenValue, Units.Year, 1) : undefined,
    profitPercent: Amount.create(profitPercentRounded, Units.Percent, 1),
  };
}

function generateDataPoints(
  lifeExpectancy: Amount.Amount<"Duration"> | undefined,
  offerAmount: string | undefined,
  currentEnergyPrice: string | undefined,
  currentHeatingPrice: string | undefined,
  expectedIncreaseEnergyPrice: Amount.Amount<"Dimensionless"> | undefined,
  expectedIncreaseHeatingPrice: Amount.Amount<"Dimensionless"> | undefined,
  fanTotalEnergy: number,
  heatingEnergy: number | undefined
): readonly Point[] {
  const points = [];

  const years = lifeExpectancy ? Amount.valueAs(Units.Year, lifeExpectancy) : 0;

  const offerAmountValue = offerAmount === undefined || offerAmount === "" ? 0 : parseFloat(offerAmount);

  const energyPrice = currentEnergyPrice !== undefined ? parseFloat(currentEnergyPrice) : 0;
  const energyPriceIncrease =
    expectedIncreaseEnergyPrice !== undefined ? Amount.valueAs(Units.Percent, expectedIncreaseEnergyPrice) : 0;
  const heatingPrice = currentHeatingPrice !== undefined ? parseFloat(currentHeatingPrice) : 0;
  const heatingPriceIncrease =
    expectedIncreaseHeatingPrice !== undefined ? Amount.valueAs(Units.Percent, expectedIncreaseHeatingPrice) : 0;

  points.push({ x: 0, y: offerAmountValue });
  let previousCost = offerAmountValue;
  for (let year = 1; year <= years; year++) {
    const energyCost = energyPrice * (1 + (year * energyPriceIncrease) / 100);
    const heatingCost = heatingPrice * (1 + (year * heatingPriceIncrease) / 100);

    const totalCost = previousCost + energyCost * (fanTotalEnergy || 0) + heatingCost * (heatingEnergy || 0);
    points.push({ x: year, y: totalCost });
    previousCost = totalCost;
  }
  return points;
}

function getIntersectionFromPoints(line1: readonly Point[], line2: readonly Point[]): Point | undefined {
  let l1p1 = undefined;
  let l1p2 = undefined;

  let l2p1 = undefined;
  let l2p2 = undefined;

  for (let index = 1; index < line1.length; index++) {
    const l1p1Temp = line1[index];
    const l1p2Temp = line1[index - 1];

    const l2p1Temp = line2[index];
    const l2p2Temp = line2[index - 1];

    const diff1 = l1p1Temp.y - l2p1Temp.y;
    const diff2 = l1p2Temp.y - l2p2Temp.y;

    if ((diff1 < 0 && diff2 < 0) || (diff1 > 0 && diff2 > 0)) {
      continue;
    }

    l1p1 = l1p1Temp;
    l1p2 = l1p2Temp;
    l2p1 = l2p1Temp;
    l2p2 = l2p2Temp;
    break;
  }

  if (!l1p1 || !l1p2 || !l2p1 || !l2p2) {
    return undefined;
  }

  //first line
  const a1 = l1p2.y - l1p1.y;
  const b1 = l1p1.x - l1p2.x;
  const c1 = a1 * l1p1.x + b1 * l1p1.y;

  //second line
  const a2 = l2p2.y - l2p1.y;
  const b2 = l2p1.x - l2p2.x;
  const c2 = a2 * l2p1.x + b2 * l2p1.y;

  const determinant = a1 * b2 - a2 * b1;

  if (determinant === 0) {
    // The lines dont intersect
    return undefined;
  } else {
    const x = (b2 * c1 - b1 * c2) / determinant;
    const y = (a1 * c2 - a2 * c1) / determinant;
    return { x, y };
  }
}
