import { Amount, Unit } from "uom";
import { Units } from "uom-units";
import { amountAs, amountAsFixed, AnyQuantity } from "../uom";
import { roundTo } from "../utils";
import { Result } from "../calculation-results";
import { NumberFormat } from "../lang-texts";
import { stringToLocalized } from "../lang-texts/number-format";

export interface UnitInfo {
  readonly unit: Unit.Unit<AnyQuantity>;
  readonly unitName: string;
  readonly decimals: number;
  readonly hideIfZero: boolean;
}

export type GetUnitInfoFn = (fieldName: string | undefined, decimals: number | undefined) => UnitInfo;

export function isResultRowEmpty(valuePath: string, results: readonly Result[]): boolean {
  return results.every((r) => getValue(valuePath, r) === undefined || getValue(valuePath, r) === "");
}

export function valuesFromResults(
  unitInfo: UnitInfo,
  valuePath: string,
  results: readonly Result[],
  numberFormat: NumberFormat
): readonly string[] {
  const values = [];
  for (const result of results) {
    const rawValue = getValue(valuePath, result);
    const renderedValue = renderValue(unitInfo, rawValue, numberFormat);
    values.push(renderedValue);
  }
  return values;
}

export function getValue(valuePath: string, result: Result): unknown | undefined {
  const parts = valuePath.split(".");
  let value = result;
  for (const property of parts) {
    if (value === undefined) {
      break;
    }
    value = (value as never)[property];
  }
  return value;
}

export function renderValue(unitInfo: UnitInfo, value: unknown, numberFormat: NumberFormat): string {
  if (value === undefined) {
    return "-";
  } else if (typeof value === "string") {
    return unitInfo.unitName ? stringToLocalized(value, numberFormat) : value;
  } else if (typeof value === "number") {
    if (Number.isNaN(value)) {
      return "";
    } else {
      const roundedValue = roundTo(value, unitInfo.decimals);
      return `${stringToLocalized(roundedValue.toString(), numberFormat)}`;
    }
  } else {
    const val = amountAs(unitInfo.unit, value as Amount.Amount<AnyQuantity>, unitInfo.decimals);
    if (Number.isNaN(val)) {
      return "";
    } else {
      const prefix = unitInfo.unit.quantity === Units.Celsius.quantity && val > 0 ? "+" : "";
      const fixedAmount = amountAsFixed(
        unitInfo.unit,
        value as Amount.Amount<AnyQuantity>,
        (value as Amount.Amount<AnyQuantity>).decimalCount
      );
      return `${prefix}${stringToLocalized(fixedAmount, numberFormat)}`;
    }
  }
}
