export type SeparatorType = "dot" | "comma" | "space";
export type ThousandSeparator = "." | "," | " ";
export type DecimalSeparator = "." | ",";

export type NumberFormat = {
  readonly thousandSeparator: ThousandSeparator;
  readonly decimalSeparator: DecimalSeparator;
};

export function stringToLocalized(value: string, numberFormat: NumberFormat): string {
  const isMinus = value.startsWith("-");
  const val = value.replace("-", "").replace(",", ".").replace(".", numberFormat.decimalSeparator);
  const parts = val.split(numberFormat.decimalSeparator);
  const number = parts[0];

  let formatedNumber = number.substring(number.length - 3, number.length);
  let index = number.length - 6;
  while (index > -3) {
    formatedNumber = `${number.substring(index, index + 3)}${numberFormat.thousandSeparator}${formatedNumber}`;
    index -= 3;
  }

  if (parts[1]) {
    return `${isMinus ? "-" : ""}${formatedNumber}${numberFormat.decimalSeparator}${parts[1]}`;
  } else {
    return `${isMinus ? "-" : ""}${formatedNumber}`;
  }
}

export function localizedToString(value: string, numberFormat: NumberFormat): string {
  // can't use replaceAll due to browser compat
  const re = new RegExp(numberFormat.thousandSeparator, "g");
  return value.replace(re, "").replace(numberFormat.decimalSeparator, ".");
}
