import * as AbstractImage from "abstract-image";
import * as AbstractChart from "abstract-chart";
import * as CalculationResults from "@lcc/shared/src/calculation-results";
import { fromArgb } from "abstract-image";
import { PaybackData } from "../calculation";
import { ChartArea, createChart, renderChart } from "../abstract-chart/chart";
import { TranslateFn, texts } from "../lang-texts";

type SerieId = {
  readonly id: string;
  readonly chartLine: AbstractChart.ChartLine;
};

type PaybackDataId = {
  readonly id: string;
  readonly paybackData: PaybackData | undefined;
};

export function paybackChart(
  results: readonly CalculationResults.Result[],
  translate: TranslateFn
): {
  readonly chart: AbstractImage.AbstractImage;
  readonly data: readonly { readonly id: string; readonly paybackData: PaybackData | undefined }[] | undefined;
} {
  const paybackData = results.map((res) => ({
    id: res.id,
    paybackData: res.result.payback,
  }));
  const resultSeries = getSeries(results);
  const series = resultSeries.map((m) => m.chartLine);

  const areas = generateAreas(results, resultSeries, paybackData);

  const [xMin, xMax] = getLineRange(series, (point) => point.x);
  const [yMin, yMax] = getLineRange(series, (point) => point.y);

  const chart = createChart({
    chartLines: series,
    chartAreas: areas,
    xAxisBottom: AbstractChart.createLinearAxis(xMin, xMax, translate(texts.years)),
    yAxisLeft: AbstractChart.createLinearAxis(
      yMin,
      yMax,
      `${translate(texts.cost)} (${results[0].result.userInput.currency || ""})`
    ),
    labelLayout: "center",
    gridColor: fromArgb(90, 0, 0, 0),
    font: "DaxlinePro",
  });

  return { chart: renderChart(chart), data: paybackData };
}

function generateAreas(
  results: readonly CalculationResults.Result[],
  resultSeries: readonly SerieId[],
  paybackData: readonly PaybackDataId[] | undefined
  // eslint-disable-next-line functional/prefer-readonly-type
): ChartArea[] | undefined {
  if (!paybackData) {
    return undefined;
  }
  const selectedForCompare = results.find((result) => result.result.ahuUserInput.selectedForCompare);
  const selectedSerie = resultSeries.find((serie) => serie.id === selectedForCompare?.id);

  if (!selectedSerie) {
    return undefined;
  }

  const red = fromArgb(100, 150, 0, 0);
  const green = fromArgb(100, 0, 150, 0);

  const chartAreas: ChartArea[] = [];
  for (const serie of resultSeries) {
    if (serie.id === selectedSerie.id) {
      continue;
    }
    if (serie.chartLine.points.length === 0) {
      continue;
    }
    const seriePaybackData = paybackData.find((d) => d.id === serie.id);

    const midPoint = seriePaybackData?.paybackData?.timeUntilEvenPos;

    if (midPoint) {
      const [leftSelected, rightSelected] = splitOn(selectedSerie.chartLine.points, midPoint.x);
      const [leftCurrent, rightCurrent] = splitOn(serie.chartLine.points, midPoint.x);

      const leftSideColor = leftSelected[0].y < leftCurrent[0].y ? red : green;
      const rightSideColor =
        rightSelected[rightSelected.length - 1].y < rightCurrent[rightCurrent.length - 1].y ? red : green;
      chartAreas.push({
        points: [...leftSelected, midPoint, ...leftCurrent.slice().reverse()],
        strokeColor: AbstractImage.black,
        strokeThickness: 0,
        fillColor: leftSideColor,
        xAxis: "bottom",
        yAxis: "left",
      });
      chartAreas.push({
        points: [midPoint, ...rightSelected, ...rightCurrent.slice().reverse()],
        strokeColor: AbstractImage.black,
        strokeThickness: 0,
        fillColor: rightSideColor,
        xAxis: "bottom",
        yAxis: "left",
      });
    } else {
      const selectedPoints = selectedSerie.chartLine.points;
      const currentPoints = serie.chartLine.points;
      const leftSideColor = selectedPoints[0].y < currentPoints[0].y ? red : green;

      chartAreas.push({
        points: [...selectedPoints, ...currentPoints.slice().reverse()],
        strokeColor: AbstractImage.black,
        strokeThickness: 0,
        fillColor: leftSideColor,
        xAxis: "bottom",
        yAxis: "left",
      });
    }
  }
  return chartAreas;
}

function splitOn(
  points: readonly AbstractImage.Point[],
  splitPoint: number
  // eslint-disable-next-line functional/prefer-readonly-type
): [AbstractImage.Point[], AbstractImage.Point[]] {
  let splitIndex = 0;
  for (splitIndex = 0; splitIndex < points.length; splitIndex++) {
    const point = points[splitIndex];
    if (point.x > splitPoint) {
      break;
    }
  }

  return [points.slice(0, splitIndex), points.slice(splitIndex)];
}

function getSeries(results: readonly CalculationResults.Result[]): readonly SerieId[] {
  const series = results.map((res) => {
    const dataPoints = res.result.payback.points;
    return {
      id: res.id,
      chartLine: AbstractChart.createChartLine({
        points: [...dataPoints],
        color: AbstractImage.black,
        label: res.result.ahuUserInput.unitName,
        xAxis: "bottom",
        yAxis: "left",
      }),
    };
  });

  return series;
}

function getLineRange(
  series: readonly AbstractChart.ChartLine[],
  axisSelector: (point: AbstractImage.Point) => number
): readonly [number, number] {
  const axisValues = series
    .map((serie) => serie.points.map(axisSelector))
    .reduce<ReadonlyArray<number>>((soFar, current) => {
      return [...soFar, ...current];
    }, []);
  return [Math.min(...axisValues, 0), Math.max(...axisValues)];
}
