import { AbstractDoc as AD } from "abstract-document";

export function defaultStyles(): AD.Types.Indexer<AD.Style.Style> {
  const paragraphStyle = createParagraphStyle(false, false, 9, -4, 2, "black");
  const h1Style = createParagraphStyle(true, false, 15, 0, 4, "black");
  const defaultStyles = {
    [AD.StyleKey.create(paragraphStyle.type, "Default")]: paragraphStyle,
    [AD.StyleKey.create(paragraphStyle.type, "H1")]: h1Style,
  };
  return defaultStyles;
}

function createParagraphStyle(
  bold: boolean,
  italic: boolean,
  fontSize?: number,
  spacingBefore?: number,
  spacingAfter?: number,
  color?: string,
  alignment?: AD.ParagraphStyle.TextAlignment,
  underline?: boolean,
  mediumBold?: boolean
): AD.ParagraphStyle.ParagraphStyle {
  return AD.ParagraphStyle.create({
    margins: AD.LayoutFoundation.create({
      top: spacingBefore,
      bottom: spacingAfter ?? 2,
    }),
    alignment: alignment,
    textStyle: createTextStyle(bold, italic, fontSize, color, underline, mediumBold),
  });
}

function createTextStyle(
  bold?: boolean,
  italic?: boolean,
  fontSize?: number,
  color?: string,
  underline?: boolean,
  mediumBold?: boolean
): AD.TextStyle.TextStyle {
  return AD.TextStyle.create({
    underline: underline,
    fontFamily: "DaxlinePro",
    ...(fontSize !== undefined ? { fontSize: fontSize } : {}),
    ...(bold !== undefined ? { bold: bold } : {}),
    ...(mediumBold !== undefined ? { mediumBold: mediumBold } : {}),
    ...(italic !== undefined ? { italic: italic } : {}),
    color: color,
  });
}
