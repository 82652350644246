import React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as SharedState from "../../infrastructure/shared-state";
import { Action } from "./state";

export function view(props: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  return (
    <div>
      <div>User settings</div>
      <button
        onClick={() => {
          props.dispatch(Action.SetCodesVisibility(true));
        }}
      >
        true
      </button>
      <button
        onClick={() => {
          props.dispatch(Action.SetCodesVisibility(false));
        }}
      >
        false
      </button>
    </div>
  );
}
