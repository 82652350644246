/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import React, { ReactElement } from "react";
import { Tooltip } from "react-tooltip";
import { Amount, Unit } from "uom";
import { unitLookup } from "uom-units";
import { FanCalculationType, NumberFormat, TranslateFn, texts } from "@lcc/shared";
import { AnyQuantity, getUnits } from "@lcc/shared/src/uom";
import { Input, Select, withTw } from "../../../../../components/elements/with-tw";
import { AmountInputBox, Icon, NumberInputBox, UnitSelector } from "../../../../../components/elements";
import { TabButton } from "../../../../../components/elements/tab-button";

export const Row = withTw("div", " flex flex-row flex-nowrap space-x-4 items-center ");
export const ColumnName = withTw(
  "div",
  "flex-1 flex flex-row space-x-4 text-xs max-w-[33.3%] w-[10%] ml-[-5px] pr-[22px]"
);
export const Column = withTw("div", "flex-1 flex flex-row space-x-4 ");
export const ColumnValue = withTw(
  "div",
  "flex-1 flex flex-row justify-end max-w-unit-column py-20 p-4 w-[10%] ",
  (p: { readonly highlighted?: boolean }) => (p.highlighted ? "bg-[#F0F8FF] w-[10%]" : "")
);
export const EmptyColumn = withTw(
  "div",
  "flex-1 flex flex-row justify-center max-w-unit-column w-[10%] p-4 self-stretch",
  (p: { readonly highlighted?: boolean }) => (p.highlighted ? "bg-[#F0F8FF] w-[10%]" : "")
);
export const ColumnCenter = withTw(
  "div",
  "flex-1 flex flex-row p-4 justify-center max-w-unit-column w-[10%] ",
  (p: { readonly highlighted?: boolean }) => (p.highlighted ? "bg-[#F0F8FF] w-[10%] " : "")
);
export const SubHeading = withTw("h3", "flex flex-row justify-between py-4");
const UnitColumn = withTw("div", "flex-1 max-w-unit-column");
const ValueContainer = withTw("div", "relative w-full");

const UnitCompactContainer = withTw("div", "absolute top-0 right-0 h-24 leading-[1.5rem] pr-[0.5rem] text-gray-600");

export interface RadioButtonProps {
  readonly label?: string | React.ReactNode;
  readonly checked: boolean;
  readonly checkedChanged?: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly name?: string;

  readonly onChange?: (checked: boolean) => void;
}

interface IconProps {
  readonly message?: string;
  readonly onClick?: () => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly colorClass?: string;
}

export type ValueProps<T, U> =
  | {
      readonly multi: false;
      readonly value?: U;
      readonly required?: boolean;
      readonly autoFocusOnLoad?: boolean;
      readonly onValueChange: (value: U | undefined) => void;
      readonly translate: TranslateFn;
    }
  | {
      readonly multi: true;
      readonly valueKey: keyof T;
      readonly values: readonly ({ readonly id: string } & T)[];
      readonly required?: boolean;
      readonly autoFocusOnLoad?: boolean;
      readonly onValuesChange: (id: string, value: U | undefined) => void;
      readonly isVisible?: (value: T) => boolean;
      readonly translate: TranslateFn;
    };

export function RemoveAhuButton(props: {
  readonly values: readonly { readonly id: string; readonly selectedForCompare?: boolean }[];
  readonly onClick: (id: string) => void;
}): ReactElement {
  // const ahuIds = props.values.map((v) => v.id);
  if (props.values.length < 2) {
    return <></>;
  }

  return (
    <Row>
      <ColumnName className=""></ColumnName>
      {props.values.map((value) => (
        <ColumnCenter highlighted={props.values.length > 1 && value.selectedForCompare} key={`${value.id}`}>
          <Icon
            icon={"fal_faTrashCan"}
            onClick={() => props.onClick(value.id)}
            className="px-40 py-8 hover:text-[#C23318] active:bg-[#ECC1B9]"
          />
        </ColumnCenter>
      ))}
      <UnitColumn id="unit" />
    </Row>
  );
}

export function InputTextRow<T>(
  props: {
    readonly name?: string;
    readonly hideUnitColumn?: boolean;
  } & ValueProps<T, string>
): ReactElement {
  const values = toValues<T, string>(props);
  if (!values) {
    return <></>;
  }

  return (
    <>
      <Row>
        <ColumnName className="font-bold">{`${props.name}`}</ColumnName>
        {values.map(({ id, value, visible, highlighted }, index) => (
          <ColumnValue key={`${id}_${props.name}`} highlighted={highlighted && values.length > 1}>
            {visible ? (
              <Input
                autoFocus={props.autoFocusOnLoad ? (props.multi ? index === 0 : true) : false}
                type="text"
                value={value || ""}
                onChange={(v) =>
                  props.multi === false
                    ? props.onValueChange(v.currentTarget.value)
                    : props.onValuesChange(id, v.currentTarget.value)
                }
              />
            ) : (
              <div key={`${id}_${props.name}`} />
            )}
          </ColumnValue>
        ))}
        {!props.hideUnitColumn && <UnitColumn id="unit" />}
      </Row>

      {props.required && (
        <RequiredContainer hideUnitColumn={props.hideUnitColumn} translate={props.translate} values={values} />
      )}
    </>
  );
}

export function InputRadiobuttonRow<T>(
  props: {
    readonly name?: string;
    readonly multi: true;
  } & ValueProps<T, undefined>
): ReactElement {
  const values = toValues<T, undefined>(props);
  if (!values) {
    return <></>;
  }
  return (
    <Row>
      <ColumnName className="font-bold">{`${props.name}`}</ColumnName>
      {values.map(({ id, value, visible, highlighted }) => (
        <ColumnValue key={`${id}_${props.name}`} highlighted={highlighted && values.length > 1}>
          {visible ? (
            <Input
              type="radio"
              checked={value || false}
              readOnly={true}
              onClick={() => props.onValuesChange(id, undefined)}
            />
          ) : (
            <div key={`${id}_${props.name}`} />
          )}
        </ColumnValue>
      ))}
      <UnitColumn id="unit" />
    </Row>
  );
}

export function InputCheckboxRow<T>(
  props: {
    readonly name?: string;
    readonly multi: true;
  } & ValueProps<T, boolean>
): ReactElement {
  const values = toValues<T, undefined>(props);
  if (!values) {
    return <></>;
  }
  return (
    <Row>
      <ColumnName className="font-bold">{`${props.name}`}</ColumnName>
      {values.map(({ id, value, visible, highlighted }) => (
        <ColumnValue
          key={`${id}_${props.name}`}
          highlighted={highlighted && values.length > 1}
          className="justify-center"
        >
          {visible ? (
            <Input
              className={"w-[18px] h-[18px]"}
              type="checkbox"
              checked={value || false}
              readOnly={true}
              onClick={() => props.onValuesChange(id, !value)}
            />
          ) : (
            <div key={`${id}_${props.name}`} />
          )}
        </ColumnValue>
      ))}
      <UnitColumn id="unit" />
    </Row>
  );
}
// eslint-disable-next-line functional/no-let
let tooltip_id_counter = 0;

export function InputAmountRow<T>(
  props: {
    readonly name: string | boolean;
    readonly userUnits?: Record<string, string>;
    readonly defaultUnit: Unit.Unit<AnyQuantity>;
    readonly numberFormat: NumberFormat;
    readonly fieldName?: string;
    readonly hideUnitSelect?: boolean;
    readonly message?: string;
    readonly hideUnitColumn?: boolean;
    readonly errorMsg?: (
      id: string,
      value: Amount.Amount<AnyQuantity> | undefined,
      defaultUnit: Unit.Unit<AnyQuantity>,
      currentUnit: Unit.Unit<AnyQuantity>
    ) => string;
    readonly unitCallback: (value: Unit.Unit<AnyQuantity>) => void;
    readonly compactUnitSelector?: boolean;
    readonly singleColumnValue?: boolean;
    readonly supplyUserChoice?: FanCalculationType;
    readonly translate: TranslateFn;
  } & ValueProps<T, Amount.Amount<AnyQuantity>>
): ReactElement {
  const { userUnits, defaultUnit, fieldName, singleColumnValue } = props;

  const toolTipId = React.useMemo(() => `tooltip_${tooltip_id_counter++}`, []);

  const values = toValues<T, Amount.Amount<AnyQuantity>>(props);

  if (!values) {
    return <></>;
  }
  const units = getUnits(defaultUnit.quantity, fieldName);
  const unit = userUnits ? lookUpSelectedUnit(userUnits, defaultUnit, units, fieldName) : defaultUnit;
  const valueColumns = values.map(({ id, value, visible, highlighted }, index) => {
    if (!visible) {
      return (
        <EmptyColumn key={`${id}_${props.name}`} highlighted={highlighted && values.length > 1} className="flex-1" />
      );
    }
    // const amount = Amount.create(value ? Amount.valueAs(unit, value) : 0, unit, 5);
    return (
      <ColumnValue key={`${id}_${props.name}`} highlighted={highlighted && values.length > 1}>
        <ValueContainer>
          <AmountInputBox
            autoFocus={props.autoFocusOnLoad ? (props.multi ? index === 0 : true) : false}
            value={value}
            unit={unit}
            decimals={5}
            notNumericMessage={""}
            isRequiredMessage={""}
            numberFormat={props.numberFormat}
            errorMsg={(valAnyUnit) => (props.errorMsg && props.errorMsg(id, valAnyUnit, defaultUnit, unit)) || ""}
            disabled={false}
            valueChanged={(v) => (props.multi === false ? props.onValueChange(v) : props.onValuesChange(id, v))}
          />
          {(props.hideUnitSelect === undefined || !props.hideUnitSelect) && props.compactUnitSelector === true ? (
            <UnitCompactContainer>
              {fieldName === "lifeExpectancy" ? (
                props.translate(texts.life_expectancy_years)
              ) : fieldName === "operationTime" ? (
                props.translate(texts.operation_time_hour)
              ) : (
                <UnitSelector
                  unit={unit}
                  units={units}
                  unitChanged={(u: Unit.Unit<AnyQuantity>) => {
                    props.unitCallback(u);
                  }}
                  extraText={""}
                />
              )}
            </UnitCompactContainer>
          ) : (
            <></>
          )}
        </ValueContainer>
      </ColumnValue>
    );
  });
  const unitColumn = !props.hideUnitColumn && (
    <UnitColumn id="unit">
      {(props.hideUnitSelect === undefined || !props.hideUnitSelect) && !props.compactUnitSelector ? (
        <UnitSelector
          unit={unit}
          units={units}
          unitChanged={(u: Unit.Unit<AnyQuantity>) => {
            props.unitCallback(u);
          }}
          extraText={""}
        />
      ) : (
        <></>
      )}
    </UnitColumn>
  );
  return (
    <>
      <Row>
        <div className="flex-1 flex flex-row space-x-4 max-w-[33.3%] items-center justify-between pr-[22px] ml-[-5px]">
          <div className="font-bold">{props.name}</div>
          <div>
            <a data-tooltip-id={toolTipId}>
              <InfoIcon message={props.message} className="ml-4 cursor-pointer" />
            </a>
            <Tooltip
              className="z-10 max-w-[700px]"
              id={toolTipId}
              content={props.message}
              events={["click", "hover"]}
            />
          </div>
        </div>
        {singleColumnValue ? (
          <div className="flex flex-row flex-1 flex-nowrap items-center pr-4">
            {valueColumns}
            {unitColumn}
          </div>
        ) : (
          <>
            {valueColumns}
            {unitColumn}
          </>
        )}
      </Row>
      {props.required && (
        <RequiredContainer hideUnitColumn={props.hideUnitColumn} translate={props.translate} values={values} />
      )}
    </>
  );
}

export function InputNumberRow<T>(
  props: {
    readonly name: string;
    readonly unit?: string;
    readonly numberFormat: NumberFormat;
    readonly compactUnitSelector?: boolean;
    readonly hideUnitColumn?: boolean;
    readonly fieldName?: string;
    readonly message?: string;
  } & ValueProps<T, string>
): ReactElement {
  const toolTipId = React.useMemo(() => `tooltip_${tooltip_id_counter++}`, []);

  const values = toValues<T, string>(props);

  if (!values) {
    return <></>;
  }
  return (
    <>
      <Row>
        <div className="flex-1 flex flex-row space-x-4 max-w-[33.3%] items-center justify-between pr-[22px] ml-[-5px]">
          <div className="font-bold">{props.name}</div>
          <div>
            <a data-tooltip-id={toolTipId}>
              <InfoIcon message={props.message} className="ml-4 cursor-pointer" />
            </a>
            <Tooltip
              className="z-10 max-w-[700px]"
              id={toolTipId}
              content={props.message}
              events={["click", "hover"]}
            />
          </div>
        </div>

        {values.map(({ id, value, visible, highlighted }, index) => (
          <ColumnValue key={`${id}_${props.name}`} highlighted={highlighted && values.length > 1}>
            {visible ? (
              <ValueContainer>
                <NumberInputBox
                  autoFocus={props.autoFocusOnLoad ? (props.multi ? index === 0 : true) : false}
                  value={value || ""}
                  valueChanged={(v) => {
                    props.multi === false ? props.onValueChange(v) : props.onValuesChange(id, v);
                  }}
                  decimals={5}
                  notNumericMessage={""}
                  isRequiredMessage={""}
                  numberFormat={props.numberFormat}
                  disabled={false}
                />
                {props.compactUnitSelector === true && (
                  <UnitCompactContainer>
                    <div>{props.unit ?? <></>}</div>
                  </UnitCompactContainer>
                )}
              </ValueContainer>
            ) : (
              <div key={`${id}_${props.name}`} />
            )}
          </ColumnValue>
        ))}
        {!props.hideUnitColumn && (
          <UnitColumn id={"unit"}>{!props.compactUnitSelector ? props.unit : <></>}</UnitColumn>
        )}
      </Row>

      {props.required && (
        <RequiredContainer hideUnitColumn={props.hideUnitColumn} translate={props.translate} values={values} />
      )}
    </>
  );
}

export function InputSelectRow<T>(
  props: {
    readonly name: string;
    readonly options: readonly { readonly value: string; readonly text: string }[];
    readonly placeholderText?: string;
    readonly defaultUserChoice?: FanCalculationType;
    readonly hideUnitColumn?: boolean;
    readonly fieldName?: string;
  } & ValueProps<T, string>
): ReactElement {
  const values = toValues<T, string>(props);

  if (!values) {
    return <></>;
  }
  return (
    <>
      <Row>
        <ColumnName className="font-bold">{`${props.name}`}</ColumnName>
        {values.map(({ id, value, visible, highlighted }, index) => {
          const options = props.placeholderText
            ? [{ value: props.placeholderText, text: props.placeholderText }, ...props.options]
            : props.options;

          return (
            <ColumnValue key={`${id}_${props.name}`} highlighted={highlighted && values.length > 1}>
              {visible ? (
                <Select
                  autoFocus={props.autoFocusOnLoad ? (props.multi ? index === 0 : true) : false}
                  value={value || options[0].value}
                  onChange={(v) => {
                    const val = props.placeholderText
                      ? v.currentTarget.value === props.placeholderText
                        ? undefined
                        : v.currentTarget.value
                      : v.currentTarget.value;
                    props.multi === false ? props.onValueChange(val) : props.onValuesChange(id, val);
                  }}
                >
                  {options.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.text}
                    </option>
                  ))}
                </Select>
              ) : (
                <div key={`${id}_${props.name}`} className="flex-1" />
              )}
            </ColumnValue>
          );
        })}
        {!props.hideUnitColumn && <UnitColumn id={"unit"} />}
      </Row>
      {props.required && (
        <RequiredContainer hideUnitColumn={props.hideUnitColumn} translate={props.translate} values={values} />
      )}
    </>
  );
}

export function InputSelectRadioRow<T>(
  props: {
    readonly name: string;
    readonly options: readonly { readonly value: string; readonly text: string }[];
    readonly placeholderText?: string;
  } & ValueProps<T, string>
): ReactElement {
  if (props.multi) {
    return <></>;
  }
  return (
    <Row className="inline-flex ">
      <ColumnName className="font-bold">{`${props.name}`}</ColumnName>
      <RadioButton
        label={props.options[0].text}
        checked={props.value === props.options[0].value || props.value === undefined}
        disabled={false}
        onChange={(v) => {
          if (v) {
            props.onValueChange(props.options[0].value);
          }
        }}
      />
      <RadioButton
        label={props.options[1].text}
        checked={props.value === props.options[1].value || props.value === undefined}
        disabled={false}
        onChange={(v) => {
          if (v) {
            props.onValueChange(props.options[1].value);
          }
        }}
      />
    </Row>
  );
}

export function RadioButton(props: RadioButtonProps): React.ReactElement<RadioButtonProps> {
  return (
    <div className={`form-radio ${props.className || ""}`}>
      <label className="form-control ml-80  mt-3">
        <input
          name={props.name}
          checked={props.checked}
          type="radio"
          disabled={props.disabled}
          onChange={() => {
            if (!props.disabled && props.onChange) {
              props.onChange(!props.checked);
            }
          }}
        />
        {props.label && (
          <div className="flex ml-2">{typeof props.label === "string" ? <span>{props.label}</span> : props.label}</div>
        )}
      </label>
    </div>
  );
}
interface Value<T> {
  readonly id: string;
  readonly value: T;
  readonly visible: boolean;
  readonly highlighted: boolean;
}

function toValues<T, U>(props: ValueProps<T, U>): readonly Value<U | undefined>[] | undefined {
  if (props.multi) {
    const values = props.values.map((v) => ({
      id: v.id,
      value: v[props.valueKey] as unknown as U,
      visible: !props.isVisible || !!props.isVisible(v),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      highlighted: (v as any)["selectedForCompare"],
    }));
    if (values.some((v) => v.visible)) {
      return values;
    } else {
      return undefined;
    }
  } else if (props.multi === false) {
    return [{ id: "", value: props.value, visible: true, highlighted: false }];
  } else {
    return []; // Typescript complains without this
  }
}

function lookUpSelectedUnit(
  userUnits: Record<string, string>,
  defaultUnit: Unit.Unit<AnyQuantity>,
  units: readonly Unit.Unit<AnyQuantity>[],
  fieldName: string | undefined = undefined
): Unit.Unit<AnyQuantity> {
  const unit = userUnits[fieldName ? fieldName : defaultUnit.quantity];
  return unit && units.find((un) => un.name === unit) ? (unitLookup(unit) as Unit.Unit<AnyQuantity>) : defaultUnit;
}

export function InfoIcon({ message, onClick, disabled, colorClass, className }: IconProps): JSX.Element | null {
  const divClassName = `
    ${disabled ? "text-gray-400" : onClick ? "cursor-pointer" : ""} 
    ${colorClass ?? ""}
    ${className ?? ""}`;

  if (message === undefined) {
    return null;
  }

  return (
    <div
      className={divClassName}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      <Icon icon={"fal_faCircleInfo"} className={"w-16 h-16"}></Icon>
    </div>
  );
}

export function InputSelectTabRow<T>(
  props: {
    readonly name: string;
    readonly options: readonly { readonly value: string; readonly text: string }[];
    readonly placeholderText?: string;
  } & ValueProps<T, string>
): ReactElement {
  if (props.multi) {
    return <></>;
  }
  return (
    <Row className="inline-flex mt-20 space-x-6 mb-6">
      <TabButton
        label={props.options[0].text}
        active={props.value === props.options[0].value || props.value === undefined}
        disabled={false}
        onClick={() => {
          props.onValueChange(props.options[0].value);
        }}
      />
      <TabButton
        label={props.options[1].text}
        active={props.value === props.options[1].value || props.value === undefined}
        disabled={false}
        onClick={() => {
          props.onValueChange(props.options[1].value);
        }}
      />
    </Row>
  );
}

export function RequiredField({
  field,
  translate,
}: {
  readonly field: unknown | undefined;
  readonly translate: TranslateFn;
}): JSX.Element {
  if (field === undefined || field === "") {
    return (
      <div className="text-danger text-[9px] mt-[-1.4rem] flex flex-row font-bold justify-center">
        <Icon icon="fal_faTriangleExclamation" className="mr-[1px] mt-[-2]" colorClass={"text-danger"} />
        {translate(texts.field_required)}
      </div>
    );
  } else {
    return <></>;
  }
}

export function RequiredContainer({
  values,
  translate,
  hideUnitColumn,
}: {
  readonly values: readonly Value<unknown | undefined>[];
  readonly translate: TranslateFn;
  readonly hideUnitColumn?: boolean;
}): JSX.Element {
  return (
    <Row>
      <ColumnName />
      {values.map(({ id, value, visible, highlighted }) =>
        visible ? (
          <EmptyColumn key={id} highlighted={highlighted && values.length > 1}>
            <RequiredField translate={translate} field={value} />
          </EmptyColumn>
        ) : (
          <EmptyColumn highlighted={highlighted} key={id} />
        )
      )}
      {!hideUnitColumn && <UnitColumn />}
    </Row>
  );
}
