import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch, EffectManager } from "@typescript-tea/core";
import { HttpFetchCmd, mapCmd, RequestedResponseType } from "./http-fetch-cmd";
import { home } from "./home";

export function createEffectManager(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub: (_, _sub) => {
      throw new Error("This effect manager has no subs.");
    },
    setup: () => () => undefined,
    onEffects,
    onSelfAction: () => ({}),
  };
}

// -- STATE

interface State {}

function init(): readonly [State] {
  return [{}];
}

// -- COMMANDS

// -- SUBSCRIPTIONS

// -- MANAGER

function onEffects<ActionApp>(
  dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  cmds: readonly HttpFetchCmd<ActionApp>[],
  _subs: readonly never[],
  state: State = init()
): State {
  for (const cmd of cmds) {
    switch (cmd.type) {
      case "FetchOne": {
        httpFetch(cmd.headers, cmd.url, cmd.responseType).then((data) => {
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess(data));
        });
        break;
      }
      case "FetchMultiple": {
        Promise.all(cmd.urls.map((url) => httpFetch(cmd.headers, url, cmd.responseType))).then((responsesData) => {
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess(responsesData));
        });
        break;
      }
      case "Post": {
        fetch(cmd.url, {
          method: "POST",
          headers: { "Content-Type": cmd.contentType, ...cmd.headers },
          body: cmd.body,
        })
          .then((response) => response.json())
          .then((result) => cmd.onSuccess && dispatchApp(cmd.onSuccess(result)));
        break;
      }
      default: {
        exhaustiveCheck(cmd, true);
      }
    }
  }
  return [state];
}

async function httpFetch(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: RequestedResponseType
): Promise<JSON | string | ArrayBuffer> {
  const response = await fetch(url, {
    headers: headers,
  });

  response.arrayBuffer;

  switch (responseType) {
    case "json": {
      return response.json();
    }
    case "text": {
      return response.text();
    }
    case "arrayBuffer": {
      return response.arrayBuffer();
    }

    default: {
      return exhaustiveCheck(responseType, true);
    }
  }
}
