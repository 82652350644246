import React, { ReactElement } from "react";
import { Dispatch } from "@typescript-tea/core";
import { Amount, Unit } from "uom";
import { Quantity, Units } from "uom-units";
import { AnyQuantity, amountAs } from "@lcc/shared/src/uom";
import { CalculationInputs, FanFlowType, HeatingType, FanCalculationType } from "@lcc/shared/src/calculation";
import { NumberFormat, texts, TranslateFn } from "@lcc/shared";
import * as SharedState from "../../../../../infrastructure/shared-state";
import { Action } from "../state";
import * as Page from "../../page";
import { getNumberFormat, getUserUnits } from "../../../../../infrastructure/shared-state";
import {
  InputAmountRow,
  InputNumberRow,
  InputSelectRow,
  InputTextRow,
  SubHeading,
  RemoveAhuButton,
  InputRadiobuttonRow,
  InputCheckboxRow,
} from "./shared";
import { PrimaryButton, withTw } from "../../../../../components/elements";

const HideFanCalculationSelector = withTw("div", "hidden");

export function AhuInput({
  dispatch,
  sharedState,
  calculationInputMain,
  calculationInputAhu,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly calculationInputMain: CalculationInputs;
  readonly calculationInputAhu: readonly Page.CalculationAhuInputsWithId[];
}): JSX.Element {
  const translate = sharedState.lang.translate;
  const userUnits = getUserUnits(sharedState);
  const numberFormat = getNumberFormat(sharedState.lang);

  return (
    <>
      {ahuData(translate, dispatch, numberFormat, calculationInputMain, calculationInputAhu, userUnits, sharedState)}
      {fanData(translate, dispatch, numberFormat, calculationInputMain, calculationInputAhu, userUnits, sharedState)}
      {additionalHeat(translate, dispatch, numberFormat, calculationInputMain, calculationInputAhu)}
    </>
  );
}

function ahuData(
  translate: TranslateFn,
  dispatch: Dispatch<Action>,
  numberFormat: NumberFormat,
  calculationInputMain: CalculationInputs,
  calculationInput: readonly Page.CalculationAhuInputsWithId[],
  userUnits: Record<string, string>,
  sharedState: SharedState.SharedState
): ReactElement {
  const sfpArrayEntire = ["sfpeEntireUnit", "sfpvEntireUnit"];

  const fanCalculationDependingOnMarket = sharedState.market?.fan_calculation;

  const splitComma: string[] | undefined = fanCalculationDependingOnMarket?.split(",");

  const convertToOptions = splitComma?.map((r) => [{ value: r, text: translate(texts.sfp_calculation_type(r)) }]);

  const marketDependingOptions = convertToOptions?.flat();

  return (
    <div className="h-full">
      <SubHeading>
        {translate(texts.air_handling_units)}
        <PrimaryButton
          title={translate(texts.maximum_units_comparison)}
          disabled={calculationInput.length >= 3}
          onClick={() => {
            dispatch(Action.AddAhu());
          }}
        >
          <svg fill="#ffffff" width="16" height="16" viewBox="0 0 24 24">
            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
          </svg>
        </PrimaryButton>
      </SubHeading>
      <div className="mb-8">{translate(texts.ahu_header_info)}</div>
      <RemoveAhuButton values={calculationInput} onClick={(id) => dispatch(Action.RemoveAhu(id))} />

      {calculationInput.length > 1 && (
        <InputRadiobuttonRow
          translate={translate}
          multi={true}
          valueKey={"selectedForCompare"}
          values={calculationInput}
          name={translate(texts.selected_for_compare)}
          onValuesChange={(id) => dispatch(Action.SetCalcAhuSelected(id))}
        />
      )}

      <InputTextRow
        required={true}
        translate={translate}
        multi={true}
        autoFocusOnLoad={true}
        valueKey={"unitName"}
        values={calculationInput}
        name={translate(texts.unit_name)}
        onValuesChange={(id, value) => dispatch(Action.SetCalcAhuInput(id, { unitName: value }))}
      />

      <InputNumberRow
        compactUnitSelector={true}
        required={true}
        translate={translate}
        multi={true}
        valueKey={"offerAmount"}
        values={calculationInput}
        numberFormat={numberFormat}
        name={translate(texts.offer_amount)}
        unit={calculationInputMain.currency}
        onValuesChange={(id, value) => dispatch(Action.SetCalcAhuInput(id, { offerAmount: value }))}
      />

      <InputSelectRow
        fieldName={"fanFlowTypeSelection"}
        required={true}
        translate={translate}
        name={translate(texts.flow_type)}
        multi={true}
        valueKey={"fanFlowType"}
        values={calculationInput}
        onValuesChange={(id, v: string) => {
          dispatch(
            Action.SetCalcAhuInput(id, {
              correctionVariableFlowTemp: calculationInput.find((c) => c.id === id)?.correctionVariableFlow,
            })
          );
          dispatch(
            Action.SetCalcAhuInput(id, {
              correctionVariableFlow:
                v === "constant"
                  ? undefined
                  : calculationInput.find((c) => c.id === id)?.correctionVariableFlowTemp ??
                    Amount.create(0.65, Units.One),
            })
          );
          dispatch(
            Action.SetCalcAhuInput(id, {
              fanFlowType: v as FanFlowType,
            })
          );
        }}
        options={[
          { value: "constant", text: translate(texts.constant_flow) },
          { value: "variable", text: translate(texts.variable_flow) },
        ]}
      />

      <InputAmountRow
        required={true}
        translate={translate}
        name={translate(texts.correction_variable_flow)}
        multi={true}
        valueKey={"correctionVariableFlow"}
        values={calculationInput}
        numberFormat={numberFormat}
        defaultUnit={Units.One}
        unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
        message={translate(texts.variable_flow_info_message)}
        isVisible={(v) => v.fanFlowType === "variable"}
        hideUnitSelect={true}
        onValuesChange={(id, value: Amount.Amount<Quantity.Dimensionless>) =>
          dispatch(
            Action.SetCalcAhuInput(id, {
              correctionVariableFlow: value,
            })
          )
        }
      />
      {}

      {sharedState.market?.market === "Canada" || sharedState.market?.market === "United States of America" ? (
        <HideFanCalculationSelector>
          <InputSelectRow
            required={true}
            translate={translate}
            name={translate(texts.fan_calculation)}
            multi={false}
            value={calculationInputMain.fanCalculationType}
            defaultUserChoice={"sfpeEntireUnit"}
            onValueChange={(v: string) => {
              dispatch(
                Action.SetCalcInput({
                  fanCalculationType: v as FanCalculationType,
                })
              );
            }}
            options={marketDependingOptions!}
          />
        </HideFanCalculationSelector>
      ) : (
        <InputSelectRow
          required={true}
          translate={translate}
          name={translate(texts.fan_calculation)}
          multi={false}
          value={calculationInputMain.fanCalculationType}
          defaultUserChoice={"sfpeEntireUnit"}
          onValueChange={(v: string) => {
            dispatch(
              Action.SetCalcInput({
                fanCalculationType: v as FanCalculationType,
              })
            );
          }}
          options={marketDependingOptions!}
        />
      )}
      {sfpArrayEntire.map((calcType) => (
        <InputAmountRow
          key={calcType}
          required={true}
          translate={translate}
          name={
            calcType === "sfpeEntireUnit"
              ? translate(texts.sfp_calculation_type("sfpeEntireUnit"))
              : calcType === "sfpvEntireUnit"
              ? translate(texts.sfp_calculation_type("sfpvEntireUnit"))
              : ""
          }
          multi={true}
          valueKey={"specificFanPowerTotal"}
          values={calculationInput}
          numberFormat={numberFormat}
          userUnits={userUnits}
          isVisible={() => calculationInputMain.fanCalculationType === calcType}
          defaultUnit={Units.KiloWattPerCubicMeterPerSecond}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          onValuesChange={(id, value: Amount.Amount<Quantity.SpecificFanPower>) =>
            dispatch(
              Action.SetCalcAhuInput(id, {
                specificFanPowerTotal: value,
              })
            )
          }
        />
      ))}
    </div>
  );
}

function fanData(
  translate: TranslateFn,
  dispatch: Dispatch<Action>,
  numberFormat: NumberFormat,
  calculationInputMain: CalculationInputs,
  calculationInput: readonly Page.CalculationAhuInputsWithId[],
  userUnits: Record<string, string>,
  sharedState: SharedState.SharedState
): ReactElement {
  const sfpArraySupply = ["sfpePerFan", "sfpvPerFan"];

  const sfpArrayExtract = ["sfpePerFan", "sfpvPerFan"];
  return (
    <>
      <div>
        <SubHeading>{translate(texts.supply_fan_data)}</SubHeading>
        <InputAmountRow
          required={true}
          translate={translate}
          name={translate(texts.nominal_flow)}
          multi={true}
          valueKey={"nominalFlowSupply"}
          numberFormat={numberFormat}
          values={calculationInput}
          userUnits={userUnits}
          defaultUnit={Units.CubicMeterPerSecond}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          onValuesChange={(id, value: Amount.Amount<Quantity.VolumeFlow>) =>
            dispatch(
              Action.SetCalcAhuInput(id, {
                nominalFlowSupply: value,
              })
            )
          }
        />
        {sfpArraySupply.map((calcType) => (
          <InputAmountRow
            key={calcType}
            required={true}
            translate={translate}
            name={
              calcType === "sfpePerFan"
                ? translate(texts.specific_fan_power_e)
                : calcType === "sfpvPerFan"
                ? translate(texts.specific_fan_power_v)
                : ""
            }
            multi={true}
            valueKey={"specificFanPowerSupply"}
            values={calculationInput}
            numberFormat={numberFormat}
            userUnits={userUnits}
            isVisible={() => calculationInputMain.fanCalculationType === calcType}
            defaultUnit={Units.KiloWattPerCubicMeterPerSecond}
            unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
            onValuesChange={(id, value: Amount.Amount<Quantity.SpecificFanPower>) =>
              dispatch(
                Action.SetCalcAhuInput(id, {
                  specificFanPowerSupply: value,
                })
              )
            }
          />
        ))}

        <InputAmountRow
          required={true}
          translate={translate}
          name={translate(texts.fan_power)}
          multi={true}
          valueKey={"fanPowerSupply"}
          values={calculationInput}
          numberFormat={numberFormat}
          userUnits={userUnits}
          isVisible={() =>
            calculationInputMain.fanCalculationType === "powerPerFan" || sharedState.market?.market === "Canada"
          }
          defaultUnit={Units.KiloWatt}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          onValuesChange={(id, value: Amount.Amount<Quantity.Power>) =>
            dispatch(
              Action.SetCalcAhuInput(id, {
                fanPowerSupply: value,
              })
            )
          }
        />
      </div>
      <div>
        <SubHeading>{translate(texts.extract_fan_data)}</SubHeading>
        <InputAmountRow
          required={true}
          translate={translate}
          name={translate(texts.nominal_flow)}
          multi={true}
          valueKey={"nominalFlowExtract"}
          values={calculationInput}
          numberFormat={numberFormat}
          userUnits={userUnits}
          defaultUnit={Units.CubicMeterPerSecond}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          onValuesChange={(id, value: Amount.Amount<Quantity.VolumeFlow>) =>
            dispatch(
              Action.SetCalcAhuInput(id, {
                nominalFlowExtract: value,
              })
            )
          }
        />

        {sfpArrayExtract.map((calcType) => (
          <InputAmountRow
            key={calcType}
            required={true}
            translate={translate}
            name={
              calcType === "sfpePerFan"
                ? translate(texts.specific_fan_power_e)
                : calcType === "sfpvPerFan"
                ? translate(texts.specific_fan_power_v)
                : ""
            }
            multi={true}
            valueKey={"specificFanPowerExtract"}
            values={calculationInput}
            numberFormat={numberFormat}
            userUnits={userUnits}
            isVisible={() => calculationInputMain.fanCalculationType === calcType}
            defaultUnit={Units.KiloWattPerCubicMeterPerSecond}
            unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
            onValuesChange={(id, value: Amount.Amount<Quantity.SpecificFanPower>) =>
              dispatch(
                Action.SetCalcAhuInput(id, {
                  specificFanPowerExtract: value,
                })
              )
            }
          />
        ))}

        <InputAmountRow
          required={true}
          translate={translate}
          name={translate(texts.fan_power)}
          multi={true}
          valueKey={"fanPowerExtract"}
          values={calculationInput}
          numberFormat={numberFormat}
          userUnits={userUnits}
          isVisible={() =>
            calculationInputMain.fanCalculationType === "powerPerFan" || sharedState.market?.market === "Canada"
          }
          defaultUnit={Units.KiloWatt}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          onValuesChange={(id, value: Amount.Amount<Quantity.Power>) =>
            dispatch(
              Action.SetCalcAhuInput(id, {
                fanPowerExtract: value,
              })
            )
          }
        />
        {/* <InputAmountRow
          name={translate(texts.pressure_drop_connected_ducts)}
          multi={true}
          valueKey={"pressureDropExtract"}
          values={calculationInput}
          userUnits={userUnits}
          defaultUnit={Units.Pascal}
          unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit(unit.quantity, unit.name, 2))}
          onValuesChange={(id, value: Amount.Amount<Quantity.Pressure>) =>
            dispatch(
              Action.SetCalcAhuInput(id, {
                pressureDropExtract: value,
              })
            )
          }
        /> */}
      </div>
    </>
  );
}

function additionalHeat(
  translate: TranslateFn,
  dispatch: Dispatch<Action>,
  numberFormat: NumberFormat,
  calculationInputMain: CalculationInputs,
  calculationInput: readonly Page.CalculationAhuInputsWithId[]
): ReactElement {
  return (
    <div>
      <SubHeading>{translate(texts.heating_recovery_additional_heat)}</SubHeading>
      <InputSelectRow
        translate={translate}
        name={translate(texts.heating_type)}
        multi={true}
        valueKey={"heatingType"}
        values={calculationInput}
        onValuesChange={(id, v: string) =>
          dispatch(
            Action.SetCalcAhuInput(id, {
              heatingType: v as HeatingType,
            })
          )
        }
        options={[
          { value: "none", text: translate(texts.none) },
          { value: "plateStandardBypass", text: translate(texts.plate_standard_bypass) },
          { value: "plateSectionDefrost", text: translate(texts.plate_section_defrost) },
          { value: "wheel", text: translate(texts.rotary_heater) },
          { value: "battery", text: translate(texts.battery_heater) },
        ]}
      />

      <InputAmountRow
        required={true}
        translate={translate}
        key="temperature_efficiency"
        name={translate(texts.temperature_efficiency_dry)}
        multi={true}
        numberFormat={numberFormat}
        valueKey={"temperatureEfficiency"}
        values={calculationInput}
        defaultUnit={Units.Percent}
        unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
        onValuesChange={(id, value: Amount.Amount<Quantity.Dimensionless>) =>
          dispatch(
            Action.SetCalcAhuInput(id, {
              temperatureEfficiency: value,
            })
          )
        }
        isVisible={(v) => v.heatingType !== "none"}
        errorMsg={(_, value, defaultUnit) =>
          value && (amountAs(defaultUnit, value) < 0 || amountAs(defaultUnit, value) > 90)
            ? translate(texts.value_should_be_between("0", "90"))
            : ""
        }
      />

      <InputAmountRow
        required={true}
        translate={translate}
        key="t_min_outlet_exhaust_exchanger"
        name={translate(texts.t_min_outlet_exhaust_exchanger)}
        multi={true}
        numberFormat={numberFormat}
        valueKey={"tMinOutletExhaustExchanger"}
        values={calculationInput}
        message={translate(texts.outlet_exhaust_exchanger_info_message)}
        defaultUnit={Units.Celsius}
        unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
        fieldName={"outletExhaustExchanger"}
        onValuesChange={(id, value: Amount.Amount<Quantity.Temperature>) =>
          dispatch(
            Action.SetCalcAhuInput(id, {
              tMinOutletExhaustExchanger: value,
            })
          )
        }
        isVisible={(v) =>
          v.heatingType !== "none" &&
          v.heatingType !== "wheel" &&
          calculationInputMain.annualAverageTemperatureInput === "selection"
        }
        errorMsg={(_, value, defaultUnit) =>
          value && (amountAs(defaultUnit, value) < 0 || amountAs(defaultUnit, value) > 4)
            ? translate(texts.value_should_be_between("0", "4"))
            : ""
        }
      />

      <InputCheckboxRow
        translate={translate}
        multi={true}
        valueKey={"defrostFunctionIncluded"}
        values={calculationInput}
        name={translate(texts.defrost_function_included)}
        isVisible={(v) =>
          v.heatingType !== "none" &&
          v.heatingType !== "wheel" &&
          calculationInputMain.annualAverageTemperatureInput === "selection"
        }
        onValuesChange={(id, value) =>
          dispatch(
            Action.SetCalcAhuInput(id, {
              defrostFunctionIncluded: value,
            })
          )
        }
      />
    </div>
  );
}
