// Calculates required additional heating degree hours

import { ClimateLocationResponse } from "../../climate-data-api";
import * as GQLOps from "../../generated/generated-operations";
import { HeatingType } from "../types";
import { interpolateHeatingDegree } from "./freezingCorrection";

const airDensity = 1;
const specificHeatCapacityAir = 1;
const tab = 0;
export function calculateAdditionalHeatingDegreeHoursManualAvgTemp(
  annualAvareregeTempC: number,
  supplyAirTemperatureC: number,
  exhaustAirTempBeforeExcC: number,
  temperatureEfficiencyPercent: number,
  supplyAirFlowMs: number,
  extractAirflowms: number,
  annualOperationTimeH: number,
  temperatureIncreaseFanC: number,
  degreeHoursAdditionalHeatTable: ReadonlyArray<GQLOps.HeatingDegreeHoursAdditionalHeat_QueryFragment>
): number {
  const k = supplyAirFlowMs / extractAirflowms;

  const supplyTempWithFanIncrease = supplyAirTemperatureC - temperatureIncreaseFanC;
  const temperatureEfficiencyOne = temperatureEfficiencyPercent / 100;

  const correctionsFactorGc = 1 - temperatureEfficiencyOne;
  const correctionFactorGd = temperatureEfficiencyOne;
  const correctionFactorGe = 1;

  const temperatureLimitTgC =
    (supplyTempWithFanIncrease - temperatureEfficiencyOne * exhaustAirTempBeforeExcC) / correctionsFactorGc;
  const temperatureLimitTgD = (k * tab - k * temperatureEfficiencyOne * exhaustAirTempBeforeExcC) / correctionFactorGd;
  const temperatureLimitTgE = (supplyTempWithFanIncrease - k * exhaustAirTempBeforeExcC + k * tab) / correctionFactorGe;

  return getInterpolatedDegreeHours(
    temperatureLimitTgC,
    temperatureLimitTgD,
    temperatureLimitTgE,
    annualAvareregeTempC,
    supplyAirFlowMs,
    annualOperationTimeH,
    correctionsFactorGc,
    correctionFactorGd,
    correctionFactorGe,
    degreeHoursAdditionalHeatTable
  );
}

export function calculateAdditionalHeatingDegreeHoursLocationAvgTemp(
  _annualAvareregeTempC: number,
  supplyAirTemperatureC: number,
  exhaustAirTempBeforeExcC: number,
  temperatureIncreaseFanC: number,
  temperatureEfficiencyPercent: number,
  climateLocationData: ClimateLocationResponse,
  annualOperationTimeH: number,
  defrostFunctionIncluded: boolean,
  tMinOutletExhaustExchangerTempC: number,
  heatingType: HeatingType
): number | undefined {
  if (climateLocationData.length === 0) {
    return undefined;
  }

  const supplyTempWithFanIncrease = supplyAirTemperatureC - temperatureIncreaseFanC;
  const temperatureEfficiencyOne = temperatureEfficiencyPercent / 100;

  // RYDELL

  const tMax =
    supplyTempWithFanIncrease <= exhaustAirTempBeforeExcC
      ? (supplyTempWithFanIncrease - exhaustAirTempBeforeExcC * temperatureEfficiencyOne) /
        (1 - temperatureEfficiencyOne)
      : exhaustAirTempBeforeExcC;

  let heatingDegreeHours = 0;
  for (const r of climateLocationData) {
    if (r.temperature >= tMax) {
      continue;
    }

    // Additional Heat required:
    let tMaxRecovered = 0;
    // Frost Issue:
    if (defrostFunctionIncluded && heatingType !== "wheel") {
      if (r.temperature >= tMax) {
        tMaxRecovered = 0;
      } else if (
        (exhaustAirTempBeforeExcC - r.temperature) * temperatureEfficiencyOne <
        exhaustAirTempBeforeExcC - tMinOutletExhaustExchangerTempC
      ) {
        tMaxRecovered = (exhaustAirTempBeforeExcC - r.temperature) * temperatureEfficiencyOne;
      } else {
        tMaxRecovered = exhaustAirTempBeforeExcC - tMinOutletExhaustExchangerTempC;
      }
    } else {
      //No Frost issues
      // eslint-disable-next-line no-lonely-if
      if (r.temperature >= tMax) {
        tMaxRecovered = 0;
      } else {
        tMaxRecovered = (exhaustAirTempBeforeExcC - r.temperature) * temperatureEfficiencyOne;
      }
    }

    const toHeat = supplyTempWithFanIncrease - r.temperature - tMaxRecovered;

    heatingDegreeHours += Math.abs(toHeat);
  }

  return heatingDegreeHours * (annualOperationTimeH / 8760);
}

function getInterpolatedDegreeHours(
  temperatureLimitTgC: number,
  temperatureLimitTgD: number,
  temperatureLimitTgE: number,
  annualAvareregeTempC: number,
  supplyAirFlowMs: number,
  annualOperationTimeH: number,
  correctionFactorGc: number,
  correctionFactorGd: number,
  correctionFactorGe: number,
  degreeHoursAdditionalHeatTable: ReadonlyArray<GQLOps.HeatingDegreeHoursAdditionalHeat_QueryFragment>
): number {
  if (temperatureLimitTgC > temperatureLimitTgD) {
    const gTTgc = interpolateHeatingDegree(
      degreeHoursAdditionalHeatTable,
      temperatureLimitTgC,
      annualAvareregeTempC,
      "supply_air_temp",
      "annual_average_temp"
    );

    const gTTgd = interpolateHeatingDegree(
      degreeHoursAdditionalHeatTable,
      temperatureLimitTgD,
      annualAvareregeTempC,
      "supply_air_temp",
      "annual_average_temp"
    );

    const gTTgcNumber = gTTgc === undefined ? 0 : gTTgc;
    const gTTgdNumber = gTTgd === undefined ? 0 : gTTgd;
    const ec =
      airDensity *
      specificHeatCapacityAir *
      supplyAirFlowMs *
      (annualOperationTimeH / 8760) *
      correctionFactorGc *
      gTTgcNumber;

    const ed =
      airDensity *
      specificHeatCapacityAir *
      supplyAirFlowMs *
      (annualOperationTimeH / 8760) *
      correctionFactorGd *
      gTTgdNumber;

    return ec + ed;
  } else {
    const gTTge = interpolateHeatingDegree(
      degreeHoursAdditionalHeatTable,
      temperatureLimitTgE,
      annualAvareregeTempC,
      "supply_air_temp",
      "annual_average_temp"
    );

    const gTTgeNumber = gTTge === undefined ? 0 : gTTge;
    return (
      airDensity *
      specificHeatCapacityAir *
      supplyAirFlowMs *
      (annualOperationTimeH / 8760) *
      correctionFactorGe *
      gTTgeNumber
    );
  }
}
