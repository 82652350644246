import * as resultsAdapter from "./results-report/adapter";
import { AdapterType } from "./results-report/adapter";
import { ReportType } from "./types";

const reportAdapters = [resultsAdapter].map((adapter) => ({ ...adapter }));

export function getReportAdapters(name: ReportType): { readonly execute: AdapterType; readonly Name: string } {
  const adapter = reportAdapters.find((x) => x.Name === name);
  if (!adapter) {
    throw new Error(`could not find adapter: ${name}`);
  }
  return adapter;
}
