import * as React from "react";
import { Icon, withTw } from ".";
import { AvailableIcons } from "./icon";

export interface ButtonProps {
  readonly label: string;
  readonly title?: string;
  readonly type?: "primary" | "secondary" | "danger" | "nav";
  readonly url?: string;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly iconLeft?: AvailableIcons;
  readonly iconRight?: AvailableIcons;
  readonly spinnerIcon?: boolean;
  readonly large?: boolean;
}

export type StyledButtonProps = {
  readonly buttontype: "primary" | "secondary" | "danger" | "nav";
  readonly disabled: boolean;
  readonly large: boolean;
};

export const StyledButton = withTw(
  "a",
  "cursor-pointer btn flex flex-row justify-center",
  ({ large }: StyledButtonProps) => (large ? "text-sm" : "text-xs btn-tiny"),
  ({ buttontype }: StyledButtonProps) => {
    switch (buttontype) {
      case "primary":
        return "btn-primary";
      case "secondary":
        return "btn-secondary";
      case "danger":
        return "btn-danger";
      case "nav":
        return "btn-nav";
      default:
        return "";
    }
  },
  ({ disabled }: StyledButtonProps) => (disabled ? "btn-disabled" : "")
);

export function ButtonLink({
  label,
  title,
  type = "primary",
  url,
  disabled = false,
  className,
  iconLeft,
  iconRight,
  spinnerIcon,
  large = true,
}: ButtonProps): JSX.Element {
  return (
    <div>
      <StyledButton title={title} buttontype={type} disabled={disabled} large={large} className={className} href={url}>
        {iconLeft && <Icon className={spinnerIcon ? "fa-spin mr-8" : "font-normal mr-8"} icon={iconLeft} />}
        {label && <span className="whitespace-nowrap">{label}</span>}
        {iconRight && <Icon className={spinnerIcon ? "fa-spin ml-8" : "ml-8"} icon={iconRight} />}
      </StyledButton>
    </div>
  );
}
