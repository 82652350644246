import React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../../infrastructure/shared-state";
import { Action, DataReceived, State } from "./state";
import * as Page from "../page";
import { AhuInput } from "./components";
import { Conditions } from "./components/conditions";
import { EconomicFactors } from "./components/economic-factors";
import { ProjectNameInput } from "./components/project-name";

export function View({
  state,
  sharedState,
  dispatch,
  pageState,
}: {
  readonly state: State | undefined;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly pageState: Page.State;
}): JSX.Element {
  if (!pageState || !state || state.type === "WaitingOnData") {
    return <div>Loading</div>;
  }

  if (pageState.type !== "CalculationState") {
    return <span />;
  }

  return (
    <div className="h-full">
      <div>
        <div className="flex flex-col space-y-40">
          <div className="flex flex-col space-y-40 ">
            <RenderSection state={state} sharedState={sharedState} dispatch={dispatch} pageState={pageState} />
          </div>
        </div>
      </div>
    </div>
  );
}

function RenderSection({
  state,
  sharedState,
  dispatch,
  pageState,
}: {
  readonly state: DataReceived;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly pageState: Page.StateCalculation;
}): JSX.Element {
  switch (pageState.selectedSection) {
    case "project_info":
      return <ProjectNameInput dispatch={dispatch} state={state} sharedState={sharedState} pageState={pageState} />;
    case "economic_factors":
      return <EconomicFactors dispatch={dispatch} state={state} sharedState={sharedState} pageState={pageState} />;
    case "conditions":
      return <Conditions dispatch={dispatch} state={state} sharedState={sharedState} pageState={pageState} />;
    case "ahu_inputs":
      return (
        <AhuInput
          dispatch={dispatch}
          sharedState={sharedState}
          calculationInputMain={pageState.calculationInput}
          calculationInputAhu={pageState.calculationInputAhu}
        />
      );

    default:
      return <></>;
  }
}
