import gql from "graphql-tag";
// import { PropertyValue } from "@promaster-sdk/property";
// import { ProductDataService } from "@uc/service-effects/lib/product-data-service";
// import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GqlOps from "../generated/generated-operations";
import { KeyParams, NormalizedText } from "./normalized-text";
import { replaceCurly } from "./replace-curly";

export interface Translations {
  readonly selected: { readonly [key: string]: string | undefined };
  readonly english: { readonly [key: string]: string | undefined };
}

export type LanguageCode = string;

export const LangTexts_TextsFragment = gql`
  fragment LangTexts_Texts on Texts_Text {
    name
    text
  }
`;

export const textsQuery = gql`
  query GetTexts($productId: ID!, $language: String!) {
    product(id: $productId) {
      modules {
        texts {
          text(language: $language) {
            ...LangTexts_Texts
          }
        }
      }
    }
  }
  ${LangTexts_TextsFragment}
`;

// export function* loadTranslations(
//   productId: string,
//   language: LanguageCode
// ): Program<Translations, readonly [ProductDataService]> {
//   const data = yield* ProductDataService.fetchProductData<GqlOps.GetTextsQuery, GqlOps.GetTextsQueryVariables>(
//     textsQuery,
//     {
//       productId: productId,
//       language: language,
//     }
//   );
//   return translationsFromProductTexts(data.product?.modules.texts.text);
// }

export function translationsFromProductTexts(
  textSelected: readonly GqlOps.LangTexts_TextsFragment[] | undefined,
  textEnglish: readonly GqlOps.LangTexts_TextsFragment[] | undefined
): Translations {
  type MutableTranslations = { [key: string]: string | undefined };
  const selected: MutableTranslations = {};
  for (const x of textSelected ?? []) {
    if (x.name !== null && x.text !== null) {
      selected[x.name] = x.text;
    }
  }
  const english: MutableTranslations = {};
  for (const x of textEnglish ?? []) {
    if (x.name !== null && x.text !== null) {
      english[x.name] = x.text;
    }
  }
  return { selected, english };
}

export type TextTranslateFn<TTextTypes> = (text: TTextTypes) => string;

// export function* loadAndCreateTranslateFn<TTextTypes>(
//   productId: string,
//   productKey: string,
//   normalizeTextFn: (text: TTextTypes) => NormalizedText,
//   languageCode: LanguageCode
// ): Program<TextTranslateFn<TTextTypes>, readonly [ProductDataService]> {
//   const loadedTexts = yield* loadTranslations(productId, languageCode);
//   return (text) => translateNormalizedText(productKey, loadedTexts, normalizeTextFn(text));
// }

export function translateNormalizedText(productKey: string, translations: Translations, text: NormalizedText): string {
  const rawTranslation = translations.selected[text.key] || translations.english[text.key];
  const translation =
    rawTranslation === undefined
      ? getUndefinedTextPlaceholderFromNormalizedText(productKey, text)
      : replaceCurly(rawTranslation, text.params);
  return translation;
}

function getUndefinedTextPlaceholderFromNormalizedText(productKey: string, text: NormalizedText): string {
  return getUndefinedTextPlaceholder(productKey, text.key, text.params);
}

export function getUndefinedTextPlaceholder(productKey: string, key: string, params?: KeyParams): string {
  return params && Object.keys(params).length > 0
    ? `{${productKey}.${key} ${JSON.stringify(params)}}`
    : `{${productKey}.${key}}`;
}

export function getUndefinedPropertyNamePlaceholder(productKey: string, propertyName: string): string {
  return `{${productKey}.${propertyName}}`;
}

// export function getUndefinedPropertyValuePlaceholder(
//   productKey: string,
//   propertyName: string,
//   propertyValue?: PropertyValue.PropertyValue
// ): string {
//   if (!propertyValue) {
//     return `{${productKey}.${propertyName}}`;
//   }
//   switch (propertyValue.type) {
//     case "amount":
//       return propertyValue.value.value.toString();
//     case "integer":
//       return `{${productKey}.${propertyName} value ${propertyValue.value}}`;
//       break;
//     case "text":
//       return propertyValue.value;
//     default:
//       exhaustiveCheck(propertyValue);
//       return "";
//   }
// }
