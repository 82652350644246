import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { texts, TranslateFn } from "@lcc/shared";
import * as State from "./state";
import * as Page from "./parts/page";
import { Icon, withTw } from "../../components/elements";
import { isValidSection } from "./view";

const Hamburger = withTw("span", "lg:invisible sm:visible md:visible absolute cursor-pointer p-[0.75em] left-0");

export interface TreeToggleStateProps {
  readonly showMenu: boolean;
  readonly dispatch: Dispatch<State.Action>;
}

export function ProductTreeMenuContainerToggler({
  dispatch,
  showMenu,
}: TreeToggleStateProps): React.ReactElement<TreeToggleStateProps> {
  return (
    <Hamburger onClick={() => dispatch(State.Action.ToggleShow(!showMenu))}>
      {showMenu ? <Icon icon="far_faXmarkLarge" /> : <Icon icon="far_faBars" />}
    </Hamburger>
  );
}

export function Navbar({
  translate,
  dispatch,
  pageState,
  showSideMenu,
}: {
  readonly translate: TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
  readonly pageState: Page.StateCalculation;
  readonly showSideMenu: boolean;
}): JSX.Element {
  return (
    <div
      style={{ minHeight: "calc(160vh - 3rem)" }}
      className={
        showSideMenu
          ? "bg-neutral-50 border-r-4 visible h-full border-neutral-100 p-200 pt-600 top-0 bottom-0 w-[130px] sm:w-[130px] md:w-[190px] lg:w-[350px] capitalize"
          : "invisible sm:invisible md:invisible lg:visible w-0 bg-neutral-50 border-r-4 h-full border-neutral-100 p-200 pt-600 top-0 bottom-0 lg:w-[350px] capitalize"
      }
    >
      <NavButton
        label={translate(texts.project_sign)}
        active={pageState.selectedSection === "project_info"}
        checked={isValidSection(pageState, "project_info")}
        onClick={() => dispatch(State.Action.PageAction(Page.PageAction.SetSelectedSection("project_info")))}
      />
      <div className="mt-6" />
      <NavButton
        label={translate(texts.economic_factors)}
        active={pageState.selectedSection === "economic_factors"}
        checked={isValidSection(pageState, "economic_factors")}
        onClick={() => dispatch(State.Action.PageAction(Page.PageAction.SetSelectedSection("economic_factors")))}
      />
      <div className="mt-8" />
      <NavButton
        label={translate(texts.conditions)}
        active={pageState.selectedSection === "conditions"}
        checked={isValidSection(pageState, "conditions")}
        onClick={() => dispatch(State.Action.PageAction(Page.PageAction.SetSelectedSection("conditions")))}
      />
      <div className="mt-6" />
      <NavButton
        label={translate(texts.air_handling_units)}
        active={pageState.selectedSection === "ahu_inputs"}
        checked={isValidSection(pageState, "ahu_inputs")}
        onClick={() => dispatch(State.Action.PageAction(Page.PageAction.SetSelectedSection("ahu_inputs")))}
      />
      <div className="mt-16" />
      <NavButton
        label={translate(texts.result)}
        active={pageState.selectedSection === "result"}
        checked={isValidSection(pageState, "result")}
        onClick={() => dispatch(State.Action.PageAction(Page.PageAction.SetSelectedSection("result")))}
      />
    </div>
  );
}

export function NavButton({
  label,
  active,
  checked,
  onClick,
}: {
  readonly label: string;
  readonly active: boolean;
  readonly checked?: boolean;
  readonly onClick: (e: React.MouseEvent) => void;
}): JSX.Element {
  return (
    <div className={`p-4 hover:cursor-pointer flex flex-row shrink space-x-12 items-center`} onClick={onClick}>
      {checked ? <Icon icon="fal_faCircleCheck"></Icon> : <Icon icon="fal_faCircle"></Icon>}

      <span className={`${active ? "text-sm text-black font-bold" : " border-neutral-700 text-sm text-neutral-700"}`}>
        {label}
      </span>
    </div>
  );
}
