/* eslint-disable @typescript-eslint/no-explicit-any */
import * as AbstractImage from "abstract-image";
import React, { ReactElement } from "react";
import { Amount } from "uom";
import ReactGA from "react-ga4";
import { Units } from "uom-units";
import { Dispatch } from "@typescript-tea/core";
import { NumberFormat, roundTo, texts, renderValue } from "@lcc/shared";
import { fromArgb } from "abstract-image";
import { calculateLCC, CalculationInputs } from "@lcc/shared/src/calculation";
import { TextKey, TranslateFn } from "@lcc/shared/src/lang-texts";
import {
  Result,
  valuesFromResults,
  GetUnitInfoFn,
  isResultRowEmpty,
  carbonEmissionBarGraph,
  changedRealEstateValueBarGraph,
} from "@lcc/shared/src/calculation-results";
import { getPDFReports } from "@lcc/shared/src/reports";
import * as CalculationResults from "@lcc/shared/src/calculation-results";
import { paybackChart, pieChart } from "@lcc/shared/src/charts";
import { saveAs } from "file-saver";
import { PrintProps } from "@lcc/shared/src/reports/types";
import { IBlobStream } from "blob-stream";
import * as SharedState from "../../../../infrastructure/shared-state";
import { Action, DataReceived, State } from "./state";
import * as Page from "../page";
import { Button, withTw } from "../../../../components/elements";
import { getNumberFormat, getUserUnits } from "../../../../infrastructure/shared-state";
import { createGetUnitInfoFunction } from "./units";

const AhuName = withTw("div", "bottom-0 p-[inherit] relative truncate");
const ThRight = withTw("th", "text-right p-0 relative w-104 h-full");
const TdRight = withTw("td", "text-right p-0 whitespace-nowrap w-80 h-full");
const ThUnit = withTw("th", "w-80");
const HidePieChart = withTw("div", "hidden");
const ValueContainer = withTw(
  "div",
  "flex flex-row mt-2 justify-end items-center border-r-4 pr-8 border-white w-full h-full"
);
const TableFullHeight = withTw("table", "h-full");

export function View({
  state,
  pageState,
  sharedState,
}: {
  readonly state: State | undefined;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly pageState: Page.State;
}): JSX.Element {
  if (pageState.type !== "CalculationState" || state?.type !== "DataReceived") {
    return <span />;
  }

  const calcInput = pageState && pageState.calculationInput ? pageState.calculationInput : ({} as CalculationInputs);
  const ahuInputs = pageState && pageState.calculationInputAhu ? pageState.calculationInputAhu : [];

  const translate = sharedState.lang.translate;
  const userUnits = getUserUnits(sharedState);
  const getUnitInfo = createGetUnitInfoFunction(translate, userUnits, calcInput?.currency || "");

  const ahuInputsWithEnergyUsage = setSelectedUnitInput(state, calcInput, ahuInputs);

  const results = calcInput
    ? ahuInputsWithEnergyUsage?.map(
        (ahuInput): CalculationResults.Result => ({
          id: ahuInput.id,
          result: calculateLCC(generateCalculationInputs(calcInput, state), ahuInput),
        })
      )
    : undefined;

  if (!results || results.length === 0) {
    return <div />;
  }

  const showResults = results.some((r) => !!r.result.supplyFanLCC || !!r.result.extractFanLCC || !!r.result.heatingLCC);

  if (!showResults) {
    return <div />;
  }
  const numberFormat = getNumberFormat(sharedState.lang);

  const printProps: PrintProps = { results, getUnitInfo, translate, numberFormat, fonts: state.fonts };

  return (
    <div className="-mt-40 p-[1rem]">
      <div className="flex justify-end space-x-8">
        <Button
          type={"primary"}
          iconRight="fas_faPrint"
          className=""
          onClick={() => print(printProps)}
          label={translate(texts.print)}
        ></Button>
      </div>

      <Results
        translate={translate}
        getUnitInfo={getUnitInfo}
        tables={CalculationResults.projectHeader}
        results={[results[0]]}
        numberFormat={numberFormat}
        tableKey={"projectHeader"}
      />

      <Results
        translate={translate}
        getUnitInfo={getUnitInfo}
        tables={CalculationResults.mainTables}
        results={[results[0]]}
        numberFormat={numberFormat}
        tableKey={"mainTables"}
      />

      <Results
        translate={translate}
        getUnitInfo={getUnitInfo}
        tables={CalculationResults.ahuTables}
        results={results}
        numberFormat={numberFormat}
        tableKey={"ahuTables"}
      />
      <PaybackChart translate={translate} results={results} />
      <FanVsHeatingCost translate={translate} getUnitInfo={getUnitInfo} results={results} numberFormat={numberFormat} />
      <CarbonEmissionGraph translate={translate} results={results} numberFormat={numberFormat} />
      <ChangedRealEstateValue translate={translate} results={results} numberFormat={numberFormat} />
    </div>
  );
}

function PaybackChart({
  translate,
  results,
}: {
  readonly translate: TranslateFn;
  readonly results: readonly Result[];
}): JSX.Element {
  if (results.length < 2) {
    return <></>;
  }

  const result = results.find((res) => res.result.payback.profitPercent);

  const profitPercent = result?.result.payback.profitPercent;

  const profitPercentValue = profitPercent && Amount.valueAs(Units.Percent, profitPercent);

  if (profitPercentValue === undefined || profitPercentValue === 0) {
    return <></>;
  }
  const paybackData = paybackChart(results, translate);
  const svg = AbstractImage.createSVG(paybackData.chart);

  return (
    <>
      <div>
        <img style={{ width: "90%" }} src={`data:image/svg+xml;utf8,${svg}`} />
      </div>
    </>
  );
}

function FanVsHeatingCost({
  results,
  getUnitInfo,
  translate,
  numberFormat,
}: {
  readonly results: readonly Result[];
  readonly getUnitInfo: GetUnitInfoFn;
  readonly numberFormat: NumberFormat;
  readonly translate: TranslateFn;
}): JSX.Element {
  const unitInfo = getUnitInfo("kiloWattHour", 0);

  return (
    <HidePieChart>
      {results.map((result) => {
        const fanEnergy = result.result.fanTotalEnergy;
        const heatingEnergy = result.result.heatingEnergy;

        if (!fanEnergy || !heatingEnergy) {
          return [];
        }

        const totalEnergy = fanEnergy + heatingEnergy;

        const pieInput = [
          {
            label: translate(texts.energy_electric_fans),
            valueText: `${renderValue(unitInfo, fanEnergy, numberFormat)} kWh`,
            percent: roundTo((fanEnergy / totalEnergy) * 100, 1),
            color: fromArgb(0xff, 60, 100, 168),
          },
          {
            label: translate(texts.energy_additional_heat),
            valueText: `${renderValue(unitInfo, heatingEnergy, numberFormat)} kWh`,
            percent: roundTo((heatingEnergy / totalEnergy) * 100, 1),
            color: fromArgb(0xff, 150, 61, 59),
          },
        ];
        const chart = pieChart(pieInput);
        return (
          <div key={result.id} style={{ marginTop: "10px" }}>
            <span style={{ fontSize: "15px" }}>{result.result.ahuUserInput.unitName}</span>
            <img src={`data:image/svg+xml;utf8,${chart}`} />{" "}
          </div>
        );
      })}
    </HidePieChart>
  );
}

function ChangedRealEstateValue({
  results,
  translate,
  numberFormat,
}: {
  readonly results: readonly Result[];
  readonly numberFormat: NumberFormat;
  readonly translate: TranslateFn;
}): JSX.Element {
  const chart = changedRealEstateValueBarGraph(results, translate, numberFormat);
  if (!chart) {
    return <></>;
  }
  const svg = AbstractImage.createSVG(chart);
  const charts = (
    <div style={{ marginTop: "20px" }}>
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{translate(texts.changed_real_estate_value)}</div>
      <div>{translate(texts.changed_real_estate_explanation)}</div>
      <div></div>
      <img style={{ width: "90%" }} src={`data:image/svg+xml;utf8,${svg}`} />{" "}
    </div>
  );
  return <div>{charts}</div>;
}

function CarbonEmissionGraph({
  results,
  translate,
  numberFormat,
}: {
  readonly results: readonly Result[];
  readonly numberFormat: NumberFormat;
  readonly translate: TranslateFn;
}): JSX.Element {
  if (results.find((result) => result.result.userInput.carbonEmissionCountry === undefined)) {
    return <></>;
  }
  const chart = carbonEmissionBarGraph(results, translate, numberFormat);
  if (!chart) {
    return <></>;
  }
  const svg = AbstractImage.createSVG(chart);

  const charts = (
    <div style={{ marginTop: "20px" }}>
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{""}</div>
      <div></div>
      <img style={{ width: "90%" }} src={`data:image/svg+xml;utf8,${svg}`} />{" "}
    </div>
  );
  return <div>{charts}</div>;
}

function Results({
  translate,
  getUnitInfo,
  tables,
  results,
  numberFormat,
  tableKey,
}: {
  readonly translate: TranslateFn;
  readonly getUnitInfo: GetUnitInfoFn;
  readonly tables: readonly CalculationResults.Table[];
  readonly results: readonly Result[];
  readonly numberFormat: NumberFormat;
  readonly tableKey: CalculationResults.TableKeys;
}): JSX.Element {
  return (
    <TableFullHeight>
      {tables.map((table) => {
        const rows = table.rows.filter((r) => !isResultRowEmpty(r.valuePath, results));
        if (rows.length === 0 || (table.hideIf && table.hideIf(results))) {
          return undefined;
        }
        const key = table.title ? table.title.key : "main";
        return (
          <React.Fragment key={`fragment_${key}`}>
            <thead>
              {table.title !== undefined ? (
                <RenderHeader translate={translate} name={table.title} results={results} tableKey={tableKey} />
              ) : undefined}
            </thead>
            <tbody>
              {rows.map((row) => {
                return (
                  <RenderRow
                    tableKey={tableKey}
                    key={`${key}_${row.valuePath}`}
                    translate={translate}
                    getUnitInfo={getUnitInfo}
                    fieldName={row.fieldName}
                    name={row.label}
                    valuePath={row.valuePath}
                    decimals={row.decimals}
                    results={results}
                    numberFormat={numberFormat}
                  />
                );
              })}
            </tbody>
          </React.Fragment>
        );
      })}
    </TableFullHeight>
  );
}

function RenderHeader(props: {
  readonly translate: TranslateFn;
  readonly name: TextKey;
  readonly tableKey: CalculationResults.TableKeys;
  readonly results: readonly Result[];
  // readonly shouldColorBackground: boolean;
}): ReactElement {
  return (
    <tr>
      <th>
        <h3 className="text-left">{props.translate(props.name)}</h3>
      </th>
      {props.tableKey === "ahuTables" ? (
        <>
          {props.results.map((res) => {
            const className =
              res.result.ahuUserInput.selectedForCompare && props.results.length === 1
                ? ""
                : res.result.ahuUserInput.selectedForCompare && props.results.length > 1
                ? "m-10 bg-[#F0F8FF]"
                : "m-10 bg-neutral-50";

            return (
              <ThRight key={res.id}>
                {props.tableKey !== "projectHeader" ? (
                  <ValueContainer className={className}>
                    <AhuName>{res.result.ahuUserInput.unitName}</AhuName>
                  </ValueContainer>
                ) : null}
              </ThRight>
            );
          })}
          <ThUnit />
        </>
      ) : (
        <ThRight />
      )}
    </tr>
  );
}

function RenderRow(props: {
  readonly translate: TranslateFn;
  readonly getUnitInfo: GetUnitInfoFn;
  readonly fieldName: string | undefined;
  readonly name: TextKey;
  readonly valuePath: string;
  readonly decimals?: number;
  readonly results: readonly Result[];
  readonly numberFormat: NumberFormat;
  readonly tableKey: CalculationResults.TableKeys;
}): ReactElement {
  const unitInfo = props.getUnitInfo(props.fieldName, props.decimals);
  const values = valuesFromResults(unitInfo, props.valuePath, props.results, props.numberFormat);

  return (
    <tr>
      <td key="name">{props.translate(props.name)}</td>
      {props.tableKey === "ahuTables" ? (
        <>
          {values.map((value, idx) => {
            const className =
              props.tableKey !== "ahuTables"
                ? ""
                : props.results[idx].result.ahuUserInput.selectedForCompare && values.length === 1
                ? ""
                : props.results[idx].result.ahuUserInput.selectedForCompare && values.length > 1
                ? "m-10 bg-[#F0F8FF]"
                : "m-10 bg-neutral-50";

            return (
              <TdRight key={idx}>
                <ValueContainer className={className}>
                  {props.name.key === "flow_type" && value === "constant"
                    ? props.translate(texts.constant_flow)
                    : props.name.key === "flow_type" && value === "variable"
                    ? props.translate(texts.variable_flow)
                    : value}
                </ValueContainer>
              </TdRight>
            );
          })}

          <TdRight key="unit">{unitInfo.unitName}</TdRight>
        </>
      ) : props.fieldName === "lifeExpectancy" ? (
        <TdRight key="unit">{`${values[0]} ${props.translate(texts.life_expectancy_years)}`}</TdRight>
      ) : props.fieldName === "operationTime" ? (
        <TdRight key="unit">{`${values[0]} ${props.translate(texts.operation_time_hour)}`}</TdRight>
      ) : (
        <TdRight key="unit">{`${values[0]} ${unitInfo.unitName}`}</TdRight>
      )}
    </tr>
  );
}

function setSelectedUnitInput(
  state: State | undefined,
  calcInput: CalculationInputs,
  ahuInputs: readonly Page.CalculationAhuInputsWithId[]
): readonly Page.CalculationAhuInputsWithId[] | undefined {
  const compareSelected =
    calcInput && state?.type === "DataReceived"
      ? ahuInputs.filter((ahuInput) => ahuInput.selectedForCompare)
      : undefined;

  const compareSelectedResult =
    calcInput && state?.type === "DataReceived" && compareSelected?.length === 1
      ? calculateLCC(generateCalculationInputs(calcInput, state), compareSelected[0])
      : undefined;

  return calcInput && state?.type === "DataReceived" && compareSelectedResult
    ? ahuInputs.map((ahuInput) => ({
        ...ahuInput,
        selectedUnitResult: {
          points: compareSelectedResult.payback.points,
          supplyExistingKWH:
            compareSelectedResult.supplyFanEnergy === undefined
              ? undefined
              : Amount.create(compareSelectedResult.supplyFanEnergy, Units.KiloWattHour),
          extractExistingKWH:
            compareSelectedResult.extractFanEnergy === undefined
              ? undefined
              : Amount.create(compareSelectedResult.extractFanEnergy, Units.KiloWattHour),
          heatExistingKWH:
            compareSelectedResult.heatingEnergy === undefined
              ? undefined
              : Amount.create(compareSelectedResult.heatingEnergy, Units.KiloWattHour),
        },
      }))
    : ahuInputs;
}

function print(props: PrintProps): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stream: IBlobStream = new (window as any).blobStream(); // index.html will import blob-stream. Here we reference it;

  stream.on("finish", () => {
    saveAs(stream.toBlob(), `SystemairLCC.pdf`);
  });

  getPDFReports(stream, "results", props);
  ReactGA.event({
    category: "Click",
    action: "Print Calculation Click",
  });
  
}

function generateCalculationInputs(calcInput: CalculationInputs, state: DataReceived): CalculationInputs {
  return {
    ...calcInput,
    inputTables: state.productCalculateTables.product?.modules.custom_tables,
    ...(state.climateLocationData !== "n/a"
      ? { climateLocationData: state.climateLocationData.climateLocationData }
      : {}),
  };
}
