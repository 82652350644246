import { CtorsUnion, ctorsUnion } from "ctors-union";
// import { SelectableFormat } from "@promaster-sdk/react-property-selectors";
// import { UcUnitsLookup } from "@uc/uom-units-uc";
import { LanguageCode, TranslateFn } from "@lcc/shared/src/lang-texts";
import { DecimalSeparator, ThousandSeparator, NumberFormat, ActiveUser } from "@lcc/shared";
import { Market_QueryFragment } from "@lcc/shared/src/generated/generated-operations";
// import * as GraphQLSub from "../../infrastructure/effect-managers/graphql-sub";

export type Lang = {
  readonly code: LanguageCode;
  readonly translate: TranslateFn;
  readonly decimalSeparator: DecimalSeparator;
  readonly thousandSeparator: ThousandSeparator;
};

export interface SharedState {
  readonly activeUser: ActiveUser;
  readonly lang: Lang;
  readonly marketCode: string;
  readonly userSettings: { readonly [key: string]: string } | undefined;
  readonly market: Market_QueryFragment | undefined;
  readonly projectName: string | undefined;
}

// export type PropertyFormats = { readonly [propertyName: string]: SelectableFormat };

export function getNumberFormat(lang: Lang): NumberFormat {
  return { thousandSeparator: lang.thousandSeparator, decimalSeparator: lang.decimalSeparator };
}

export const SharedStateAction = ctorsUnion({
  Logout: () => ({}),
  SetShowCodes: (showCodes: boolean) => ({ showCodes }),
  SetMarket: (newMarket: string) => ({ newMarket }),
  SetFieldUnit: (field: string, unit: string, decimalCount: number) => ({
    field,
    unit,
    decimalCount,
  }),
  SetProjectName: (projectName: string) => ({ projectName }),
  // SetOpCaseDefaults: (opCaseDefaults: readonly { readonly pvs: string; readonly opCaseName: string }[]) => ({
  //   opCaseDefaults,
  // }),
  // ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  // ClearUserSettings: () => ({}),
});
export type SharedStateAction = CtorsUnion<typeof SharedStateAction>;

// TODO : Replace getUserUnits with getPropertyFormats
export function getUserUnits(sharedState: SharedState): Record<string, string> {
  const arrayData = Object.entries(sharedState.userSettings || {}).map(([name, value]) => {
    const [fu, fieldName] = name.split(":");
    const [unitName] = value.split(":");
    if (fu !== "fu" || !fieldName || !unitName) {
      return [];
    }
    return [fieldName, unitName];
  });

  return Object.fromEntries(arrayData.filter((a) => a.length === 2));
}

// export function getPropertyFormats(sharedState: SharedState): PropertyFormats {
//   const arrayData = Object.entries(sharedState.userSettings || {}).map(([name, value]) => {
//     const [fu, propertyName] = name.split(":");
//     const [unitName, decimalCountString] = value.split(":");
//     const unit = UcUnitsLookup(unitName);
//     if (fu !== "fu" || !propertyName || !unit) {
//       return [];
//     }

//     return [propertyName, { decimalCount: Number(decimalCountString) ?? 2, unit }];
//   });

//   return Object.fromEntries(arrayData.filter((a) => a.length === 2));
// }

// export function gqlSubConnConfig(state: SharedState): GraphQLSub.ConnectionConfig {
//   const wsUrl = `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${window.location.host}/graphql`;
//   const cnnCfg: GraphQLSub.ConnectionConfig = {
//     url: wsUrl,
//     connectionParams: { token: state.activeUser.accessToken },
//   };
//   return cnnCfg;
// }

// export function propertySpecificFieldName(product: string, identifier: "param" | "property", name: string): string {
//   return `${product}.${identifier}.${name}`;
// }
