import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { texts } from "@lcc/shared";
import * as State from "./state";
import * as SharedState from "../../infrastructure/shared-state";
import { ButtonLink } from "../../components/elements/button-link";
import * as GQLOps from "../../generated/generated-operations";
import { createBlobUrlCreator } from "../../infrastructure/blobs";

export function view({
  sharedState,
  meta,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
  readonly meta: GQLOps.Main_ProductMetaQuery | undefined;
}): JSX.Element {
  const logoImage = meta?.product?.modules.images.image.find((i) => i.name === "logo");
  const logoUrl =
    logoImage && logoImage.image && logoImage.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(logoImage.image, logoImage.file_name)
      : undefined;
  const translate = sharedState.lang.translate;
  return (
    <div className="w-full h-[100vh] flex">
      <div className="flex flex-col mt-44 lg:mt-104 w-3/5 ml-80 mr-[8em] space-y-32 lg:space-y-26">
        <h2 className="text-md lg:text-xl">{translate(texts.start_page_headline)}</h2>
        <p className="text-sm lg:text-md">{translate(texts.start_page_bodytext)}</p>

        <ButtonLink
          className={"w-[3/4] lg:w-[495px] h-52"}
          url={"/lcc/calculate"}
          label={translate(texts.get_started)}
        />
      </div>

      <div className="bg-[#F0F8FF] w-2/3 hidden md:flex lg:flex justify-center items-start pl-64 pr-64">
        {logoUrl ? <img src={logoUrl} className="mt-104 hidden md:flex text-center lg:text-4xl" /> : undefined}
      </div>
    </div>
  );
}
