import { Unit } from "uom";
import { unitLookup, Units, UnitsFormat } from "uom-units";
import { AnyQuantity, getUnitFormat } from "@lcc/shared/src/uom";
import { GetUnitInfoFn } from "@lcc/shared/src/calculation-results";
import { texts, TranslateFn } from "@lcc/shared";

interface FieldNameUnit {
  readonly fieldName: string;
  readonly defaultUnit: Unit.Unit<AnyQuantity> | string;
  readonly decimals?: number;
  readonly hideIfZero?: boolean;
}

const defaultFieldNames: readonly FieldNameUnit[] = [
  {
    fieldName: "percent",
    defaultUnit: Units.Percent,
  },
  {
    fieldName: "lifeExpectancy",
    defaultUnit: Units.Year,
  },
  {
    fieldName: "paybackTime",
    defaultUnit: Units.Year,
  },
  {
    fieldName: "operationTime",
    defaultUnit: Units.Hour,
  },
  {
    fieldName: "VolumeFlow",
    defaultUnit: Units.CubicMeterPerSecond,
  },
  {
    fieldName: "pressure",
    defaultUnit: Units.Pascal,
  },
  {
    fieldName: "SpecificFanPower",
    defaultUnit: Units.KiloWattPerCubicMeterPerSecond,
  },
  {
    fieldName: "KiloWattHour",
    defaultUnit: Units.KiloWattHour,
  },
  {
    fieldName: "Temperature",
    defaultUnit: Units.Celsius,
  },
  {
    fieldName: "energy_price",
    defaultUnit: "",
    decimals: 2,
  },
  {
    fieldName: "price_year",
    defaultUnit: "",
    decimals: 2,
  },
  {
    fieldName: "currency",
    defaultUnit: "",
    decimals: 2,
  },
  {
    fieldName: "degreeHours",
    defaultUnit: "°Ch",
    decimals: 2,
    hideIfZero: true,
  },
];

export function createGetUnitInfoFunction(
  translate: TranslateFn,
  userUnits: Record<string, string>,
  currency: string
): GetUnitInfoFn {
  return (fieldName: string | undefined, decimals: number | undefined) => {
    const fieldNameUnit = defaultFieldNames.find((f) => f.fieldName === fieldName) || {
      fieldName: "",
      defaultUnit: "",
      decimals: 1,
      hideIfZero: false,
    };

    let unitName = "";
    let unit: Unit.Unit<AnyQuantity> = Unit.One;
    if (fieldNameUnit.fieldName === "currency") {
      unitName = currency;
    } else if (fieldNameUnit.fieldName === "energy_price") {
      unitName = `${currency}/kWh`;
    } else if (fieldNameUnit.fieldName === "price_year") {
      unitName = `${currency}/${translate(texts.year)}`;
    } else if (typeof fieldNameUnit.defaultUnit === "string") {
      unitName = fieldNameUnit.defaultUnit;
    } else {
      const userUnit = userUnits[fieldName ? fieldName : fieldNameUnit.defaultUnit.quantity];
      unit = userUnit ? (unitLookup(userUnit) as Unit.Unit<AnyQuantity>) : fieldNameUnit.defaultUnit;
      unitName = getUnitFormat(unit, UnitsFormat)?.label ?? unit.name;
    }

    return {
      unit: unit,
      unitName: unitName,
      decimals: decimals !== undefined ? decimals : fieldNameUnit.decimals || 1,
      hideIfZero: fieldNameUnit.hideIfZero || false,
    };
  };
}
