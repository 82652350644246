export function interpolate2Axis(
  xVal: number,
  yVal: number,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  xMinYMinValue: number,
  xMinYMaxValue: number,
  xMaxYMinValue: number,
  xMaxYMaxValue: number
): number {
  //Interpolate temperatures to find min and max temperatures
  const xInterpMin = xMin === xMax ? xMinYMinValue : interpolate(xVal, xMin, xMax, xMinYMinValue, xMaxYMinValue);
  const xInterpMax = xMin === xMax ? xMinYMaxValue : interpolate(xVal, xMin, xMax, xMinYMaxValue, xMaxYMaxValue);

  //Interpolate between interpolated temperatures to get final value
  return yMin === yMax ? xInterpMin : interpolate(yVal, yMin, yMax, xInterpMin, xInterpMax);
}

function interpolate(pos: number, min: number, max: number, minVal: number, maxVal: number): number {
  return ((pos - min) * (maxVal - minVal)) / (max - min) + minVal;
}
