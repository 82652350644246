import { TextKey, texts } from "../lang-texts";
import { CalculationOutputs } from "../calculation";

export interface Result {
  readonly id: string;
  readonly result: CalculationOutputs;
}

export interface TableRow {
  readonly label: TextKey;
  readonly valuePath: string;
  readonly fieldName: string | undefined;
  readonly decimals?: number;
}

export interface Table {
  readonly title: TextKey;
  readonly hideIf?: (results: readonly Result[]) => boolean;
  readonly rows: readonly TableRow[];
}

export type TableKeys = "projectHeader" | "mainTables" | "ahuTables" | "diagramTables";

export const projectHeader: readonly Table[] = [
  {
    title: texts.project_sign,
    rows: [row(texts.project_name, "result.userInput.projectName", "")],
  },
];

export const mainTables: readonly Table[] = [
  {
    title: texts.economic_factors,
    rows: [
      row(texts.currency, "result.userInput.currency", ""),
      row(texts.annual_price_increase_electricity, "result.userInput.expectedIncreaseEnergyPrice", "percent"),
      row(texts.annual_price_increase_heating, "result.userInput.expectedIncreaseHeatingPrice", "percent"),
      row(texts.real_calculated_interest, "result.userInput.realCalculatedInterest", "percent"),
      row(texts.life_expectancy, "result.userInput.lifeExpectancy", "lifeExpectancy"),
      row(texts.specific_net_present_value_electricity, "result.presentValueEnergy", "energy_price"),
      row(texts.specific_net_present_value_heating, "result.presentValueHeating", "energy_price"),
    ],
  },
  {
    title: texts.conditions,
    rows: [
      row(texts.country_selection, "result.userInput.countrySelection", "countrySelection"),
      row(texts.location_selection, "result.userInput.locationSelection", "locationSelection"),
      row(texts.annual_average_temperature, "result.userInput.annualAverageTemperature", "Temperature"),
      row(texts.current_energy_price_electricity, "result.userInput.currentEnergyPrice", "energy_price"),
      row(texts.current_energy_price_heating, "result.userInput.currentHeatingPrice", "energy_price"),
      row(texts.annual_operation_time, "result.userInput.annualOperationTime", "operationTime"),
    ],
  },
];

export const ahuTables: readonly Table[] = [
  {
    title: texts.ahu_data,
    rows: [
      row(texts.flow_type, "result.ahuUserInput.fanFlowType", ""),
      row(texts.correction_variable_flow, "result.ahuUserInput.correctionVariableFlow", "percent"),
    ],
  },

  {
    title: texts.supply_fan_data,
    rows: [
      row(texts.nominal_flow, "result.ahuUserInput.nominalFlowSupply", "VolumeFlow"),
      row(texts.specific_fan_power_e, "result.sfpePerFanSupply", "SpecificFanPower"),
      row(texts.specific_fan_power_v, "result.sfpvPerFanSupply", "SpecificFanPower"),
      row(texts.sfp_calculation_type("sfpeEntireUnit"), "result.sfpeEntireUnitSupply", "SpecificFanPower"),
      row(texts.sfp_calculation_type("sfpvEntireUnit"), "result.sfpvEntireUnitSupply", "SpecificFanPower"),
      // row(texts.pressure_drop_connected_ducts, "result.ahuUserInput.pressureDropSupply", "pressure"),
      row(texts.temperature_increase_supply_fan, "result.temperatureIncreaseSupplyFan", "Temperature"),
    ],
  },

  {
    title: texts.extract_fan_data,
    rows: [
      row(texts.nominal_flow, "result.ahuUserInput.nominalFlowExtract", "VolumeFlow"),
      row(texts.specific_fan_power_e, "result.sfpePerFanExtract", "SpecificFanPower"),
      row(texts.specific_fan_power_v, "result.sfpvPerFanExtract", "SpecificFanPower"),
      row(texts.sfp_calculation_type("sfpeEntireUnit"), "result.sfpeEntireUnitExtract", "SpecificFanPower"),
      row(texts.sfp_calculation_type("sfpvEntireUnit"), "result.sfpvEntireUnitExtract", "SpecificFanPower"),
      // row(texts.pressure_drop_connected_ducts, "result.ahuUserInput.pressureDropExtract", "pressure"),
    ],
  },

  {
    title: texts.heating_recovery_additional_heat,
    hideIf: (results) => {
      const filtered = results.filter(
        (result) =>
          result.result.ahuUserInput.heatingType !== "none" && result.result.ahuUserInput.heatingType !== undefined
      );
      return filtered.length === 0;
    },
    rows: [
      row(texts.supply_air_temp, "result.userInput.supplyAirTemperature", "Temperature"),
      row(texts.extract_air_temp, "result.userInput.extractAirTemperature", "Temperature"),
      row(texts.temperature_efficiency_dry, "result.ahuUserInput.temperatureEfficiency", "percent"),
      row(texts.heating_degree, "result.degreeHours", "degreeHours", 0),
      // row(texts.freezing_correction, "result.heatingDegreeCorrection", "degreeHours", 0),
      row(texts.corrected_heating_degree, "result.correctedDegreeHours", "degreeHours", 0),
      row(texts.recovered_thermal_energy, "result.recoveredThermalEnergy", "KiloWattHour", 0),
    ],
  },
  {
    title: texts.annual_energy_usage,
    rows: [
      row(texts.supply_fan, "result.supplyFanEnergy", "KiloWattHour", 0),
      row(texts.extract_fan, "result.extractFanEnergy", "KiloWattHour", 0),
      row(texts.fan_total, "result.fanTotalEnergy", "KiloWattHour", 0),
      row(texts.heating, "result.heatingEnergy", "KiloWattHour", 0),
      row(texts.total, "result.energyTotal", "KiloWattHour", 0),
    ],
  },
  {
    title: texts.changed_real_estate_value,
    hideIf: (results) => results.length === 1,
    rows: [
      row(texts.current_real_estate_value, "result.userInput.currentRealEstateValue", "currency", 0),
      row(texts.energy_fan_saving, "result.energyFanSaving", "currency", 0),
      row(texts.energy_heat_saving, "result.energyHeatSaving", "currency", 0),
      row(texts.energy_cost_saving, "result.energyCostSaving", "currency", 0),
      row(texts.expected_return, "result.userInput.expectedReturn", "percent"),
      row(texts.calculated_increased_value, "result.calculatedIncreasedValue", "currency", 0),
    ],
  },
  {
    title: texts.lcc_energy_net_present_value,
    rows: [
      row(texts.offer_amount, "result.ahuUserInput.offerAmount", "currency"),
      row(texts.supply_fan, "result.supplyFanLCC", "currency", 0),
      row(texts.extract_fan, "result.extractFanLCC", "currency", 0),
      row(texts.fan_total, "result.fanLCCTotal", "currency", 0),
      row(texts.heating, "result.heatingLCC", "currency", 0),
      row(texts.total, "result.LCCTotal", "currency", 0),
    ],
  },
  {
    title: texts.payback_diagram,
    rows: [
      row(texts.payback_time_until_even, "result.payback.timeUntilEven", "paybackTime", 1),
      row(texts.profit_from_investment, "result.payback.profitPercent", "percent", 1),
    ],
  },
];

function row(label: TextKey, valuePath: string, fieldName: string, decimals?: number): TableRow {
  return {
    label,
    valuePath,
    fieldName,
    decimals,
  };
}
