import { AbstractDocExporters } from "abstract-document";
import { runReportAsync } from "./report-runner-shared";
import { PrintProps, ReportType } from "./types";

export async function getPDFReports(stream: unknown, reportName: ReportType, props: PrintProps): Promise<void> {
  const abstractDoc = await runReportAsync(reportName, props);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pdfKit = (window as any).PDFDocument; // index.html will import pdfkit. Here we reference it
  return AbstractDocExporters.Pdf.exportToStream(pdfKit, stream as {}, abstractDoc);
}
