import React from "react";
import { Paragraph, Group, Image, TextRun } from "abstract-document/lib/abstract-document-jsx";
import { AbstractDoc as AD } from "abstract-document";
import { paybackChart } from "../../charts";
import { PrintProps } from "../types";
import { carbonEmissionBarGraph, changedRealEstateValueBarGraph } from "../../calculation-results";
import { texts } from "../../lang-texts";

export function renderPaybackChart({ translate, results }: PrintProps): JSX.Element {
  if (results.length < 2) {
    return <Group></Group>;
  }
  const paybackData = paybackChart(results, translate);
  const imageResource = AD.ImageResource.create({
    abstractImage: paybackData.chart,
    id: "paybackChart",
  });
  return (
    <Group>
      <Paragraph>
        <Image imageResource={imageResource} width={350} height={250} />
      </Paragraph>
    </Group>
  );
}

export function carbonEmissionGraph({ translate, results, numberFormat }: PrintProps): JSX.Element {
  if (results.find((res) => res.result.userInput.carbonEmissionCountry === undefined)) {
    return <Group></Group>;
  }

  const barGraph = carbonEmissionBarGraph(results, translate, numberFormat);
  if (!barGraph) {
    return <Group></Group>;
  }
  const imageResource = AD.ImageResource.create({
    abstractImage: barGraph,
    id: "paybackChart",
  });
  return (
    <Group>
      <Paragraph>
        <Image imageResource={imageResource} width={350} height={250} />
      </Paragraph>
    </Group>
  );
}

export function changedRealEstateValue({ translate, results, numberFormat }: PrintProps): JSX.Element {
  if (results.find((res) => res.result.userInput.carbonEmissionCountry === undefined)) {
    return <Group></Group>;
  }

  const barGraph = changedRealEstateValueBarGraph(results, translate, numberFormat);
  if (!barGraph) {
    return <Group></Group>;
  }
  const imageResource = AD.ImageResource.create({
    abstractImage: barGraph,
    id: "changedRealEstateValue",
  });
  return (
    <Group keepTogether={true}>
      <Paragraph styleName="H1">
        <TextRun text={translate(texts.changed_real_estate_value)} />
      </Paragraph>
      <Paragraph>
        <TextRun text={translate(texts.changed_real_estate_explanation)} />
      </Paragraph>
      <Paragraph>
        <Image imageResource={imageResource} width={350} height={250} />
      </Paragraph>
    </Group>
  );
}
