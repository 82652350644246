import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import { amountAs } from "../../uom";
import { AnnualOperationTimeInput, FanFlowType } from "../types";

export function calculateAnnualOperationTime(
  annualOperationTimeInput: AnnualOperationTimeInput | undefined,
  annualOperationTime: Amount.Amount<Quantity.Duration> | undefined,
  annualOperationTimeWorkdays: Amount.Amount<Quantity.Duration> | undefined,
  annualOperationTimeWeekends: Amount.Amount<Quantity.Duration> | undefined
): Amount.Amount<Quantity.Duration> | undefined {
  if (annualOperationTimeInput === "total" || !annualOperationTimeInput) {
    return annualOperationTime;
  } else if (annualOperationTimeInput === "detailed" && annualOperationTimeWorkdays && annualOperationTimeWeekends) {
    const numWeeks = 365.25 / 7;
    const workdayHours = Amount.valueAs(Units.Hour, annualOperationTimeWorkdays);
    const weekendHours = Amount.valueAs(Units.Hour, annualOperationTimeWeekends);
    const totalOperationTime = workdayHours * numWeeks + weekendHours * numWeeks;
    return Amount.create(totalOperationTime, Units.Hour);
  } else {
    return undefined;
  }
}

export function calculateFlow(
  nominalFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  specificFanPower: Amount.Amount<Quantity.SpecificFanPower> | undefined,
  annualOperationTime: Amount.Amount<Quantity.Duration> | undefined,
  fanFlowType: FanFlowType | undefined,
  correctionVariableFlow: Amount.Amount<Quantity.Dimensionless> | undefined
): number | undefined {
  if (!nominalFlow || !specificFanPower || !annualOperationTime || !fanFlowType) {
    return undefined;
  }
  const constantFlow =
    amountAs(Units.CubicMeterPerSecond, nominalFlow) *
    amountAs(Units.KiloWattPerCubicMeterPerSecond, specificFanPower) *
    amountAs(Units.Hour, annualOperationTime);

  const correctedFlow =
    fanFlowType === "variable"
      ? correctionVariableFlow && constantFlow * amountAs(Units.One, correctionVariableFlow)
      : constantFlow;
  return correctedFlow;
}
