import React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as Main from "../main/index";
import { Button } from "../components/elements";
import { Spinner } from "../components/elements/spinner";

export function view({
  state,
  dispatch,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
}): JSX.Element {
  if (state.type === "LoginErrorState") {
    return <div>LoginErrorState</div>;
  }
  if (
    state.type === "WaitingForUserMarketState" ||
    state.type === "WaitingForUserSessionState" ||
    state.type === "WaitingForUserSettingsState" ||
    state.type === "WaitingForUserLanguageTextsState"
  ) {
    return <Spinner debounce={false} />;
  }
  if (state.type === "LoggedOutState") {
    return (
      <div className="absolute mt-[33.33%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12">
        <div className="font-bold text-lg">You've been logged out</div>
        <Button
          label="Sign in"
          onClick={() => {
            window.location.assign(window.location.origin);
          }}
        />
      </div>
    );
  }
  const currentLocation = state.urlMatch?.location;
  if (currentLocation === undefined) {
    return <div>Current location is undefined</div>;
  }
  switch (currentLocation?.type) {
    case "LoginCallback": {
      return <div>Callback...</div>;
    }
    case "LoggedOut": {
      return <div>LoggedOut</div>;
    }
    default: {
      return (
        <div className="w-full h-full">
          <Main.view
            dispatch={Dispatch.map(State.Action.DispatchMain, dispatch)}
            state={state.mainState!}
            sharedState={State.buildSharedState(state)}
          />
        </div>
      );
    }
  }
}
