import { Amount } from "uom";
import { Quantity } from "uom-units";
import * as GQLOps from "../generated/generated-operations";
import { ClimateLocationResponse } from "../climate-data-api";

export type InputTables = {
  readonly CorrectedHeatingDegree: readonly GQLOps.CorrectedHeatingDegree_QueryFragment[];
  readonly HeatingDegreeHoursAdditionalHeat: readonly GQLOps.HeatingDegreeHoursAdditionalHeat_QueryFragment[];
  readonly CarbonEmissionPerCountry: readonly GQLOps.CarbonEmission_QueryFragment[];
};

export type InputTableType =
  | GQLOps.CorrectedHeatingDegree_QueryFragment
  | GQLOps.HeatingDegreeHoursAdditionalHeat_QueryFragment;

export type AnnualOperationTimeInput = "total" | "detailed";
export type AnnualAverageTemperatureInput = "manual" | "selection";

export type CalculationInputs = {
  readonly inputTables?: InputTables;
  readonly climateLocationData?: ClimateLocationResponse;
  readonly currentEnergyPrice?: string;
  readonly currentHeatingPrice?: string;
  readonly expectedReturn?: Amount.Amount<Quantity.Dimensionless>;
  readonly currentRealEstateValue?: string;
  readonly currency?: string;
  readonly expectedIncreaseEnergyPrice?: Amount.Amount<Quantity.Dimensionless>;
  readonly expectedIncreaseHeatingPrice?: Amount.Amount<Quantity.Dimensionless>;
  readonly realCalculatedInterest?: Amount.Amount<Quantity.Dimensionless>;
  readonly lifeExpectancy?: Amount.Amount<Quantity.Duration>;
  readonly annualOperationTimeInput?: AnnualOperationTimeInput;
  readonly annualOperationTime?: Amount.Amount<Quantity.Duration>;
  readonly annualOperationTimeWorkdays?: Amount.Amount<Quantity.Duration>;
  readonly annualOperationTimeWeekends?: Amount.Amount<Quantity.Duration>;
  readonly annualAverageTemperatureInput?: AnnualAverageTemperatureInput;
  readonly annualAverageTemperatureCountry?: string;
  readonly annualAverageTemperatureLocation?: string;
  readonly annualAverageTemperature?: Amount.Amount<Quantity.Temperature>;
  readonly carbonEmissionCountry?: string;
  readonly supplyAirTemperature?: Amount.Amount<Quantity.Temperature>;
  readonly exhaustAirTemperature?: Amount.Amount<Quantity.Temperature>;
  readonly projectName?: string;
  readonly fanCalculationType?: FanCalculationType | undefined;
};

export type FanFlowType = "constant" | "variable";
export type HeatingType = "none" | "plateStandardBypass" | "plateSectionDefrost" | "battery" | "wheel";
export type FanCalculationType =
  | "sfpTotal"
  | "sfpPerFan"
  | "sfpeEntireUnit"
  | "sfpePerFan"
  | "sfpvEntireUnit"
  | "sfpvPerFan"
  | "powerPerFan";

export type CalculationAhuInputs = {
  readonly unitName?: string;
  readonly offerAmount?: string;
  readonly selectedForCompare?: boolean;
  readonly correctionVariableFlow?: Amount.Amount<Quantity.Dimensionless>;
  readonly correctionVariableFlowTemp?: Amount.Amount<Quantity.Dimensionless>;
  readonly specificFanPowerTotal?: Amount.Amount<Quantity.SpecificFanPower>;
  readonly specificFanPowerSupply?: Amount.Amount<Quantity.SpecificFanPower>;
  readonly specificFanPowerExtract?: Amount.Amount<Quantity.SpecificFanPower>;
  readonly fanPowerSupply?: Amount.Amount<Quantity.Power>;
  readonly fanPowerExtract?: Amount.Amount<Quantity.Power>;
  readonly nominalFlowSupply?: Amount.Amount<Quantity.VolumeFlow>;
  readonly nominalFlowExtract?: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressureDropSupply?: Amount.Amount<Quantity.Pressure>;
  readonly pressureDropExtract?: Amount.Amount<Quantity.Pressure>;
  readonly temperatureEfficiency?: Amount.Amount<Quantity.Dimensionless>;
  readonly fanFlowType?: FanFlowType;
  readonly heatingType?: HeatingType;
  readonly tMinOutletExhaustExchanger?: Amount.Amount<Quantity.Temperature>;
  readonly defrostFunctionIncluded?: boolean;
  readonly selectedUnitResult?: SelectedUnitResult;
};

export type SelectedUnitResult = {
  readonly points?: readonly Point[];
  readonly supplyExistingKWH?: Amount.Amount<Quantity.Energy>;
  readonly extractExistingKWH?: Amount.Amount<Quantity.Energy>;
  readonly heatExistingKWH?: Amount.Amount<Quantity.Energy>;
};

export type CalculationOutputs = {
  readonly userInput: UserInput;
  readonly ahuUserInput: AhuUserInput;
  readonly payback: PaybackData;
  readonly presentValueEnergy: number | undefined;
  readonly presentValueHeating: number | undefined;
  readonly supplyFanEnergy: number | undefined;
  readonly extractFanEnergy: number | undefined;
  readonly fanTotalEnergy: number | undefined;
  readonly heatingEnergy: number | undefined;
  readonly energyTotal: number | undefined;
  readonly energyFanSaving: number | undefined;
  readonly energyHeatSaving: number | undefined;
  readonly energyCostSaving: number | undefined;
  readonly calculatedIncreasedValue: number | undefined;
  readonly specificFanPowerSupply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly specificFanPowerExtract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly supplyFanLCC: number | undefined;
  readonly extractFanLCC: number | undefined;
  readonly fanLCCTotal: number | undefined;
  readonly heatingLCC: number | undefined;
  readonly LCCTotal: number | undefined;
  readonly temperatureIncreaseSupplyFan: Amount.Amount<Quantity.Temperature> | undefined;
  readonly degreeHours?: number;
  readonly heatingDegreeCorrection?: number;
  readonly correctedDegreeHours?: number;
  readonly recoveredThermalEnergy: number;

  readonly sfpePerFanSupply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpePerFanExtract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpvPerFanSupply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpvPerFanExtract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpeEntireUnitSupply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpeEntireUnitExtract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpvEntireUnitSupply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly sfpvEntireUnitExtract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
};

export type Point = { readonly x: number; readonly y: number };

export type PaybackData = {
  readonly points: readonly Point[];
  readonly timeUntilEven?: Amount.Amount<Quantity.Duration>;
  readonly timeUntilEvenPos?: Point;
  readonly profitPercent?: Amount.Amount<Quantity.Dimensionless>;
};

export type UserInput = {
  readonly expectedReturn?: Amount.Amount<Quantity.Dimensionless>;
  readonly currentRealEstateValue?: string;
  readonly currentEnergyPrice: string | undefined;
  readonly currentHeatingPrice: string | undefined;
  readonly currency: string | undefined;
  readonly expectedIncreaseEnergyPrice: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly expectedIncreaseHeatingPrice: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly realCalculatedInterest: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly lifeExpectancy: Amount.Amount<Quantity.Duration> | undefined;
  readonly annualOperationTime: Amount.Amount<Quantity.Duration> | undefined;
  readonly annualAverageTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly carbonEmissionCountry: string | undefined;
  readonly carbonEmissionElectricityFactor: number | undefined;
  readonly carbonEmissionHeatingFactor: number | undefined;
  readonly supplyAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly exhaustAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly projectName: string;
  readonly locationSelection: string | undefined;
  readonly countrySelection: string | undefined;
};

export type AhuUserInput = {
  readonly unitName: string | undefined;
  readonly offerAmount: string | undefined;
  readonly selectedForCompare?: boolean;
  readonly correctionVariableFlow: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly correctionVariableFlowTemp: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly specificFanPowerSupply: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly specificFanPowerExtract: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly nominalFlowSupply: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly nominalFlowExtract: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly pressureDropSupply: Amount.Amount<Quantity.Pressure> | undefined;
  readonly pressureDropExtract: Amount.Amount<Quantity.Pressure> | undefined;
  readonly temperatureEfficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly fanFlowType: FanFlowType | undefined;
  readonly heatingType: HeatingType | undefined;
  readonly tMinOutletExhaustExchanger: Amount.Amount<Quantity.Temperature> | undefined;
};

export type HeatAndFlowOutput = {
  readonly degreeHours?: number;
  readonly correctedDegreeHours?: number;
  readonly heatingDegreeCorrection?: number;
  readonly heatingEnergy?: number;
};

export type InterpResult = number | undefined;
