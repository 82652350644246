import { LanguageCode, TranslateFn, texts } from "@lcc/shared/src/lang-texts";
import { Dispatch } from "@typescript-tea/core";
import ReactGA from "react-ga4";
import React from "react";
import { Button, Icon, Input, Select, withTw } from "../components/elements";
import { SharedState } from "../infrastructure/shared-state";
import * as GQLOps from "../generated/generated-operations";
import * as State from "./state";
import * as PageState from "../pages/lcc";
import { createBlobUrlCreator } from "../infrastructure/blobs";
import { PageAction, StateCalculation } from "../pages/lcc/parts/page";
import { Spinner } from "../components/elements/spinner";

const Header = withTw(
  "div",
  "w-full flex flex-col lg:flex-row lg:space-x-40 justify-between border-b p-8 text-xs z-10 relative bg-white lg:h-[3rem] "
);

const HeaderButtons = withTw("div", "flex flex-row space-x-4 items-center mr-[30px] mt-4 lg:mt-0");

const ScreenAdjustmentLarge = withTw("div", "hidden lg:inline");

const ScreenAdjustmentSmall = withTw("div", "inline lg:hidden");

const LogOutButton = withTw(
  "a",
  "h-32 ml-[8px] flex flex-row hover:bg-[#F0F8FF] active:bg-primary group items-center justify-center"
);

const ShareContainer = withTw(
  "div",
  "absolute p-6 shadow items-center bg-white rounded-8 space-y-14 p-[1em] top-full w-[30em] left-[-70%] mt-[1.2em] font-bold"
);

export function View({
  dispatch,
  pageDispatch,
  sharedState,
  meta,
  hideClearButton,
  state,
  pageState,
}: {
  readonly state: State.State | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly pageDispatch: Dispatch<PageState.Action>;
  readonly sharedState: SharedState;
  readonly meta: GQLOps.Main_ProductMetaQuery | undefined;
  readonly hideClearButton: boolean;
  readonly pageState: StateCalculation | undefined;
}): JSX.Element {
  const translate = sharedState.lang.translate;
  const currentMarket = sharedState.marketCode;

  const logoImage = meta?.product?.modules.images.image.find((i) => i.name === "systemair-logo");
  const availableMarkets = state?.marketTable?.product?.modules.custom_tables.Market;

  const logoUrl =
    logoImage && logoImage.image && logoImage.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(logoImage.image, logoImage.file_name)
      : undefined;

  return (
    <Header>
      <div className="flex flex-row">
        <a href={"/start"}>{logoUrl ? <img src={logoUrl} className="max-h-24 h-full min-w-104" /> : undefined}</a>
        <ScreenAdjustmentSmall>
          <Select
            className="min-w-80 ml-12 w-fit lg:ml-0"
            name="languages"
            value={currentMarket}
            onChange={(v) => dispatch(State.Action.ChangeMarket(v.currentTarget.value as LanguageCode))}
          >
            {availableMarkets?.map((m) => {
              return (
                <option key={m.market} value={m.market || ""}>
                  {m.market}
                </option>
              );
            })}
          </Select>
        </ScreenAdjustmentSmall>

        {!hideClearButton ? (
          <ScreenAdjustmentSmall>
            <Button
              className="hover:bg-[#E6F3F6] active:bg-[#B2D8E3] ml-20 lg:ml-0"
              type={"secondary"}
              iconRight="fal_faEraser"
              onClick={() => pageDispatch(PageState.Action.PageAction(PageAction.ClearCalculationInput()))}
              label={translate(texts.clear_inputs)}
            ></Button>
          </ScreenAdjustmentSmall>
        ) : (
          <LogOutButton href={"/start"}>
            <Icon
              icon={"fat_faArrowRightFromBracket"}
              onClick={() => dispatch(State.Action.Logout())}
              svgClassName={"group-active:text-white"}
              className={"p-8 pt-4 items-center justify-center lg:hidden"}
            />
          </LogOutButton>
        )}
      </div>
      <HeaderButtons>
        <ScreenAdjustmentLarge>
          <Select
            className="min-w-80 ml-12 lg:ml-0 w-fit"
            name="languages"
            value={currentMarket}
            onChange={(v) => dispatch(State.Action.ChangeMarket(v.currentTarget.value as LanguageCode))}
          >
            {availableMarkets?.map((m) => {
              return (
                <option key={m.market} value={m.market || ""}>
                  {m.market}
                </option>
              );
            })}
          </Select>
        </ScreenAdjustmentLarge>
        {!hideClearButton ? (
          <>
            <ScreenAdjustmentLarge>
              <Button
                className="hover:bg-[#E6F3F6] active:bg-[#B2D8E3] lg:ml-0"
                type={"secondary"}
                iconRight="fal_faEraser"
                onClick={() => pageDispatch(PageState.Action.PageAction(PageAction.ClearCalculationInput()))}
                label={translate(texts.clear_inputs)}
              ></Button>
            </ScreenAdjustmentLarge>
            <div className="relative flex flex-wrap">
              <Button
                disabled={
                  !pageState ||
                  !PageState.isValidSection(pageState, "project_info") ||
                  !PageState.isValidSection(pageState, "economic_factors") ||
                  !PageState.isValidSection(pageState, "conditions") ||
                  !PageState.isValidSection(pageState, "ahu_inputs") ||
                  !PageState.isValidSection(pageState, "result")
                }
                className="hover:bg-[#E6F3F6] active:bg-[#B2D8E3]"
                type={"secondary"}
                onClick={() => dispatch(State.Action.SetExpandedShareInput(!state?.expandedShareInput))}
                label={translate(texts.share_calculation_button)}
              ></Button>

              {state?.expandedShareInput && <ShareEmailInput dispatch={dispatch} translate={translate} state={state} />}
            </div>
          </>
        ) : null}

        {!hideClearButton ? (
          <>
            <CopyCalculationLink translate={translate} pageState={pageState} />{" "}
            <LogOutButton href={"/start"} className="inline lg:hidden">
              <Icon
                icon={"fat_faArrowRightFromBracket"}
                onClick={() => dispatch(State.Action.Logout())}
                svgClassName={"group-active:text-white"}
                className={"p-8 items-center justify-center"}
              />
            </LogOutButton>
          </>
        ) : (
          <LogOutButton href={"/start"} className="hidden">
            <Icon
              icon={"fat_faArrowRightFromBracket"}
              onClick={() => dispatch(State.Action.Logout())}
              svgClassName={"group-active:text-white"}
              className={"p-8 items-center justify-center"}
            />
          </LogOutButton>
        )}
        <LogOutButton href={"/start"} className="hidden lg:inline">
          <Icon
            icon={"fat_faArrowRightFromBracket"}
            onClick={() => dispatch(State.Action.Logout())}
            svgClassName={"group-active:text-white"}
            className={"p-8 items-center justify-center"}
          />
        </LogOutButton>
      </HeaderButtons>
    </Header>
  );
}

const emailValidator = new RegExp(".+@.+[.].+");

function isValidEmail(email: string): boolean {
  return emailValidator.test(email);
}

function CopyCalculationLink({
  translate,
  pageState,
}: {
  readonly translate: TranslateFn;
  readonly pageState: StateCalculation | undefined;
}): JSX.Element {
  const [showCopiedLabel, setShowCopiedLabel] = React.useState(false);

  return (
    <section>
      <Button
        disabled={
          !pageState ||
          !PageState.isValidSection(pageState, "project_info") ||
          !PageState.isValidSection(pageState, "economic_factors") ||
          !PageState.isValidSection(pageState, "conditions") ||
          !PageState.isValidSection(pageState, "ahu_inputs") ||
          !PageState.isValidSection(pageState, "result")
        }
        className="hover:bg-[#E6F3F6] active:bg-[#B2D8E3]"
        type="secondary"
        onClick={() => {
          setShowCopiedLabel(true);
          ReactGA.event({
            category: "Click",
            action: "Copy Link to Calculation",
          });
          setTimeout(() => setShowCopiedLabel(false), 4000);
          navigator.clipboard.writeText(window.location.href);
        }}
        label={showCopiedLabel ? translate(texts.link_copied) : translate(texts.copy_calculation_link_button)}
      />
    </section>
  );
}

function ShareEmailInput({
  state,
  dispatch,
  translate,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly translate: TranslateFn;
}): JSX.Element {
  const [shareEmail, setShareEmail] = React.useState<string>("");

  if (state.shareProjectState === "error") {
    return <ShareContainer>{translate(texts.email_error)}</ShareContainer>;
  } else if (state.shareProjectState === "finished") {
    return <ShareContainer>{translate(texts.email_sent)}</ShareContainer>;
  }

  return (
    <ShareContainer>
      <>
        <div className="flex flex-row justify-between mt-20">
          <div> {translate(texts.share_info)}</div>
          <button
            onClick={() => {
              dispatch(State.Action.SetExpandedShareInput(!state?.expandedShareInput));
            }}
          >
            <Icon
              size={"xl"}
              icon={"fat_faCircleXmark"}
              className="mt-[-5px] cursor-pointer opacity-[0.3] hover:opacity-[0.6]"
            ></Icon>
          </button>
        </div>
        <Input
          className="font-normal"
          placeholder={"email"}
          autoFocus={true}
          type="text"
          value={shareEmail}
          onChange={(v) => setShareEmail(v.currentTarget.value)}
          onKeyDown={(e) => {
            if (isValidEmail(shareEmail) && e.key === "Enter") {
              dispatch(State.Action.ShareProject(shareEmail));
            }
          }}
        />

        {state.shareProjectState === "waiting" ? (
          <Spinner />
        ) : (
          <div title={!isValidEmail(shareEmail) ? translate(texts.invalid_email) : ""}>
            <Button
              className="float-right rounded-full"
              disabled={!isValidEmail(shareEmail)}
              type={"secondary"}
              onClick={() => {
                dispatch(State.Action.ShareProject(shareEmail));
              }}
              label={"share"}
            ></Button>
          </div>
        )}
      </>
    </ShareContainer>
  );
}
