import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Amount, Unit } from "uom";
import { Quantity, Units } from "uom-units";
import { AnyQuantity } from "@lcc/shared/src/uom";
import { texts } from "@lcc/shared";
import * as SharedState from "../../../../../infrastructure/shared-state";
import { Action, DataReceived } from "../state";
import * as Page from "../../page";
import { getNumberFormat, getUserUnits } from "../../../../../infrastructure/shared-state";
import { InputAmountRow, InputNumberRow, InputSelectRow, SubHeading } from "./shared";

export function EconomicFactors({
  sharedState,
  dispatch,
  pageState,
  state,
}: {
  readonly state: DataReceived;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly pageState: Page.StateCalculation;
}): JSX.Element {
  const translate = sharedState.lang.translate;
  const userUnits = getUserUnits(sharedState);

  const { calculationInput } = pageState;
  const numberFormat = getNumberFormat(sharedState.lang);
  return (
    <div>
      <SubHeading>{translate(texts.economic_factors)}</SubHeading>
      <InputSelectRow
        hideUnitColumn={true}
        required={true}
        translate={translate}
        placeholderText={translate(texts.select_an_option)}
        // options={[...state.climateData.map((data) => ({ text: data.countryName, value: data.countryId }))].sort(
        //   (a, b) => (a.text > b.text ? 1 : -1)

        options={[
          ...state.currencyTable.map((row) => ({
            value: row.code || "",
            text: row.code ? translate(texts.currency_country_code(row.code.toLowerCase())) : "",
          })),
        ].sort((a, b) => (a.text > b.text ? 1 : -1))}
        autoFocusOnLoad={true}
        multi={false}
        name={translate(texts.currency)}
        value={calculationInput.currency}
        onValueChange={(value) => dispatch(Action.SetCalcInput({ currency: value }))}
      />

      <InputNumberRow
        hideUnitColumn={true}
        compactUnitSelector={true}
        required={true}
        translate={translate}
        multi={false}
        numberFormat={numberFormat}
        name={translate(texts.current_electricity_price)}
        unit={calculationInput.currency ? `${calculationInput.currency}/kWh` : "/kWh"}
        value={calculationInput.currentEnergyPrice}
        onValueChange={(value) => dispatch(Action.SetCalcInput({ currentEnergyPrice: value }))}
      />

      <InputAmountRow
        hideUnitColumn={true}
        compactUnitSelector={true}
        required={true}
        translate={translate}
        name={translate(texts.annual_electricity_price_increase)}
        value={calculationInput.expectedIncreaseEnergyPrice}
        defaultUnit={Units.Percent}
        unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
        numberFormat={numberFormat}
        multi={false}
        fieldName={"expectedIncreaseEnergyPrice"}
        onValueChange={(value: Amount.Amount<Quantity.Dimensionless>) =>
          dispatch(
            Action.SetCalcInput({
              expectedIncreaseEnergyPrice: value,
            })
          )
        }
      />

      <div>
        <InputNumberRow
          hideUnitColumn={true}
          compactUnitSelector={true}
          required={true}
          translate={translate}
          multi={false}
          name={translate(texts.current_heating_price)}
          numberFormat={numberFormat}
          unit={calculationInput.currency ? `${calculationInput.currency}/kWh` : "/kWh"}
          value={calculationInput.currentHeatingPrice}
          onValueChange={(value) =>
            dispatch(
              Action.SetCalcInput({
                currentHeatingPrice: value,
              })
            )
          }
        />
        <InputAmountRow
          hideUnitColumn={true}
          compactUnitSelector={true}
          required={true}
          translate={translate}
          name={translate(texts.annual_heating_price_increase)}
          value={calculationInput.expectedIncreaseHeatingPrice}
          defaultUnit={Units.Percent}
          unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
          numberFormat={numberFormat}
          multi={false}
          fieldName={"annualHeatingPrice"}
          onValueChange={(value) =>
            dispatch(
              Action.SetCalcInput({
                expectedIncreaseHeatingPrice: value as Amount.Amount<Quantity.Dimensionless>,
              })
            )
          }
        />
      </div>

      <InputAmountRow
        hideUnitColumn={true}
        compactUnitSelector={true}
        required={true}
        translate={translate}
        name={translate(texts.real_calculated_interest)}
        value={calculationInput.realCalculatedInterest}
        message={translate(texts.real_calculated_interest_info_message)}
        defaultUnit={Units.Percent}
        unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
        numberFormat={numberFormat}
        multi={false}
        fieldName={"calculatedInterest"}
        onValueChange={(value: Amount.Amount<Quantity.Dimensionless>) =>
          dispatch(
            Action.SetCalcInput({
              realCalculatedInterest: value,
            })
          )
        }
      />

      <InputAmountRow
        hideUnitColumn={true}
        compactUnitSelector={true}
        required={true}
        translate={translate}
        name={translate(texts.life_expectancy)}
        value={calculationInput.lifeExpectancy}
        defaultUnit={Units.Year}
        userUnits={userUnits}
        fieldName={"lifeExpectancy"}
        unitCallback={(unit: Unit.Unit<AnyQuantity>) => dispatch(Action.SetFieldUnit("lifeExpectancy", unit.name, 2))}
        numberFormat={numberFormat}
        multi={false}
        onValueChange={(value: Amount.Amount<Quantity.Duration>) =>
          dispatch(
            Action.SetCalcInput({
              lifeExpectancy: value,
            })
          )
        }
      />
      <SubHeading className="font-bold text-sm">{translate(texts.real_estate_value)}</SubHeading>
      <InputNumberRow
        compactUnitSelector={true}
        hideUnitColumn={true}
        required={false}
        translate={translate}
        multi={false}
        autoFocusOnLoad={false}
        name={translate(texts.current_real_estate_value)}
        unit={calculationInput.currency}
        numberFormat={numberFormat}
        fieldName={"realEstateValue"}
        value={calculationInput.currentRealEstateValue}
        message={translate(texts.real_estate_value_info_message)}
        onValueChange={(value) => dispatch(Action.SetCalcInput({ currentRealEstateValue: value }))}
      />
      <InputAmountRow
        compactUnitSelector={true}
        hideUnitColumn={true}
        required={false}
        translate={translate}
        name={translate(texts.expected_return)}
        value={calculationInput.expectedReturn}
        defaultUnit={Units.Percent}
        unitCallback={(_unit: Unit.Unit<AnyQuantity>) => null}
        multi={false}
        numberFormat={numberFormat}
        fieldName={"expectedReturn"}
        onValueChange={(value: Amount.Amount<Quantity.Dimensionless>) =>
          dispatch(
            Action.SetCalcInput({
              expectedReturn: value,
            })
          )
        }
      />
    </div>
  );
}
