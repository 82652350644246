import * as React from "react";
import { SizeProp, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark as fat_faCircleXmark,
  faArrowRightFromBracket as fat_faArrowRightFromBracket,
} from "@fortawesome/pro-thin-svg-icons";
import {
  faTrashCan as fal_faTrashCan,
  faEraser as fal_faEraser,
  faCircleInfo as fal_faCircleInfo,
  faTriangleExclamation as fal_faTriangleExclamation,
  faCircleCheck as fal_faCircleCheck,
  faCircle as fal_faCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faBars as far_faBars, faXmarkLarge as far_faXmarkLarge } from "@fortawesome/pro-regular-svg-icons";
import { faPrint as fas_faPrint } from "@fortawesome/pro-solid-svg-icons";

// import { } from "@fortawesome/pro-duotone-svg-icons";
// import { } from "@fortawesome/sharp-solid-svg-icons";

const icons = {
  fat_faCircleXmark,
  fal_faTrashCan,
  fal_faEraser,
  fat_faArrowRightFromBracket,
  fas_faPrint,
  fal_faCircleInfo,
  fal_faTriangleExclamation,
  fal_faCircleCheck,
  fal_faCircle,
  far_faBars,
  far_faXmarkLarge,
} as const;
library.add(...Object.values(icons));

export type AvailableIcons = keyof typeof icons;

export interface IconProps {
  readonly onClick?: () => void;
  readonly icon: AvailableIcons;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly colorClass?: string;
  readonly message?: string;
  readonly size?: SizeProp;
  readonly svgClassName?: string;
}

export function Icon({
  disabled,
  onClick,
  colorClass,
  className,
  message,
  icon,
  svgClassName,
  size = "lg",
}: IconProps): React.ReactElement<IconProps> {
  const divClassName = `
    ${disabled ? "text-gray-400" : onClick ? "cursor-pointer" : ""} 
    ${colorClass ?? "text-neutral-1000"} 
    ${className ?? ""}`;
  return (
    <div
      className={divClassName}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      title={message}
    >
      <FontAwesomeIcon className={svgClassName} icon={icons[icon]} size={size} />
    </div>
  );
}
