import * as R from "ramda";

const linearMultiples = [1, 2, 5];
const linearPowers = [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface Alternative {
  readonly min: number;
  readonly step: number;
  readonly ticks: number;
}

export function getLinearTicks(desiredTicks: number, min: number, max: number): ReadonlyArray<number> {
  let best: Alternative | undefined;
  for (const power of linearPowers) {
    const base = 10 ** power;
    for (const multiple of linearMultiples) {
      const step = base * multiple;
      const cMin = Math.ceil(min / step);
      const cMax = Math.ceil(max / step);
      const ticks = cMax - cMin + 1;

      if (!best || Math.abs(best.ticks - desiredTicks) > Math.abs(ticks - desiredTicks)) {
        best = {
          min: cMin * step,
          step: step,
          ticks: ticks,
        };
      }
    }
  }

  if (!best) {
    return [];
  }
  const b = best;
  return R.range(0, b.ticks).map((l) => b.min + b.step * l);
}
