import { AnyQuantity, getUnitFormat } from "@lcc/shared/src/uom";
import * as React from "react";
import { Serialize, Unit } from "uom";
import { unitLookup, UnitsFormat } from "uom-units";
// import { unitToString } from "uom/lib-cjs/serialize";
import { Select } from "./with-tw";

export interface UnitSelectorProps {
  readonly unit: Unit.Unit<AnyQuantity>;
  readonly units: readonly Unit.Unit<AnyQuantity>[];
  readonly unitChanged: (u: Unit.Unit<AnyQuantity>) => void;
  readonly extraText: string;
  readonly disabled?: boolean;
}

export function UnitSelector(props: UnitSelectorProps): React.ReactElement<UnitSelectorProps> {
  const { unit, units, unitChanged, extraText, disabled, ...htmlProps } = props;

  const isDisabled = disabled || (units.length === 1 && unit === units[0]);
  const unitOptions = [];

  unitOptions.push(
    ...units.map((u) => {
      const unitString = Serialize.unitToString(u);
      const label = getUnitFormat(u, UnitsFormat)?.label ?? u.name;

      return {
        key: unitString,
        value: unitString,
        label: label + extraText,
      };
    })
  );

  if (unitOptions.length === 1) {
    const option = unitOptions[0];
    return <div>{option.label}</div>;
  }

  return (
    <Select
      tabIndex={-1}
      value={unit.name}
      onChange={(e) => {
        const newValue = e.target.value;
        unitChanged(Serialize.stringToUnit(newValue, unitLookup)! as Unit.Unit<AnyQuantity>);
      }}
      disabled={isDisabled}
      {...htmlProps}
    >
      {unitOptions.map((option) => (
        <option key={option.key} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
