export type CreateBlobUrl = (hash: string, file?: string) => string;

export function createBlobUrlCreator(accessToken: string): CreateBlobUrl {
  //return (hash: string, file?: string) =>
  //  `/pds/blobs/${hash}?access_token=${accessToken}${file ? `&file=${file}` : ""}`;
  // return (hash: string) =>
  //   `https://promaster-api.dev.nibe.divid.se/rest/v3/0b40896e-3c97-4936-b36b-343e33d5a579/public/blobs/${hash}?access_token=${accessToken}`;

  return (hash: string, file?: string) =>
    `https://public-api.promaster.systemair.com/rest/v3/bd37fd6e-3efe-4e49-9a0f-d73638963529/public/blobs/${hash}?access_token=${accessToken}${
      file ? `&file=${file}` : ""
    }`;
}
