import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import gql from "graphql-tag";
import * as GQLOps from "../generated/generated-operations";
import { graphQLProductQueryWithAuth } from "../infrastructure/graphql";
import * as LccCalculation from "../pages/lcc";
import * as UserSettings from "../pages/user-settings";
import * as StartPage from "../pages/start-page";
import * as SharedState from "../infrastructure/shared-state";
import * as Routes from "../infrastructure/routes";
import * as Header from "../header";
import { config } from "../config";

const productMetaQuery = gql`
  query Main_ProductMeta($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        images {
          image {
            image
            type
            name
            file_name
          }
        }
      }
    }
  }
`;

export type State = {
  readonly location: Routes.MainLocation;
  readonly lccState: LccCalculation.State | undefined;
  readonly userSettingsState: UserSettings.State | undefined;
  readonly startPageState: StartPage.State | undefined;
  readonly headerState: Header.State | undefined;
  readonly metaResponse: GQLOps.Main_ProductMetaQuery | undefined;
};

export function init(
  location: Routes.MainLocation,
  prevState: State | undefined,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  // TODO, why is this part so complex?

  let gqlCmd = undefined;
  if (prevState === undefined) {
    const graphQLProductQuery = graphQLProductQueryWithAuth(sharedState.activeUser);
    gqlCmd = graphQLProductQuery<GQLOps.Main_ProductMetaQuery, GQLOps.Main_ProductMetaQueryVariables, Action>(
      productMetaQuery,
      { productId: config.promaster_meta_id },
      (data) => {
        return Action.MetaReceived(data);
      }
    );
  }
  // Initiate the header always. The header init function will take care of
  const [newHeaderState, newHeaderCmd] = Header.init(prevState?.headerState, sharedState);

  // Empty state with header initiated if previous state is undefined, if not we use it
  let newState: State =
    prevState === undefined
      ? {
          location: location,
          headerState: newHeaderState,
          lccState: undefined,
          userSettingsState: undefined,
          startPageState: undefined,
          metaResponse: undefined,
        }
      : { ...prevState, location: location }; // This is Strange, why do i need to add location here? If i dont add it the linkButton does not work

  let locationCmd: Cmd<Action> | undefined = undefined;

  // Take care of the location we are going to
  switch (location.type) {
    case "Lcc": {
      // Always init on url change so it gets the new url
      const [newLccState, newLccEditorCmd] = LccCalculation.init(location.location, sharedState, newState.lccState);
      newState = { ...newState, lccState: newLccState };
      locationCmd = Cmd.map(Action.DispatchLcc, newLccEditorCmd);
      break;
    }

    case "UserSettings": {
      // Always init on url change so it gets the new url
      const [newUserSettingsState, newUserSettingsCmd] = UserSettings.init(sharedState);
      newState = { ...newState, userSettingsState: newUserSettingsState };
      locationCmd = Cmd.map(Action.DispatchUserSettings, newUserSettingsCmd);
      break;
    }

    case "StartPage": {
      // Always init on url change so it gets the new url
      const [newStartPageState, newUserSettingsCmd] = UserSettings.init(sharedState);
      newState = { ...newState, startPageState: newStartPageState };
      locationCmd = Cmd.map(Action.DispatchStartPage, newUserSettingsCmd);
      break;
    }

    default:
      return exhaustiveCheck(location, true);
  }

  return [newState, Cmd.batch([locationCmd, gqlCmd, Cmd.map(Action.DispatchHeader, newHeaderCmd)])];
}

export const Action = ctorsUnion({
  DispatchLcc: (action: LccCalculation.Action) => ({ action }),
  DispatchUserSettings: (action: UserSettings.Action) => ({ action }),
  DispatchHeader: (action: Header.Action) => ({ action }),
  MetaReceived: (meta: GQLOps.Main_ProductMetaQuery) => ({ meta }),
  DispatchStartPage: (action: StartPage.Action) => ({ action }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, ReadonlyArray<SharedState.SharedStateAction | undefined>?] {
  switch (action.type) {
    case "DispatchLcc": {
      if (!state.lccState) {
        return [state];
      }
      const [lccState, lccCmd, sharedStateAction] = LccCalculation.update(action.action, state.lccState, sharedState);
      return [{ ...state, lccState }, Cmd.map(Action.DispatchLcc, lccCmd), sharedStateAction];
    }
    case "DispatchHeader": {
      if (!state.headerState) {
        return [state];
      }
      const [headerState, headerCmd, sharedStateUpdate] = Header.update(action.action, state.headerState, sharedState);
      return [{ ...state, headerState }, Cmd.map(Action.DispatchHeader, headerCmd), [sharedStateUpdate]];
    }

    case "DispatchUserSettings": {
      const [userSettingsState, userSettingsCmd, sharedStateAction] = UserSettings.update(
        action.action,
        state.userSettingsState!,
        sharedState
      );
      return [
        { ...state, userSettingsState },
        Cmd.map(Action.DispatchUserSettings, userSettingsCmd),
        [sharedStateAction],
      ];
    }

    case "DispatchStartPage": {
      const [startPageState, startPageCmd, sharedStateAction] = StartPage.update(
        action.action,
        state.startPageState!,
        sharedState
      );
      return [{ ...state, startPageState }, Cmd.map(Action.DispatchStartPage, startPageCmd), [sharedStateAction]];
    }

    case "MetaReceived": {
      return [{ ...state, metaResponse: action.meta }];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
