import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import { amountAs } from "../../uom";
import { FanFlowType, HeatAndFlowOutput, HeatingType, InputTables } from "../types";
import {
  calculateAdditionalHeatingDegreeHoursManualAvgTemp,
  calculateAdditionalHeatingDegreeHoursLocationAvgTemp,
} from "./additional-heating-degree-hours";
import { ClimateLocationResponse } from "../../climate-data-api";

export type DegreesHourMethod =
  | {
      readonly type: "manual_average_temp";
    }
  | {
      readonly type: "location_average_temp";
      readonly climateLocationData: ClimateLocationResponse;
    };

export function calculateHeatTransfer({
  inputTables,
  nominalAirFlowSupply,
  nominalAirFlowExtract,
  annualOperationTime,
  heatingType,
  defrostFunctionIncluded,
  temperatureEfficiency,
  supplyAirTemperature,
  fanReducedSupplyAirTemperature,
  exhaustAirTemperature,
  annualAverageTemperature,
  fanFlowType,
  correctionVariableFlow,
  supplyTempIncrease,
  airDensity,
  degreesHourMethod,
  tMinOutletExhaustExchanger,
}: {
  readonly inputTables: InputTables;
  readonly nominalAirFlowSupply: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly nominalAirFlowExtract: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly annualOperationTime: Amount.Amount<Quantity.Duration> | undefined;
  readonly heatingType: HeatingType;
  readonly defrostFunctionIncluded: boolean;
  readonly temperatureEfficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly supplyAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly fanReducedSupplyAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly exhaustAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly annualAverageTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly fanFlowType: FanFlowType | undefined;
  readonly correctionVariableFlow: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly supplyTempIncrease: Amount.Amount<Quantity.Temperature> | undefined;
  readonly airDensity: number;
  readonly degreesHourMethod: DegreesHourMethod;
  readonly tMinOutletExhaustExchanger: Amount.Amount<Quantity.Temperature> | undefined;
}): HeatAndFlowOutput {
  if (
    !supplyAirTemperature ||
    !fanReducedSupplyAirTemperature ||
    !annualAverageTemperature ||
    !nominalAirFlowSupply ||
    !nominalAirFlowExtract ||
    !annualOperationTime ||
    !supplyTempIncrease ||
    amountAs(Units.Hour, annualOperationTime) > 8760 ||
    amountAs(Units.Celsius, supplyAirTemperature) < 15
  ) {
    return {
      heatingEnergy: NaN,
      degreeHours: NaN,
      heatingDegreeCorrection: NaN,
    };
  }

  if (heatingType === "none") {
    const heatingEnergy =
      Math.abs(
        amountAs(Units.Celsius, fanReducedSupplyAirTemperature) - amountAs(Units.Celsius, annualAverageTemperature)
      ) *
      amountAs(Units.CubicMeterPerSecond, nominalAirFlowSupply) *
      airDensity *
      amountAs(Units.Hour, annualOperationTime);
    return {
      heatingEnergy: heatingEnergy,
      degreeHours: NaN,
      heatingDegreeCorrection: NaN,
    };
  }

  if (
    !exhaustAirTemperature ||
    !temperatureEfficiency ||
    (!tMinOutletExhaustExchanger && heatingType !== "wheel" && degreesHourMethod.type === "location_average_temp") ||
    amountAs(Units.Celsius, supplyAirTemperature) < 15 ||
    amountAs(Units.Celsius, supplyAirTemperature) > 22 ||
    amountAs(Units.Percent, temperatureEfficiency) > 90 ||
    amountAs(Units.Percent, temperatureEfficiency) < 0
  ) {
    return {
      heatingEnergy: NaN,
      degreeHours: NaN,
      heatingDegreeCorrection: NaN,
    };
  }

  // const min15SupplyAirTemperature =
  //   amountAs(Units.Celsius, fanReducedSupplyAirTemperature) < 15
  //     ? Amount.create(15, Units.Celsius)
  //     : fanReducedSupplyAirTemperature;

  // const interpolatedDegreeHours = interpolateHeatingDegree(
  //   inputTables.HeatingDegreeHoursAdditionalHeat,
  //   amountAs(Units.Celsius, min15SupplyAirTemperature),
  //   amountAs(Units.Celsius, annualAverageTemperature),
  //   "supply_air_temp",
  //   "annual_average_temp"
  // );

  // const degreeHours =
  //   interpolatedDegreeHours && (interpolatedDegreeHours * (100 - amountAs(Units.Percent, temperatureEfficiency))) / 100;

  const annualAverageTemperatureC = amountAs(Units.Celsius, annualAverageTemperature);
  const annualOperationTimeH = amountAs(Units.Hour, annualOperationTime);
  const supplyAirTemperatureC = amountAs(Units.Celsius, supplyAirTemperature);
  const exhaustAirTempBeforeExcC = amountAs(Units.Celsius, exhaustAirTemperature);
  const temperatureEfficiencyPercent = amountAs(Units.Percent, temperatureEfficiency);
  const supplyAirFlowMs = amountAs(Units.CubicMeterPerSecond, nominalAirFlowSupply);
  const extractAirFlowMs = amountAs(Units.CubicMeterPerSecond, nominalAirFlowExtract);
  const temperatureIncreaseSupply = amountAs(Units.Celsius, supplyTempIncrease);
  const tMinOutletExhaustExchangerC =
    tMinOutletExhaustExchanger !== undefined ? amountAs(Units.Celsius, tMinOutletExhaustExchanger) : 0;

  const heatingDegreeHours =
    degreesHourMethod.type === "manual_average_temp"
      ? calculateAdditionalHeatingDegreeHoursManualAvgTemp(
          annualAverageTemperatureC,
          supplyAirTemperatureC,
          exhaustAirTempBeforeExcC,
          temperatureEfficiencyPercent,
          supplyAirFlowMs,
          extractAirFlowMs,
          annualOperationTimeH,
          temperatureIncreaseSupply,
          inputTables.HeatingDegreeHoursAdditionalHeat
        )
      : calculateAdditionalHeatingDegreeHoursLocationAvgTemp(
          annualAverageTemperatureC,
          supplyAirTemperatureC,
          exhaustAirTempBeforeExcC,
          temperatureIncreaseSupply,
          temperatureEfficiencyPercent,
          degreesHourMethod.climateLocationData,
          annualOperationTimeH,
          defrostFunctionIncluded,
          tMinOutletExhaustExchangerC,
          heatingType
        );

  // const heatingDegreeCorrection =
  //   heatingType === "wheel"
  //     ? 0
  //     : Math.max(
  //         interpolateHeatingDegree(
  //           inputTables.CorrectedHeatingDegree.filter((f) => f.heating_type === heatingType),
  //           amountAs(Units.Percent, temperatureEfficiency),
  //           amountAs(Units.Celsius, annualAverageTemperature),
  //           "efficiency",
  //           "temperature"
  //         ) || 0,
  //         0
  //       );
  const heatingDegreeCorrection = 0;

  const nominalFlowValue = amountAs(Units.CubicMeterPerSecond, nominalAirFlowSupply);

  const correctedFlow =
    fanFlowType === "variable"
      ? correctionVariableFlow && nominalFlowValue * amountAs(Units.One, correctionVariableFlow)
      : nominalFlowValue;

  const correctedDegreeHours =
    heatingDegreeHours === undefined || heatingDegreeCorrection === undefined
      ? undefined
      : heatingDegreeHours + heatingDegreeCorrection * (amountAs(Units.Hour, annualOperationTime) / 8760);

  const heatingEnergy =
    correctedFlow === undefined || correctedDegreeHours === undefined
      ? undefined
      : airDensity * correctedFlow * correctedDegreeHours;

  return {
    heatingEnergy: heatingEnergy && Math.max(heatingEnergy, 0),
    degreeHours: heatingDegreeHours && Math.max(heatingDegreeHours, 0),
    correctedDegreeHours: correctedDegreeHours && Math.max(correctedDegreeHours, 0),
    heatingDegreeCorrection: heatingDegreeCorrection && Math.max(heatingDegreeCorrection, 0),
  };
}
