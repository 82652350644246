import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export type HttpFetchCmd<A> = FetchOne<A> | FetchMultiple<A> | Post<A>;

export type RequestedResponseType = "text" | "json" | "arrayBuffer";
export type ResponseType = JSON | string | ArrayBuffer;

export interface FetchOne<A> {
  readonly home: typeof home;
  readonly type: "FetchOne";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly responseType: RequestedResponseType;
  readonly onSuccess?: (response: ResponseType) => A;
}

export interface FetchMultiple<A> {
  readonly home: typeof home;
  readonly type: "FetchMultiple";
  readonly headers: { readonly [header: string]: string };
  readonly urls: readonly string[];
  readonly responseType: RequestedResponseType;
  readonly onSuccess?: (responses: readonly ResponseType[]) => A;
}

export interface Post<A> {
  readonly home: typeof home;
  readonly type: "Post";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly contentType: "application/json";
  readonly body: string;
  readonly responseType: ResponseType;
  readonly onSuccess?: (response: unknown) => A;
}

export function fetchOne<A, ResponseType = string>(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: RequestedResponseType,
  onSuccess?: (response: ResponseType) => A
): FetchOne<A> {
  return {
    home: "http-fetch",
    type: "FetchOne",
    headers,
    url,
    responseType,
    onSuccess: onSuccess as unknown as (response: string) => A,
  };
}

export function fetchMultiple<
  A,
  B extends RequestedResponseType,
  C extends B extends "json" ? JSON : B extends "arrayBuffer" ? ArrayBuffer : string
>(
  headers: { readonly [header: string]: string },
  urls: readonly string[],
  responseType: B,
  onSuccess?: (responses: readonly C[]) => A
): FetchMultiple<A>;
export function fetchMultiple<A>(
  headers: { readonly [header: string]: string },
  urls: readonly string[],
  responseType: RequestedResponseType,
  onSuccess?: (responses: readonly ResponseType[]) => A
): FetchMultiple<A> {
  return {
    home: "http-fetch",
    type: "FetchMultiple",
    headers,
    urls,
    responseType,
    onSuccess,
  };
}

export function post<A, ResponseType = unknown>(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: "text" | "json",
  contentType: "application/json",
  body: string,
  onSuccess?: (response: ResponseType) => A
): Post<A> {
  return {
    home: "http-fetch",
    type: "Post",
    headers,
    url,
    contentType,
    body,
    responseType,
    onSuccess,
  };
}

export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: HttpFetchCmd<A1>): HttpFetchCmd<A2> {
  switch (cmd.type) {
    case "Post":
    case "FetchOne": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && ((response: string) => actionMapper(onSuccess(response))),
      };
    }
    case "FetchMultiple": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && ((responses: readonly string[]) => actionMapper(onSuccess(responses))),
      };
    }
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}
