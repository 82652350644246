import { config } from "./config";

const serverUri = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

// eslint-disable-next-line no-console
console.log(serverUri);

export const userManagerSettings = {
  authority: config.oidc_login_url,
  client_id: config.oidc_client_id,
  redirect_uri: `${serverUri}/login-callback`,
  response_type: "id_token token",
  scope: "openid profile email",
  silent_redirect_uri: `${serverUri}/silent-renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
  post_logout_redirect_uri: `${serverUri}/logged-out`,
};
