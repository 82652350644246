export interface ClientConfig {
  readonly env: string;
  readonly dsn: string | undefined;
  readonly sentry_sample_rate: number;
  readonly sentry_traces_sample_rate: number;
  readonly promaster_meta_id: string;
  readonly promaster_marker: string;
  readonly oidc_login_url: string;
  readonly oidc_client_id: string;
  readonly image_service_url: string;
  readonly oidc_post_logout_url?: string;
  readonly ga_target_id: string | undefined;
}
