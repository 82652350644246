export interface User {
  readonly id_token: string;
  readonly session_state?: unknown;
  readonly access_token: string;
  readonly token_type: string;
  readonly scope: string;
  readonly profile: unknown;
  readonly expires_at: number;
  readonly state: unknown;
  readonly expires_in: number | undefined;
  readonly expired: boolean | undefined;
  readonly scopes: readonly string[];
}

export interface User {
  readonly id_token: string;
  readonly session_state?: unknown;
  readonly access_token: string;
  readonly token_type: string;
  readonly scope: string;
  readonly profile: unknown;
  readonly expires_at: number;
  readonly state: unknown;
  readonly expires_in: number | undefined;
  readonly expired: boolean | undefined;
  readonly scopes: readonly string[];
}

export interface UntypedToken {
  readonly [key: string]: string;
}

export type DecodedToken = UntypedToken;

export type ActiveUser = {
  readonly userId: string;
  readonly userName: string;
  readonly email: string;
  readonly accessToken: string;
  readonly idToken: string;
};

/**
 * Transform a OIDC user object to a ActiveUser object
 * We pass the ActiveUser object down as context, and
 * we don't want anyone but this component to read the
 * raw OIDC state from the store.
 */

interface ActiveUserError {
  readonly type: "error";
  readonly reason: string;
}
export function isValidUser(activeUser: ActiveUser | ActiveUserError): activeUser is ActiveUser {
  if ((activeUser as ActiveUserError).type !== undefined) {
    return false;
  }

  return true;
}

export function buildActiveUser(user: User): ActiveUser | ActiveUserError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const claims = user.profile as any;

  return {
    userId: claims.sub,
    userName: claims["name"],
    email: claims["email"],
    accessToken: user.access_token,
    idToken: user.id_token,
  };
}
