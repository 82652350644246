import * as AbstractImage from "abstract-image";
import { getTextWidth } from "../utils/text-width";
import { createRectangle, createText, createLine } from "../abstract-image-proxy";
import { getLinearTicks } from "../charts";
import { NumberFormat, stringToLocalized } from "../lang-texts";

const chartPadding = 25;
const chartWidth = 500;
const chartHeight = 400;
const topLabels = 15;
const bottomLabels = 10;
const barHeight = chartHeight - chartPadding * 2 - bottomLabels - topLabels;
const barTop = chartPadding + topLabels;
const barBottom = barHeight + barTop;
const verticalLineOffset = 5;
const font = "9pt helvetica";

export function stackedBarChart(barInput: StackedBarInput): AbstractImage.AbstractImage {
  const components = generateBars(barInput);

  const image = AbstractImage.createAbstractImage(
    AbstractImage.createPoint(0, 0),
    AbstractImage.createSize(chartWidth, chartHeight),
    AbstractImage.white,
    components
  );

  return image;
}

export type BarData = {
  readonly label: string;
  readonly values: readonly number[];
};

export type BarSection = { readonly label: string; readonly color: AbstractImage.Color };

export type StackedBarInput = {
  readonly bars: readonly BarData[];
  readonly sections: readonly BarSection[];
  readonly numberFormat?: NumberFormat;
  readonly verticalAxisLabel?: string;
};

// eslint-disable-next-line functional/prefer-readonly-type
function generateBars(barInput: StackedBarInput): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [];

  const maxBarHeight = Math.max(
    ...barInput.bars.map((bar) =>
      bar.values.reduce(function (a, b) {
        return a + b;
      }, 0)
    )
  );

  const ticks = getLinearTicks(15, 0, maxBarHeight);
  const maxValue = Math.max(...ticks);
  const scaleValue = (value: number): number => (value / maxValue) * barHeight;

  const ticksLabels = ticks.map((tick) => ({
    value: tick,
    position: barHeight - scaleValue(tick),
    text: barInput.numberFormat ? stringToLocalized(tick.toString(), barInput.numberFormat) : tick.toString(),
  }));

  const tickTextWidth =
    Math.max(...ticksLabels.map((tick) => getTextWidth(tick.text, font))) + (barInput.verticalAxisLabel ? 20 : 0);

  components.push(...createTicks(ticksLabels, tickTextWidth, barInput.verticalAxisLabel));

  const barPadding = 25;
  const barRightMargin = chartWidth - barPadding - chartPadding;
  const barLeftMarginInitial = chartPadding + tickTextWidth + verticalLineOffset + barPadding;
  const remainingChartWidth = barRightMargin - barLeftMarginInitial;
  const barSpacing = remainingChartWidth / barInput.bars.length;

  const width = Math.min(Math.max(barSpacing - 50, 50), remainingChartWidth / 4);
  const barLeftMargin = barLeftMarginInitial + (barSpacing - width) / 2;

  for (const [barIndex, bar] of barInput.bars.entries()) {
    let previousValue = 0;
    for (const [index, value] of bar.values.entries()) {
      const scaledValue = scaleValue(value);
      components.push(
        createRectangle({
          topLeft: {
            x: barLeftMargin + barIndex * barSpacing,
            y: barBottom - (previousValue + scaledValue),
          },
          bottomRight: {
            x: barLeftMargin + width + barIndex * barSpacing,
            y: barBottom - previousValue,
          },
          fillColor: barInput.sections[index].color,
          strokeColor: AbstractImage.white,
          strokeThickness: 1,
        })
      );
      components.push(
        createText({
          text: bar.label,
          position: { x: barLeftMargin + barIndex * barSpacing + width / 2, y: chartHeight - chartPadding },
          textAlignment: "center",
          horizontalGrowthDirection: "uniform",
          fontFamily: "DaxlinePro",
        })
      );
      previousValue += scaledValue;
    }
  }

  components.push(...createAxis(tickTextWidth));
  components.push(...createSectionLabels(barInput.sections, remainingChartWidth, barLeftMarginInitial));

  return components;
}

function createSectionLabels(
  barSections: readonly BarSection[],
  remainingChartWidth: number,
  barLeftMargin: number
  // eslint-disable-next-line functional/prefer-readonly-type
): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [];

  const size = 10;
  const spacing = 20;
  const fullWidth =
    barSections.map((section) => getTextWidth(section.label, font) + size + 5).reduce((a, b) => a + b, 0) +
    spacing * (barSections.length - 1);
  let left = barLeftMargin + remainingChartWidth / 2 - fullWidth / 2;
  for (const section of barSections) {
    const labelWidth = getTextWidth(section.label, font);
    components.push(
      createRectangle({
        topLeft: { x: left, y: chartPadding },
        bottomRight: { x: left + size, y: chartPadding + size },
        fillColor: section.color,
      })
    );
    components.push(
      createText({
        position: { x: left + size + 5, y: chartPadding + 3 },
        text: section.label,
        textAlignment: "left",
        horizontalGrowthDirection: "right",
        verticalGrowthDirection: "uniform",
        fontFamily: "DaxlinePro",
      })
    );
    left += labelWidth + size + 5 + spacing;
  }

  return components;
}

function createTicks(
  ticks: readonly {
    readonly value: number;
    readonly position: number;
    readonly text: string;
  }[],
  tickTextWidth: number,
  label: string | undefined
  // eslint-disable-next-line functional/prefer-readonly-type
): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [];
  components.push(
    ...ticks.map((tick) =>
      createLine({
        start: { x: tickTextWidth + chartPadding, y: barTop + tick.position },
        end: { x: chartWidth - chartPadding, y: barTop + tick.position },
        strokeColor: AbstractImage.gray,
      })
    )
  );
  components.push(
    ...ticks.map((tick) =>
      createText({
        position: { x: tickTextWidth + chartPadding - 5, y: barTop + tick.position },
        text: tick.text,
        textAlignment: "right",
        horizontalGrowthDirection: "left",
        fontFamily: "DaxlinePro",
      })
    )
  );
  label &&
    components.push(
      createText({
        position: { x: chartPadding, y: barBottom / 2 + 10 },
        text: label,
        textAlignment: "center",
        horizontalGrowthDirection: "uniform",
        clockwiseRotationDegrees: -90,
        fontFamily: "DaxlinePro",
      })
    );
  return components;
}

// eslint-disable-next-line functional/prefer-readonly-type
function createAxis(tickTextWidth: number): AbstractImage.Component[] {
  const components: AbstractImage.Component[] = [];

  components.push(
    createLine({
      start: { x: tickTextWidth + chartPadding, y: barBottom },
      end: { x: chartWidth - chartPadding, y: barBottom },
      strokeColor: AbstractImage.gray,
    })
  );
  components.push(
    createLine({
      start: { x: verticalLineOffset + tickTextWidth + chartPadding, y: barBottom },
      end: { x: verticalLineOffset + tickTextWidth + chartPadding, y: barTop },
      strokeColor: AbstractImage.gray,
    })
  );
  return components;
}
