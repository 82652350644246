/* eslint-disable @typescript-eslint/prefer-enum-initializers */
import { KeyParams } from "./normalized-text";

export type TextTypes = TextKey | Dummy;

export type TextKey = {
  readonly type: "TextKey";
  readonly key: string;
  readonly params: KeyParams;
};

export type Dummy = {
  readonly type: "Dummy";
  readonly key: string;
  readonly params: KeyParams;
};

const key = (key: string, params: KeyParams = {}): TextKey => ({
  type: "TextKey",
  key,
  params,
});

export const texts = {
  add_inlet_outlet_group: (groupName: string) => key("add_inlet_outlet_group", { groupName }),
  ahu_data: key("ahu_data"),
  ahu_header_info: key("ahu_header_info"),
  annual_average_temperature_input: key("annual_average_temperature"),
  annual_average_temperature: key("annual_average_temperature"),
  annual_average_temperature_radio: key("annual_average_temperature_radio"),
  annual_electricity_price_increase: key("annual_electricity_price_increase"),
  annual_heating_price_increase: key("annual_heating_price_increase"),
  annual_operation_time: key("annual_operation_time"),
  annual_price_increase_electricity: key("annual_price_increase_electricity"),
  annual_price_increase_heating: key("annual_price_increase_heating"),
  air_handling_units: key("air_handling_units"),
  after_savings: key("after_savings"),
  ashrae_permission_info: key("ashrae_permission_info"),
  back: key("back"),
  battery_heater: key("battery_heater"),
  before_savings: key("before_savings"),
  before_investments: key("before_investments"),
  calculation_data: key("calculation_data"),
  carbon_emission: key("carbon_emission"),
  carbon_emission_country: key("carbon_emission_country"),
  carbon_emission_electric: key("carbon_emission_electric"),
  carbon_emission_heating: key("carbon_emission_heating"),
  carbon_emission_country_code: (countryCode: string) => key(`carbon_emission_${countryCode}`),
  carbon_emission_info_first: key("carbon_emission_info_first"),
  carbon_emission_info_second: key("carbon_emission_info_second"),
  carbon_emission_number_electricity: key("carbon_emission_number_electricity"),
  clear_inputs: key("clear_inputs"),
  changed_real_estate_explanation: key("changed_real_estate_explanation"),
  conditions: key("conditions"),
  copy_calculation_link_button: key("copy_calculation_link_button"),
  constant_flow: key("constant_flow"),
  corrected_heating_degree: key("corrected_heating_degree"),
  recovered_thermal_energy: key("recovered_thermal_energy"),
  correction_variable_flow: key("correction_variable_flow"),
  cost: key("cost"),
  country_selection: key("country_selection"),
  location_selection: key("location_selection"),
  currency: key("currency"),
  curr: key("curr"),
  currency_country_code: (currencyCode: string) => key(`curr_${currencyCode}`),
  current_energy_price_electricity: key("current_energy_price_electricity"),
  current_energy_price_heating: key("current_energy_price_heating"),
  current_energy_price: key("current_energy_price"),
  current_electricity_price: key("current_electricity_price"),
  current_heating_price: key("current_heating_price"),
  download: key("download"),
  defrost_function_included: key("defrost_function_included"),
  economic_factors: key("economic_factors"),
  electricity: key("electricity"),
  email_sent: key("email_sent"),
  email_error: key("email_error"),
  extract_air_temp: key("extract_air_temp"),
  extract_fan_data: key("extract_fan_data"),
  extract_fan: key("extract_fan"),
  extract: key("extract"),
  fan_calculation: key("fan_calculation"),
  fan_data: key("fan_data"),
  fan_power: key("fan_power"),
  fan_total: key("fan_total"),
  fan: key("fan"),
  field_required: key("field_required"),
  flow_type: key("flow_type"),
  freezing_correction: key("freezing_correction"),
  get_started: key("get_started"),
  heating_degree_correction: key("heating_degree_correction"),
  heating_degree: key("heating_degree"),
  heating_recovery_additional_heat: key("heating_recovery_additional_heat"),
  heating_type: key("heating_type"),
  heating: key("heating"),
  inputs_title: key("inputs_title"),
  input_too_large: key("input_too_large"),
  invalid_location_message: key("invalid_location_message"),
  invalid_email: key("invalid_email"),
  lcc_energy_net_present_value: key("lcc_energy_net_present_value"),
  lcc_energy: key("lcc_energy"),
  life_expectancy: key("life_expectancy"),
  life_expectancy_years: key("life_expectancy_years"),
  link_copied: key("link_copied"),
  logout: key("logout"),
  location_info_message: key("location_info_message"),
  maximum_units_comparison: key("maximum_units_comparison"),
  next: key("next"),
  nominal_flow: key("nominal_flow"),
  none: key("none"),
  offer_amount: key("offer_amount"),
  outlet_exhaust_exchanger_info_message: key("outlet_exhaust_exchanger_info_message"),
  operation_time_hour: key("operation_time_hour"),
  payback_diagram: key("payback_diagram"),
  payback_time_until_even: key("payback_time_until_even"),
  print: key("print"),
  profit_from_investment: key("profit_from_investment"),
  project_name: key("project_name"),
  project_sign: key("project_sign"),
  plate_heater: key("plate_heater"),
  plate_standard_bypass: key("plate_standard_bypass"),
  plate_section_defrost: key("plate_section_defrost"),
  power_per_fan: key("power_per_fan"),
  present_value: key("present_value"),
  pressure_drop_connected_ducts: key("pressure_drop_connected_ducts"),
  real_calculated_interest: key("real_calculated_interest"),
  real_calculated_interest_info_message: key("real_calculated_interest_info_message"),
  real_estate_value_info_message: key("real_estate_value_info_message"),
  result: key("result"),
  results_title: key("results_title"),
  select_an_option: key("select_an_option"),
  select_an_location: key("select_an_location"),
  selected_for_compare: key("selected_for_compare"),
  sfp_calculation_type: (fanCalculationType: string) => key(`sfp_${fanCalculationType}`),
  sfpe_total: key("sfp_total"),
  share_calculation_button: key("share_calculation_button"),
  share_email_message_first_part: key("share_email_message_first_part"),
  share_email_message_second_part: key("share_email_message_second_part"),
  share_email_message_third_part: key("share_email_message_third_part"),
  share_email_message_link: key("share_email_message_link"),
  share_email_message_questions: key("share_email_message_questions"),
  share_email_message_contact_team: key("share_email_message_contact_team"),
  share_email_message: (param: {
    readonly url: string;
    readonly projectName: string;
    readonly currentDate: string;
    readonly userName: string;
  }) => key("share_email_message", param),
  share_email_title: (param: { readonly projectName: string; readonly currentDate: string }) =>
    key("share_email_title", param),
  share_info: key("share_info"),
  specific_fan_power_e: key("specific_fan_power_e"),
  specific_fan_power_v: key("specific_fan_power_v"),
  specific_net_present_value_electricity: key("specific_net_present_value_electricity"),
  specific_net_present_value_heating: key("specific_net_present_value_heating"),
  specific_net_present_value: key("specific_net_present_value"),
  supply_air_temp: key("supply_air_temp"),
  supply_fan_data: key("supply_fan_data"),
  supply_fan: key("supply_fan"),
  supply: key("supply"),
  temperature_efficiency: key("temperature_efficiency"),
  temperature_efficiency_dry: key("temperature_efficiency_dry"),
  temperature_increase_supply_fan: key("temperature_increase_supply_fan"),
  temperatures: key("temperatures"),
  test1: key("test1"),
  test2: key("test2"),
  title_tab_text: key("title_tab_text"),
  total: key("total"),
  unit: key("unit"),
  unit_name: key("unit_name"),
  reference_unit: key("reference_unit"),
  variable_flow: key("variable_flow"),
  value_before_investments: key("value_before_investments"),
  value_should_be_between: (min: string, max: string) => key("value_should_be_between", { min, max }),
  validation_extract_air_temp: (min: string, max: string) => key("validation_extract_air_temp", { min, max }),
  rotary_heater: key("rotary_heater"),
  annual_operation_time_input: key("annual_operation_time_input"),
  operation_time_input_total: key("operation_time_input_total"),
  operation_time_input_detailed: key("operation_time_input_detailed"),
  operation_time_detailed_workdays_message: key("operation_time_detailed_workdays_message"),
  operation_time_detailed_weekends_message: key("operation_time_detailed_weekends_message"),
  operation_time_sat_sun: key("operation_time_sat_sun"),
  operation_time_mon_fri: key("operation_time_mon_fri"),
  annual_energy_usage: key("annual_energy_usage"),
  real_estate_value: key("real_estate_value"),
  current_real_estate_value: key("current_real_estate_value"),
  energy_cost_saving: key("energy_cost_saving"),
  energy_fan_saving: key("energy_fan_saving"),
  energy_heat_saving: key("energy_heat_saving"),
  energy_electric_fans: key("energy_electric_fans"),
  energy_additional_heat: key("energy_additional_heat"),
  expected_return: key("expected_return"),
  calculated_increased_value: key("calculated_increased_value"),
  changed_real_estate_value: key("changed_real_estate_value"),
  existing_annual_energy_usage: key("existing_annual_energy_usage"),
  existing_unit_data: key("existing_unit_data"),
  year: key("year"),
  years: key("years"),
  manual_input: key("manual_input"),
  selecting_location: key("selecting_location"),
  start_page_headline: key("start_page_headline"),
  start_page_bodytext: key("start_page_bodytext"),
  t_min_outlet_exhaust_exchanger: key("t_min_outlet_exhaust_exchanger"),
  variable_flow_info_message: key("variable_flow_info_message"),
};
