import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { texts } from "@lcc/shared";
import * as State from "./state";
import * as SharedState from "../../infrastructure/shared-state";
import * as Calculate from "./parts/calculate";
import * as Result from "./parts/result";
import * as Page from "./parts/page";
import { Button, PrimaryButton, withTw } from "../../components/elements";
import { validateConditions } from "./parts/calculate/components";
import { Navbar, ProductTreeMenuContainerToggler } from "./navbar-tree-menu";

export const ShowMenuToggler = withTw(
  "span",

  (p: { readonly showMenu?: boolean }) =>
    p.showMenu
      ? "md:ml-[200px] lg:ml-[350px] pl-[0.625rem] pt-64 w-full pr-32 h-full"
      : "w-full md:ml-[200px] lg:ml-[350px] md:pl-26 lg:pl-26 pt-64 h-full pr-32"
);

export function view({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  if (
    !state ||
    state.location === undefined ||
    state.type !== "DataRecieved" ||
    state.pageState.type === "WatingForLocalStorageState"
  ) {
    return <></>;
  }

  const translate = sharedState.lang.translate;

  return (
    <div className={`w-full h-full flex`}>
      <div className={`flex flex-row text-xs w-[58rem]`}>
        <Navbar showSideMenu={state.showMenu} translate={translate} dispatch={dispatch} pageState={state.pageState} />

        <ProductTreeMenuContainerToggler
          showMenu={state.showMenu}
          dispatch={dispatch}
        ></ProductTreeMenuContainerToggler>
        <div className="pl-20 pt-64 w-full pr-14">
          {state.pageState.selectedSection !== "result" ? (
            <div>
              <div className="min-h-[300px]">
                <Calculate.View
                  dispatch={Dispatch.map(State.Action.CalculateAction, dispatch)}
                  state={state.calculateState}
                  sharedState={sharedState}
                  pageState={state.pageState}
                />
              </div>
              <div>
                {state.pageState.selectedSection !== "project_info" ? (
                  <Button
                    label={translate(texts.back)}
                    type="secondary"
                    className="float-left mt-16"
                    onClick={() => {
                      dispatch(State.Action.PageAction(Page.PageAction.BackSection()));
                    }}
                  />
                ) : null}

                <PrimaryButton
                  disabled={!isValidSection(state.pageState, state.pageState.selectedSection)}
                  className="float-right mt-16 mb-250"
                  onClick={() => {
                    dispatch(State.Action.PageAction(Page.PageAction.NextSection()));
                  }}
                >
                  {translate(texts.next)}
                </PrimaryButton>
              </div>
            </div>
          ) : (
            // <div className="flex-1 ml-24" style={{ maxWidth: `${24 + numAhus * 4.5}rem` }}>

            <Result.View
              dispatch={Dispatch.map(State.Action.ResultAction, dispatch)}
              state={state.resultState}
              sharedState={sharedState}
              pageState={state.pageState}
            />
            // </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function isValidSection(pageState: Page.StateCalculation, section: Page.ViewSections): boolean {
  const input = pageState.calculationInput;
  const ahuInput = pageState.calculationInputAhu;

  switch (section) {
    case "project_info": {
      if (input.projectName && input.projectName !== "") {
        return true;
      } else {
        return false;
      }
    }
    case "economic_factors": {
      if (
        input.realCalculatedInterest &&
        input.lifeExpectancy &&
        input.expectedIncreaseHeatingPrice &&
        input.expectedIncreaseEnergyPrice &&
        input.currency !== undefined &&
        input.currency !== "" &&
        input.currentEnergyPrice &&
        input.currentHeatingPrice
      ) {
        return true;
      } else {
        return false;
      }
    }
    case "conditions": {
      if (validateConditions(input)) {
        return true;
      } else {
        return false;
      }
    }
    case "ahu_inputs": {
      const everyAhuValid = ahuInput.every(
        (ahu) =>
          (ahu.fanFlowType === "constant" || (ahu.fanFlowType && ahu.correctionVariableFlow)) &&
          ((input.fanCalculationType === "sfpeEntireUnit" && ahu.specificFanPowerTotal) ||
            (input.fanCalculationType === "sfpePerFan" && ahu.specificFanPowerSupply && ahu.specificFanPowerExtract) ||
            (input.fanCalculationType === "sfpvEntireUnit" && ahu.specificFanPowerTotal) ||
            (input.fanCalculationType === "sfpvPerFan" && ahu.specificFanPowerSupply && ahu.specificFanPowerExtract) ||
            (input.fanCalculationType === "powerPerFan" && ahu.fanPowerExtract && ahu.fanPowerSupply)) &&
          ahu.nominalFlowSupply &&
          ahu.nominalFlowExtract &&
          (ahu.heatingType === "none" ||
            ((ahu.heatingType === "battery" ||
              ahu.heatingType === "plateSectionDefrost" ||
              ahu.heatingType === "plateStandardBypass" ||
              ahu.heatingType === "wheel") &&
              ahu.temperatureEfficiency))
      );
      return everyAhuValid;
    }

    case "result": {
      if (
        ahuInput.every(
          (ahu) =>
            (ahu.fanFlowType === "constant" || (ahu.fanFlowType && ahu.correctionVariableFlow)) &&
            ((input.fanCalculationType === "sfpeEntireUnit" && ahu.specificFanPowerTotal) ||
              (input.fanCalculationType === "sfpePerFan" &&
                ahu.specificFanPowerSupply &&
                ahu.specificFanPowerExtract) ||
              (input.fanCalculationType === "sfpvEntireUnit" && ahu.specificFanPowerTotal) ||
              (input.fanCalculationType === "sfpvPerFan" &&
                ahu.specificFanPowerSupply &&
                ahu.specificFanPowerExtract) ||
              (input.fanCalculationType === "powerPerFan" && ahu.fanPowerExtract && ahu.fanPowerSupply)) &&
            ahu.nominalFlowSupply &&
            ahu.nominalFlowExtract &&
            (ahu.heatingType === "none" ||
              ((ahu.heatingType === "battery" ||
                ahu.heatingType === "plateSectionDefrost" ||
                ahu.heatingType === "plateStandardBypass" ||
                ahu.heatingType === "wheel") &&
                ahu.temperatureEfficiency))
        ) &&
        input.realCalculatedInterest &&
        input.lifeExpectancy &&
        input.expectedIncreaseHeatingPrice &&
        input.expectedIncreaseEnergyPrice &&
        input.currency !== undefined &&
        input.currency !== "" &&
        input.currentEnergyPrice &&
        input.currentHeatingPrice &&
        validateConditions(input)
      ) {
        return true;
      } else {
        return false;
      }
    }
    default:
      return false;
  }
}
