import { GetUnitInfoFn, Result } from "../calculation-results";
import { NumberFormat, TranslateFn } from "../lang-texts";
import { Name } from "./results-report/index";

export type ReportType = typeof Name;

export type PrintProps = {
  readonly results: ReadonlyArray<Result>;
  readonly getUnitInfo: GetUnitInfoFn;
  readonly translate: TranslateFn;
  readonly numberFormat: NumberFormat;
  readonly fonts: FontData;
};

export interface FontData {
  readonly normal: Uint8Array;
  readonly medium: Uint8Array;
  readonly bold: Uint8Array;
  readonly italic: Uint8Array;
  readonly boldItalic: Uint8Array;
}

export const Fonts = [
  "/fonts/DaxlineOffcPro.ttf",
  "/fonts/DaxlineOffcPro-Bold.ttf",
  "/fonts/DaxlineOffcPro-Medium.ttf",
  "/fonts/DaxlineOffcPro-Italic.ttf",
  "/fonts/DaxlineOffcPro-BoldItalic.ttf",
];

export function createFonts(fontInput: ReadonlyArray<ArrayBuffer>): FontData {
  const normal = new Uint8Array(fontInput[0]);
  const medium = new Uint8Array(fontInput[1]);
  const bold = new Uint8Array(fontInput[2]);
  const italic = new Uint8Array(fontInput[3]);
  const boldItalic = new Uint8Array(fontInput[4]);
  return {
    normal,
    medium,
    bold,
    italic,
    boldItalic,
  };
}
