import { Amount } from "uom";
import { Units } from "uom-units";
import { amountAs } from "../uom";
import { roundTo } from "../utils";
import { CalculationAhuInputs, CalculationInputs, CalculationOutputs, HeatAndFlowOutput } from "./types";
import { DegreesHourMethod, calculateHeatTransfer } from "./functions/heating";
import { createAhuUserInput, createUserInput } from "./functions/inputs";
import { calculateAnnualOperationTime, calculateFlow } from "./functions/misc";
import { calculateSpecificFanPower } from "./functions/specificFanPower";
import { calculatePaybackData } from "./functions/payback";

export function calculateLCC(
  calculationInputs: CalculationInputs,
  ahuInputs: CalculationAhuInputs
): CalculationOutputs {
  const {
    inputTables,
    climateLocationData,
    currentEnergyPrice,
    currentHeatingPrice,
    expectedIncreaseEnergyPrice,
    expectedIncreaseHeatingPrice,
    realCalculatedInterest,
    lifeExpectancy,
    annualAverageTemperature,
    supplyAirTemperature,
    exhaustAirTemperature,
    annualAverageTemperatureInput,
    fanCalculationType,
  } = calculationInputs;
  const {
    selectedUnitResult,
    offerAmount,
    correctionVariableFlow,
    nominalFlowSupply,
    nominalFlowExtract,
    temperatureEfficiency,
    fanFlowType,
    heatingType,
    tMinOutletExhaustExchanger,
    defrostFunctionIncluded,
  } = ahuInputs;

  const years = lifeExpectancy && amountAs(Units.Year, lifeExpectancy);
  const interestEnergy =
    realCalculatedInterest &&
    expectedIncreaseEnergyPrice &&
    amountAs(Units.One, realCalculatedInterest) - amountAs(Units.One, expectedIncreaseEnergyPrice);

  const interestHeating =
    realCalculatedInterest &&
    expectedIncreaseHeatingPrice &&
    amountAs(Units.One, realCalculatedInterest) - amountAs(Units.One, expectedIncreaseHeatingPrice);

  const discountingFactorEnergy =
    interestEnergy === undefined || years === undefined
      ? undefined
      : interestEnergy === 0
      ? years
      : (1 - (1 + interestEnergy) ** -years) / interestEnergy;

  const discountingFactorHeating =
    interestHeating === undefined || years === undefined
      ? undefined
      : interestHeating === 0
      ? years
      : (1 - (1 + interestHeating) ** -years) / interestHeating;

  const presentValueEnergy =
    currentEnergyPrice === undefined || discountingFactorEnergy === undefined
      ? undefined
      : roundTo(parseFloat(currentEnergyPrice) * discountingFactorEnergy, 2);
  const presentValueHeating =
    currentHeatingPrice === undefined || discountingFactorHeating === undefined
      ? undefined
      : roundTo(parseFloat(currentHeatingPrice) * discountingFactorHeating, 2);

  const annualOperationTime = calculateAnnualOperationTime(
    calculationInputs.annualOperationTimeInput,
    calculationInputs.annualOperationTime,
    calculationInputs.annualOperationTimeWorkdays,
    calculationInputs.annualOperationTimeWeekends
  );

  const specificFanPower = calculateSpecificFanPower(ahuInputs, fanCalculationType);

  const supplyFanEnergy = calculateFlow(
    nominalFlowSupply,
    specificFanPower.supply,
    annualOperationTime,
    fanFlowType,
    correctionVariableFlow
  );

  const extractFanEnergy = calculateFlow(
    nominalFlowExtract,
    specificFanPower.extract,
    annualOperationTime,
    fanFlowType,
    correctionVariableFlow
  );

  const supplyFanLCC = supplyFanEnergy && presentValueEnergy && supplyFanEnergy * presentValueEnergy;
  const extractFanLCC = extractFanEnergy && presentValueEnergy && extractFanEnergy * presentValueEnergy;

  const fanLCCTotal = (supplyFanLCC ? supplyFanLCC : 0) + (extractFanLCC ? extractFanLCC : 0);

  const supplyTemp = supplyAirTemperature && amountAs(Units.Celsius, supplyAirTemperature);
  const sfp = specificFanPower.supply && amountAs(Units.KiloWattPerCubicMeterPerSecond, specificFanPower.supply);

  const airDensity = 1.2; // in kg/m^3
  const airHeatCapacity = 1; // in kJ/(kg*K) constant volume

  const temperatureIncreaseSupplyFan =
    sfp === undefined ? undefined : Amount.create(sfp / (airDensity * airHeatCapacity), Units.Celsius, 2);
  const supplyTempIncrease = temperatureIncreaseSupplyFan && amountAs(Units.Celsius, temperatureIncreaseSupplyFan);

  const newSupplyAirTemperature =
    supplyTemp === undefined || supplyTempIncrease === undefined
      ? undefined
      : Number.isNaN(supplyFanLCC)
      ? supplyAirTemperature
      : Amount.create(supplyTemp - supplyTempIncrease, Units.Celsius);

  const degreesHourMethod: DegreesHourMethod | undefined =
    annualAverageTemperatureInput === "selection"
      ? climateLocationData
        ? {
            type: "location_average_temp",
            climateLocationData: climateLocationData,
          }
        : undefined
      : {
          type: "manual_average_temp",
        };
  const { heatingEnergy, degreeHours, heatingDegreeCorrection, correctedDegreeHours } =
    heatingType && inputTables && degreesHourMethod
      ? calculateHeatTransfer({
          inputTables,
          nominalAirFlowSupply: nominalFlowSupply,
          nominalAirFlowExtract: nominalFlowExtract,
          annualOperationTime,
          heatingType,
          defrostFunctionIncluded: defrostFunctionIncluded || false,
          temperatureEfficiency,
          supplyAirTemperature,
          fanReducedSupplyAirTemperature: newSupplyAirTemperature,
          exhaustAirTemperature,
          annualAverageTemperature,
          fanFlowType,
          correctionVariableFlow,
          supplyTempIncrease: temperatureIncreaseSupplyFan,
          airDensity,
          degreesHourMethod,
          tMinOutletExhaustExchanger,
        })
      : ({} as HeatAndFlowOutput);

  const noExc =
    inputTables && degreesHourMethod
      ? calculateHeatTransfer({
          inputTables,
          nominalAirFlowSupply: nominalFlowSupply,
          nominalAirFlowExtract: nominalFlowExtract,
          annualOperationTime,
          heatingType: "none",
          defrostFunctionIncluded: defrostFunctionIncluded || false,
          temperatureEfficiency,
          supplyAirTemperature,
          fanReducedSupplyAirTemperature: newSupplyAirTemperature,
          exhaustAirTemperature,
          annualAverageTemperature,
          fanFlowType,
          correctionVariableFlow,
          supplyTempIncrease: temperatureIncreaseSupplyFan,
          airDensity,
          degreesHourMethod,
          tMinOutletExhaustExchanger,
        })
      : ({} as HeatAndFlowOutput);

  // eslint-disable-next-line no-console

  const heatingLCC =
    heatingEnergy === undefined ? undefined : presentValueHeating && heatingEnergy * presentValueHeating;

  const fanTotalEnergy = (supplyFanEnergy ? supplyFanEnergy : 0) + (extractFanEnergy ? extractFanEnergy : 0);
  const recoveredThermalEnergy =
    noExc.heatingEnergy !== undefined && heatingEnergy !== undefined ? noExc.heatingEnergy - heatingEnergy : 0;

  const LCCTotal =
    (supplyFanLCC ? supplyFanLCC : 0) +
    (extractFanLCC ? extractFanLCC : 0) +
    (heatingLCC ? heatingLCC : 0) +
    (offerAmount ? parseFloat(offerAmount) : 0);

  const energyTotal =
    (supplyFanEnergy ? supplyFanEnergy : 0) +
    (extractFanEnergy ? extractFanEnergy : 0) +
    (heatingEnergy ? heatingEnergy : 0);
  const userInput = createUserInput(calculationInputs, inputTables);
  const ahuUserInput = createAhuUserInput(ahuInputs, supplyFanLCC, extractFanLCC);

  const energyFanSaving =
    selectedUnitResult?.supplyExistingKWH === undefined ||
    selectedUnitResult?.extractExistingKWH === undefined ||
    supplyFanEnergy === undefined ||
    extractFanEnergy === undefined ||
    currentEnergyPrice === undefined
      ? undefined
      : Math.max(
          0,
          (amountAs(Units.KiloWattHour, selectedUnitResult?.supplyExistingKWH) +
            amountAs(Units.KiloWattHour, selectedUnitResult?.extractExistingKWH) -
            supplyFanEnergy -
            extractFanEnergy) *
            parseFloat(currentEnergyPrice)
        );

  const isSelected = ahuInputs.selectedForCompare;
  const noHeatEnergySaving =
    selectedUnitResult?.heatExistingKWH &&
    amountAs(Units.KiloWattHour, selectedUnitResult?.heatExistingKWH) === undefined &&
    heatingEnergy === undefined;
  const energyHeatSaving =
    noHeatEnergySaving || !selectedUnitResult?.heatExistingKWH || !currentHeatingPrice
      ? undefined
      : Math.max(
          ((amountAs(Units.KiloWattHour, selectedUnitResult?.heatExistingKWH) || 0) - (heatingEnergy || 0)) *
            parseFloat(currentHeatingPrice),
          0
        );

  const energyCostSaving = (energyFanSaving || 0) + (energyHeatSaving || 0);
  const calculatedIncreasedValue =
    calculationInputs.expectedReturn && energyCostSaving / amountAs(Units.One, calculationInputs.expectedReturn);

  const payback = calculatePaybackData(
    selectedUnitResult,
    isSelected,
    lifeExpectancy,
    offerAmount,
    currentEnergyPrice,
    currentHeatingPrice,
    expectedIncreaseEnergyPrice,
    expectedIncreaseHeatingPrice,
    fanTotalEnergy,
    heatingEnergy
  );
  const sfpePerFanSupply = fanCalculationType === "sfpePerFan" ? specificFanPower.supply : undefined;
  const sfpePerFanExtract = fanCalculationType === "sfpePerFan" ? specificFanPower.extract : undefined;
  const sfpvPerFanSupply = fanCalculationType === "sfpvPerFan" ? specificFanPower.supply : undefined;
  const sfpvPerFanExtract = fanCalculationType === "sfpvPerFan" ? specificFanPower.extract : undefined;
  const sfpeEntireUnitSupply = fanCalculationType === "sfpeEntireUnit" ? specificFanPower.supply : undefined;
  const sfpeEntireUnitExtract = fanCalculationType === "sfpeEntireUnit" ? specificFanPower.extract : undefined;
  const sfpvEntireUnitSupply = fanCalculationType === "sfpvEntireUnit" ? specificFanPower.supply : undefined;
  const sfpvEntireUnitExtract = fanCalculationType === "sfpvEntireUnit" ? specificFanPower.extract : undefined;

  return {
    userInput,
    ahuUserInput,
    payback,
    presentValueEnergy,
    presentValueHeating,
    supplyFanLCC,
    extractFanLCC,
    fanLCCTotal,
    heatingLCC,
    LCCTotal,
    temperatureIncreaseSupplyFan,
    degreeHours,
    correctedDegreeHours,
    heatingDegreeCorrection,
    supplyFanEnergy,
    extractFanEnergy,
    fanTotalEnergy,
    heatingEnergy,
    energyTotal,
    energyFanSaving: isSelected || noHeatEnergySaving ? undefined : energyFanSaving,
    energyHeatSaving: isSelected ? undefined : energyHeatSaving,
    energyCostSaving: isSelected ? undefined : energyCostSaving,
    calculatedIncreasedValue: isSelected ? undefined : calculatedIncreasedValue,
    specificFanPowerExtract: specificFanPower.extract && specificFanPower.extract,
    specificFanPowerSupply: specificFanPower.supply && specificFanPower.supply,
    recoveredThermalEnergy: recoveredThermalEnergy,
    sfpePerFanSupply,
    sfpePerFanExtract,
    sfpvPerFanSupply,
    sfpvPerFanExtract,
    sfpeEntireUnitSupply,
    sfpeEntireUnitExtract,
    sfpvEntireUnitSupply,
    sfpvEntireUnitExtract,
  };
}
