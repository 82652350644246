import gql from "graphql-tag";
import { Cmd } from "@typescript-tea/core";
import { ctorsUnion, CtorsUnion } from "ctors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GQLOps from "../../generated/generated-operations";
import * as SharedState from "../../infrastructure/shared-state";
import * as Routes from "../../infrastructure/routes";
import { SharedStateAction } from "../../infrastructure/shared-state";
import * as Calculate from "./parts/calculate";
import * as Result from "./parts/result";
import * as Page from "./parts/page";
import { getClearedCalculationInput } from "./parts/page";
import { graphQLProductQueryWithAuth } from "../../infrastructure/graphql";
import { config } from "../../config";

export const productQuery = gql`
  query lcc_Query($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          DefaultInputsPerMarket {
            ...DefaultInputs_Query
          }
        }
      }
    }
  }
  fragment DefaultInputs_Query on DefaultInputsPerMarket {
    market
    field_name
    value
    unit
  }
`;

export type BaseState = {
  readonly location: Routes.LccLocation;
  readonly calculateState: Calculate.State | undefined;
  readonly resultState: Result.State | undefined;

  readonly showMenu: boolean;
};

export type WaitingState = BaseState & { readonly type: "WaitingForData"; readonly pageState: Page.State | undefined };

export type DataState = BaseState & {
  readonly type: "DataRecieved";
  readonly defaultInputsTable: readonly GQLOps.DefaultInputs_QueryFragment[];
  readonly pageState: Page.State;
};

export type State = WaitingState | DataState;

export const Action = ctorsUnion({
  CalculateAction: (action: Calculate.Action) => ({
    action,
  }),
  ResultAction: (action: Result.Action) => ({
    action,
  }),
  PageAction: (action: Page.Action) => ({ action }),
  ToggleShow: (showMenu: boolean) => ({
    showMenu,
  }),
  DataReceived: (data: GQLOps.Lcc_QueryQuery) => ({
    data,
  }),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  location: Routes.LccLocation,
  sharedState: SharedState.SharedState,
  prevState: State | undefined
): readonly [State, Cmd<Action>?] {
  if (!prevState || prevState.type !== "DataRecieved") {
    const state: State = {
      type: "WaitingForData",
      location: location,
      calculateState: prevState?.calculateState,
      resultState: prevState?.resultState,
      showMenu: prevState?.showMenu ?? false,
      pageState: prevState?.pageState,
    };

    const graphQLProductQuery = graphQLProductQueryWithAuth(sharedState.activeUser);
    const query = graphQLProductQuery<GQLOps.Lcc_QueryQuery, GQLOps.Lcc_QueryQueryVariables, Action>(
      productQuery,
      { productId: config.promaster_meta_id },
      (data) => {
        return Action.DataReceived(data);
      }
    );

    return [state, query];
  } else {
    return [prevState];
  }
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, ReadonlyArray<SharedStateAction | undefined>?] {
  switch (action.type) {
    case "CalculateAction": {
      if (!state.calculateState || state.type !== "DataRecieved") {
        return [state];
      }
      const [calculateState, calculateCmd, pageAction] = Calculate.update(
        action.action,
        state.calculateState,
        sharedState
      );

      const [newState, pageCmd, sharedStateAction] = handlePageAction(pageAction, state, sharedState);
      const projectName =
        newState.pageState.type === "CalculationState" ? newState.pageState.calculationInput.projectName : undefined;
      const projectNameAction = projectName !== undefined ? SharedStateAction.SetProjectName(projectName) : undefined;
      return [
        { ...newState, calculateState },
        Cmd.batch<Action>([pageCmd, Cmd.map(Action.CalculateAction, calculateCmd)]),
        [sharedStateAction, projectNameAction],
      ];
    }
    case "ResultAction": {
      if (!state.resultState || state.type !== "DataRecieved") {
        return [state];
      }
      const [resultState, resultCmd, pageAction] = Result.update(action.action, state.resultState, sharedState);

      const [newState, pageCmd, sharedStateAction] = handlePageAction(pageAction, state, sharedState);
      return [
        { ...newState, resultState },
        Cmd.batch<Action>([pageCmd, Cmd.map(Action.ResultAction, resultCmd)]),
        [sharedStateAction],
      ];
    }

    case "PageAction": {
      if (state.type !== "DataRecieved") {
        return [state];
      }
      const [newState, cmd, sharedStateAction] = handlePageAction(action.action, state, sharedState);
      return [newState, cmd, [sharedStateAction]];
    }

    case "ToggleShow": {
      return [{ ...state, showMenu: action.showMenu }];
    }

    case "DataReceived": {
      const defaultInputsTable = action.data.product?.modules.custom_tables.DefaultInputsPerMarket;
      if (!defaultInputsTable) {
        return [state];
      }
      const newState: Omit<DataState, "pageState"> = {
        type: "DataRecieved",
        location: state.location,
        calculateState: state.calculateState,
        resultState: state.resultState,
        showMenu: state.showMenu,
        defaultInputsTable: defaultInputsTable,
      };
      switch (newState.location.type) {
        case "Calculate":
        case "Result": {
          if (state.pageState) {
            return initPage(sharedState, { ...newState, pageState: state.pageState });
          }

          const restoreFromParam = Page.restoreCalculationInputsFromParams();
          if (restoreFromParam) {
            const pageState: Page.State = {
              type: "CalculationState",
              calculationInput: restoreFromParam.calculationInput,
              calculationInputAhu: restoreFromParam.calculationInputAhu,
              selectedSection: "project_info",
            };
            return initPage(sharedState, { ...newState, pageState: pageState });
          }
          const clearedInputs = getClearedCalculationInput(
            sharedState.lang.translate,
            newState.defaultInputsTable,
            sharedState.market
          );
          const pageState: Page.State = {
            type: "CalculationState",
            calculationInput: clearedInputs.calculationInput,
            calculationInputAhu: clearedInputs.calculationInputAhu,
            selectedSection: "project_info",
          };
          return initPage(sharedState, { ...newState, pageState: pageState });
        }
        default: {
          return exhaustiveCheck(newState.location, true);
        }
      }
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

// handles pageactions that are shared between all pages, can update the entire state
export function handlePageAction(
  action: Page.Action | undefined,
  state: DataState,
  sharedState: SharedState.SharedState
): readonly [DataState, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (action === undefined) {
    return [state];
  }

  switch (action.type) {
    case "SetSelectedSection": {
      if (state.pageState.type !== "CalculationState") {
        return [state];
      }

      if(action.section === "result"){
        // Ugly hack, result page must inited because it omits loading climate data on first init if the location and country are undefined.
        // The proper way is to have result as it's own url location, like it probably was before... but now it's a section only initied once 
        const [resultState, resultAction] = Result.init(sharedState, state.pageState, state.resultState);
        return [{ ...state, resultState:resultState, pageState: { ...state.pageState, selectedSection: action.section } }, Cmd.map(Action.ResultAction, resultAction)];
      }

      return [{ ...state, pageState: { ...state.pageState, selectedSection: action.section } }];
    }
    case "NextSection": {
      if (state.pageState.type !== "CalculationState") {
        return [state];
      }
      const currentSection = state.pageState.selectedSection;
      const sectionIndex = Page.Sections.findIndex((section) => section === currentSection);
      const nextSection = Page.Sections[sectionIndex + 1];

      if(nextSection === "result"){
        // Ugly hack, result page must inited because it omits loading climate data on first init if the location and country are undefined.
        // The proper way is to have result as it's own url location, like it probably was before... but now it's a section only initied once 
        const [resultState, resultAction] = Result.init(sharedState, state.pageState, state.resultState);
        return [{ ...state, resultState:resultState, pageState: { ...state.pageState, selectedSection: nextSection } }, Cmd.map(Action.ResultAction, resultAction)];
      }

      return [{ ...state, pageState: { ...state.pageState, selectedSection: nextSection || "project_info" } }];
    }
    case "BackSection": {
      if (state.pageState.type !== "CalculationState") {
        return [state];
      }
      const currentSection = state.pageState.selectedSection;
      const sectionIndex = Page.Sections.findIndex((section) => section === currentSection);
      const backSection = Page.Sections[sectionIndex - 1];
      return [{ ...state, pageState: { ...state.pageState, selectedSection: backSection || "project_info" } }];
    }
    case "SetCalcInput": {
      if (state.pageState.type !== "CalculationState") {
        return [state];
      }

      const projectName = action.input.projectName;

      const newCalculationInput = {
        ...(state.pageState ? state.pageState.calculationInput : {}),
        ...action.input,
      };

      //Save to URL newCalculationInput

      const newPageState = {
        ...state.pageState,
        calculationInput: newCalculationInput,
      };

      return [
        {
          ...state,
          pageState: newPageState,
        },
        Page.storeCalculationInputs(newPageState),
        projectName === undefined ? undefined : SharedState.SharedStateAction.SetProjectName(projectName),
      ];
    }
    case "SetCalcAhuInput": {
      if (state.type !== "DataRecieved" || state.pageState.type !== "CalculationState") {
        return [state];
      }
      const newPageState = {
        ...state.pageState,
        calculationInputAhu: state.pageState.calculationInputAhu.map((i) =>
          i.id === action.ahuId ? { ...i, ...action.input } : i
        ),
      };
      return [
        {
          ...state,
          pageState: newPageState,
        },
        Page.storeCalculationInputs(newPageState),
      ];
    }
    case "SetCalcAhuSelected": {
      if (state.type !== "DataRecieved" || state.pageState.type !== "CalculationState") {
        return [state];
      }
      const newPageState = {
        ...state.pageState,
        calculationInputAhu: state.pageState.calculationInputAhu.map((i) => ({
          ...i,
          selectedForCompare: i.id === action.ahuId,
        })),
      };
      return [
        {
          ...state,
          pageState: newPageState,
        },
        Page.storeCalculationInputs(newPageState),
      ];
    }
    case "SetFieldUnit":
      return [state, undefined, action];

    case "AddAhu": {
      if (state.type !== "DataRecieved") {
        return [state];
      }
      return [
        {
          ...state,
          pageState: Page.addAhuInputs(state.pageState, sharedState.lang.translate),
        },
      ];
    }

    case "RemoveAhu": {
      if (state.type !== "DataRecieved") {
        return [state];
      }
      const newPageState = Page.removeAhuInputs(state.pageState, action.id);
      if (newPageState.type === "CalculationState") {
        return [{ ...state, pageState: newPageState }, Page.storeCalculationInputs(newPageState)];
      }
      return [state];
    }

    case "CalculationInputReceived": {
      return [state];
    }

    case "ClearCalculationInput": {
      if (state.type !== "DataRecieved") {
        return [state];
      }
      const clearedInputs = Page.getClearedCalculationInput(
        sharedState.lang.translate,
        state.defaultInputsTable,
        sharedState.market
      );
      const newPageState: Page.State = {
        type: "CalculationState",
        calculationInput: clearedInputs.calculationInput,
        calculationInputAhu: clearedInputs.calculationInputAhu,
        selectedSection: "project_info",
      };
      return [
        {
          ...state,
          pageState: newPageState,
        },
        Page.storeCalculationInputs(newPageState),
      ];
    }

    case "CalculationInputStored": {
      return [state];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function initPage(sharedState: SharedState.SharedState, state: DataState): readonly [DataState, Cmd<Action>?] {
  const [calculateState, calculateAction] = Calculate.init(sharedState, state.calculateState);
  const calculateLocationCmd = Cmd.map(Action.CalculateAction, calculateAction);
  const [resultState, resultAction] = Result.init(sharedState, state.pageState, state.resultState);
  const resultLocationCmd = Cmd.map(Action.ResultAction, resultAction);
  const newState: DataState = {
    ...state,
    calculateState,
    resultState,
  };
  return [newState, Cmd.batch<Action>([calculateLocationCmd, resultLocationCmd])];
}
