import * as ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { EffectManager, Program } from "@typescript-tea/core";
import { appInfo } from "@lcc/shared";
import * as Root from "./root";
import * as NavigationEffectManager from "./infrastructure/effect-managers/navigation";
import * as OidcEffectManager from "./infrastructure/effect-managers/oidc-client";
import * as HttpEffectManager from "./infrastructure/effect-managers/http-fetch";
import * as LocalStorageEffectManager from "./infrastructure/effect-managers/local-storage";
import { config } from "./config";
import { initSentry } from "./sentry";

initSentry(config.dsn, appInfo.pipelineId, config.env, config.sentry_sample_rate, config.sentry_traces_sample_rate);

if (config.ga_target_id) {
  ReactGA.initialize(config.ga_target_id);
}

// Run the program
const rootElement = document.getElementById("root");
const render = (view: JSX.Element): void => ReactDOM.render(view, rootElement);

const effectManagers: readonly EffectManager[] = [
  NavigationEffectManager.createEffectManager<Root.Action>(Root.Action.UrlChanged, Root.Action.UrlRequested),
  OidcEffectManager.createOidcEffectManager(),
  HttpEffectManager.createEffectManager(),
  LocalStorageEffectManager.createEffectManager(),
];

const rootProgram: Program<NavigationEffectManager.Url, Root.State, Root.Action, JSX.Element> = {
  init: Root.init,
  update: Root.update,
  view: Root.view,
  subscriptions: Root.subscriptions,
};

Program.run(rootProgram, NavigationEffectManager.getCurrentUrl(), render, effectManagers);
