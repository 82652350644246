import React, { ReactElement } from "react";
import { Dispatch } from "@typescript-tea/core";
import { CalculationInputs } from "@lcc/shared/src/calculation";
import { texts, TranslateFn } from "@lcc/shared";
import * as SharedState from "../../../../../infrastructure/shared-state";
import { Action, State } from "../state";
import * as Page from "../../page";
import { InputTextRow, SubHeading } from "./shared";

export function ProjectNameInput({
  sharedState,
  dispatch,
  pageState,
}: {
  readonly state: State | undefined;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.SharedState;
  readonly pageState: Page.State;
}): JSX.Element {
  if (!pageState) {
    return <div>Loading</div>;
  }

  if (pageState.type !== "CalculationState") {
    return <span />;
  }

  const translate = sharedState.lang.translate;
  // const userUnits = getUserUnits(sharedState);

  const { calculationInput } = pageState;

  return <>{projectNameValue(translate, dispatch, calculationInput)}</>;
}

function projectNameValue(
  translate: TranslateFn,
  dispatch: Dispatch<Action>,

  calculationInput: CalculationInputs
  // _userUnits: Record<string, string>
): ReactElement {
  return (
    <div>
      <SubHeading>{translate(texts.project_sign)}</SubHeading>

      <InputTextRow
        hideUnitColumn={true}
        required={true}
        translate={translate}
        autoFocusOnLoad={true}
        multi={false}
        name={translate(texts.project_name)}
        value={calculationInput.projectName}
        onValueChange={(value) => dispatch(Action.SetCalcInput({ projectName: value }))}
      />
    </div>
  );
}
