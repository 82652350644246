import * as React from "react";
import { localizedToString, NumberFormat, stringToLocalized } from "@lcc/shared";
import { debounce as lodashDebounce, DebouncedFunc } from "lodash";
import { Input, ErrorWrapper } from "./with-tw";

export interface NumberInputBoxProps {
  readonly value: string | undefined;
  readonly valueChanged: (newNumber: string | undefined, newDecimals: number) => void;
  readonly decimals: number;
  readonly notNumericMessage: string;
  readonly isRequiredMessage: string;
  readonly errorMsg?: (value: string) => string;
  readonly disabled: boolean;
  readonly numberFormat: NumberFormat;
  readonly className?: string;
  readonly autoFocus?: boolean;
}

// What the optimal debounce is may vary between users. 350ms seems like a nice value...
const debounceTime = 350;

export function NumberInputBox(props: NumberInputBoxProps): React.ReactElement<NumberInputBoxProps> {
  const { disabled, errorMsg, value, decimals, className, numberFormat } = props;

  const [textValue, setTextValue] = React.useState(
    value === undefined ? undefined : stringToLocalized(value, numberFormat)
  );
  const inputRef = React.useRef(null);

  const update = React.useMemo(createDebouncedUpdate, []);
  let errorMessage = errorMsg && textValue ? errorMsg(localizedToString(textValue, numberFormat)) : "";

  const finalError = getInputErrorMessage(props, textValue) || errorMessage;

  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setTextValue(value === undefined ? undefined : stringToLocalized(value, numberFormat));
    }
  }, [value]);

  return (
    <ErrorWrapper isvalid={!finalError}>
      <Input
        type="text"
        autoFocus={props.autoFocus}
        elementref={inputRef}
        className={className}
        value={textValue}
        readOnly={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const zeroFormatedValue = zeroFormatValue(e.currentTarget.value, decimals, numberFormat);
          errorMessage =
            errorMsg && e.currentTarget.value ? errorMsg(localizedToString(e.currentTarget.value, numberFormat)) : "";
          update(props, zeroFormatedValue);
          setTextValue(e.currentTarget.value);
        }}
        title={finalError}
        onBlur={() => {
          update.cancel();
          const zeroFormatedValue = zeroFormatValue(textValue, decimals, numberFormat);
          const formatedValue = formatValue(textValue, decimals, numberFormat);
          errorMessage = errorMsg && textValue ? errorMsg(localizedToString(textValue, numberFormat)) : "";
          updateValue(props, zeroFormatedValue);
          setTextValue(formatedValue);
        }}
      />
    </ErrorWrapper>
  );
}

function createDebouncedUpdate(): DebouncedFunc<(props: NumberInputBoxProps, textValue: string) => void> {
  return lodashDebounce((props, textValue) => updateValue(props, textValue), debounceTime);
}

function updateValue(props: NumberInputBoxProps, textValue: string): void {
  const errorMessage = getInputErrorMessage(props, textValue);
  if (!errorMessage) {
    const decimals = getDecimals(textValue, props.numberFormat);
    props.valueChanged(textValue === undefined ? "" : textValue, textValue === undefined ? 0 : decimals);
  }
}

function getInputErrorMessage(props: NumberInputBoxProps, textValue: string | undefined): string | undefined {
  const { isRequiredMessage, notNumericMessage } = props;
  // Check if blank and if required or not
  if (!textValue || (textValue.trim() === "" && isRequiredMessage)) {
    // The user has not entred anything, but a value was required
    return isRequiredMessage;
  }
  if (textValue !== undefined && textValue.trim() !== "" && isRequiredMessage) {
    // The user has entered something, but it could not be converted to an number (=was not numeric)
    return notNumericMessage;
  }
  return undefined;
}

function zeroFormatValue(text: string | undefined, maxDecimals: number, numberFormat: NumberFormat): string {
  if (!text || text.length === 0) {
    return "";
  }
  const parsedFloatValue = stringToNumber(text, numberFormat);
  if (Number.isNaN(parsedFloatValue)) {
    return "";
  }
  const decimals = getDecimals(text, numberFormat);
  const num = parsedFloatValue.toFixed(Math.min(maxDecimals, decimals));

  return num;
}

function formatValue(text: string | undefined, maxDecimals: number, numberFormat: NumberFormat): string {
  if (!text || text.length === 0) {
    return "";
  }
  const parsedFloatValue = stringToNumber(text, numberFormat);
  if (Number.isNaN(parsedFloatValue)) {
    return "";
  }
  const decimals = getDecimals(text, numberFormat);
  const num = parsedFloatValue.toFixed(Math.min(maxDecimals, decimals));

  return stringToLocalized(num, numberFormat);
}

function stringToNumber(str: string, numberFormat: NumberFormat): number {
  return parseFloat(localizedToString(str, numberFormat));
}

function getDecimals(str: string, numberFormat: NumberFormat): number {
  const decimals = localizedToString(str, numberFormat).split(".")[1];
  if (!decimals) {
    return 0;
  }
  let length = 0;
  for (const char of decimals) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(Number(char))) {
      break;
    }
    length += 1;
  }
  return length;
}
