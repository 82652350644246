import * as resultsCreator from "./results-report/creator";
import { CreatorType } from "./results-report/creator";
import { ReportType } from "./types";

const reportCreators = [resultsCreator].map((creator) => ({ ...creator }));

export function getReportCreator(name: ReportType): { readonly create: CreatorType; readonly Name: string } {
  const creator = reportCreators.find((c) => c.Name === name);
  if (!creator) {
    throw new Error(`could not find creator: ${name}`);
  }
  return creator;
}
