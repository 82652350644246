import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { SharedState, SharedStateAction } from "../../infrastructure/shared-state";

export type State = {};

export function init(_sharedState: SharedState): readonly [State, Cmd<Action>?] {
  return [{}];
}

export const Action = ctorsUnion({
  SetCodesVisibility: (showCodes: boolean) => ({ showCodes }),
  Dummy: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState
): readonly [State, Cmd<Action>?, SharedStateAction?] {
  switch (action.type) {
    case "SetCodesVisibility": {
      return [state, undefined, SharedStateAction.SetShowCodes(action.showCodes)];
    }
    case "Dummy": {
      return [state, undefined, SharedStateAction.SetShowCodes(false)];
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
