import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { /*isSystemairOrDividEmail,*/ texts } from "@lcc/shared";
import * as LccCalculation from "../pages/lcc";
import * as UserSettings from "../pages/user-settings";
import * as StartPage from "../pages/start-page";
import * as State from "./state";
import * as SharedState from "../infrastructure/shared-state";
import * as Header from "../header";

export function view({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  React.useEffect(() => {
    document.title = sharedState.lang.translate(texts.title_tab_text);
  }, [sharedState.lang.code]);
  // if (!isSystemairOrDividEmail(sharedState.activeUser.email)) {
  //   return (
  //     <div className="absolute mt-[33.33%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12">
  //       <div className="font-bold text-lg">Access Denied</div>
  //       <p>You don't have permission to access this site.</p>
  //     </div>
  //   );
  // }

  const hideClearButton = state.location.type === "StartPage";

  return (
    <div className="h-full">
      <Header.View
        pageState={state.lccState?.pageState?.type === "CalculationState" ? state.lccState?.pageState : undefined}
        hideClearButton={hideClearButton}
        dispatch={Dispatch.map(State.Action.DispatchHeader, dispatch)}
        pageDispatch={(a) => dispatch(State.Action.DispatchLcc(a))}
        sharedState={sharedState}
        state={state.headerState}
        key={"header_view"}
        meta={state.metaResponse}
      />

      <div className="h-full">
        <MainContent dispatch={dispatch} state={state} sharedState={sharedState} />
      </div>
    </div>
  );
}

function MainContent({
  dispatch,
  state,
  sharedState,
}: {
  readonly dispatch: Dispatch<State.Action>;
  readonly state: State.State;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const currentLocation = state.location;

  switch (currentLocation.type) {
    case "Lcc": {
      return (
        <LccCalculation.view
          dispatch={(a) => dispatch(State.Action.DispatchLcc(a))}
          state={state.lccState}
          sharedState={sharedState}
        ></LccCalculation.view>
      );
    }

    case "UserSettings": {
      return (
        <UserSettings.view
          dispatch={(a) => dispatch(State.Action.DispatchUserSettings(a))}
          state={state.userSettingsState!}
          sharedState={sharedState}
        ></UserSettings.view>
      );
    }

    case "StartPage": {
      return (
        <StartPage.view
          dispatch={(a) => dispatch(State.Action.DispatchStartPage(a))}
          state={state.startPageState!}
          sharedState={sharedState}
          meta={state.metaResponse}
        ></StartPage.view>
      );
    }

    default: {
      return <div>NOT FOUNDs</div>;
    }
  }
}
