// import gql from "graphql-tag";
import { Sub } from "@typescript-tea/core";
import * as State from "./state";
import { accessTokenRefreshed, listenUserSession } from "../infrastructure/effect-managers/oidc-client";
// import * as GraphQLSub from "../infrastructure/effect-managers/graphql-sub";
//import * as Main from "../main/index";
// import * as GQLOps from "../generated/generated-operations";
//import * as SharedState from "../infrastructure/shared-state";

// export const userSettingsEventSubscription = gql`
//   subscription Root_UserSettingsEvent($topics: [String!]!) {
//     userSettingsEvent(topics: $topics) {
//       __typename
//       ... on UserSettingsEvent_UserSettingClearedEvent {
//         userId
//         name
//       }
//       ... on UserSettingsEvent_UserSettingsClearedEvent {
//         userId
//       }
//       ... on UserSettingsEvent_UserSettingsSetEvent {
//         userId
//         settings {
//           name
//           value
//         }
//       }
//     }
//   }
// `;

export function subscriptions(state: State.State): Sub<State.Action> {
  const userSessionSub = listenUserSession(State.Action.UserSessionChanged);
  const accessTokenRefreshedSub = accessTokenRefreshed(State.Action.AccessTokenRefreshed);
  if (state.type === "LoggedInState" && state.mainState) {
    // const sharedState = State.buildSharedState(state);
    // const sub = GraphQLSub.listenSubscription<
    //   State.Action,
    //   GQLOps.Root_UserSettingsEventSubscription,
    //   GQLOps.Root_UserSettingsEventSubscriptionVariables
    // >(
    //   SharedState.gqlSubConnConfig(sharedState),
    //   userSettingsEventSubscription,
    //   { topics: [`*.${sharedState.activeUser.userId}`] },
    //   (response) => State.Action.OnUserSettingsEvent(response.data)
    // );
    // return Sub.batch([
    //   userSessionSub,
    //   Sub.map(State.Action.DispatchMain, Main.subscriptions(state.mainState, sharedState)),
    // ]);
  }
  return Sub.batch([userSessionSub, accessTokenRefreshedSub]);
}
