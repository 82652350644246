import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  TextTranslateFn,
  translateNormalizedText,
  Translations,
} from "./translation";
import { NormalizedText, normalizedText } from "./normalized-text";
import { TextTypes } from "./texts";

export type TranslateFn = TextTranslateFn<TextTypes>;

export function createTranslateFn(
  productKey: string,
  translations: Translations
): TranslateFn {
  return (text) =>
    translateNormalizedText(productKey, translations, normalizeText(text));
}

function normalizeText(text: TextTypes): NormalizedText {
  switch (text.type) {
    case "TextKey": {
      return normalizedText(text.key, text.params);
    }
    case "Dummy": {
      return normalizedText(text.key, text.params);
    }
    default: {
      return exhaustiveCheck(text);
    }
  }
}
