import { DocumentNode, print } from "graphql";
import { ActiveUser } from "@lcc/shared";
import { Cmd } from "@typescript-tea/core";
import * as HttpFetch from "./effect-managers/http-fetch";

export function buildAuthHeaders(accessToken: string): Record<string, string> {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
}

// export const graphQLQueryWithAuth =
//   (activeUser: ActiveUser) =>
//   <TData, TVariables, A>(
//     query: DocumentNode,
//     variables: TVariables,
//     successActionCreator: (response: TData) => A
//   ): Cmd<A> => {
//     const fetchOneCmd = HttpFetch.post(
//       buildAuthHeaders(activeUser.accessToken),
//       "/graphql?",
//       "json",
//       "application/json",
//       JSON.stringify({ query: print(query), variables }),
//       (result) => {
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         return successActionCreator((result as any).data as TData);
//       }
//     );
//     return fetchOneCmd;
//   };

export const graphQLProductQueryWithAuth =
  (activeUser: ActiveUser) =>
  <TData, TVariables, A>(
    query: DocumentNode,
    variables: TVariables,
    successActionCreator: (response: TData) => A
  ): Cmd<A> => {
    const fetchOneCmd = HttpFetch.post(
      buildAuthHeaders(activeUser.accessToken),
      // eslint-disable-next-line no-restricted-globals
      // "/pds/graphql?",
      // eslint-disable-next-line no-restricted-globals
      `https://public-api.promaster.systemair.com/graphql/bd37fd6e-3efe-4e49-9a0f-d73638963529/${location.hostname}`,
      "json",
      "application/json",
      JSON.stringify({ query: print(query), variables }),
      (result) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return successActionCreator((result as any).data);
      }
    );
    return fetchOneCmd;
  };

// export const graphQLMutationWithAuth =
//   (activeUser: ActiveUser) =>
//   <TData, TVariables, A>(mutation: DocumentNode, variables: TVariables, onSuccess?: (response: TData) => A): Cmd<A> => {
//     const fetchOneCmd = HttpFetch.post(
//       buildAuthHeaders(activeUser.accessToken),
//       "/graphql?",
//       "json",
//       "application/json",
//       JSON.stringify({ query: print(mutation), variables }),
//       onSuccess &&
//         ((result) => {
//           // eslint-disable-next-line @typescript-eslint/no-explicit-any
//           return onSuccess((result as any).data as TData);
//         })
//     );
//     return fetchOneCmd;
//   };
