import { Unit, Amount } from "uom";
import { AnyQuantity } from "../uom";
import { roundTo } from "../utils";

export function amountAs(
  unit: Unit.Unit<AnyQuantity>,
  amount: Amount.Amount<AnyQuantity>,
  decimals: number | undefined = undefined
): number {
  if (decimals) {
    return amount && roundTo(Amount.valueAs(unit, amount), decimals);
  } else {
    return amount && Amount.valueAs(unit, amount);
  }
}

export function amountAsFixed(
  unit: Unit.Unit<AnyQuantity>,
  amount: Amount.Amount<AnyQuantity>,
  decimals: number | undefined = undefined
): string {
  if (decimals) {
    return amount && Amount.valueAs(unit, amount).toFixed(Math.min(amount.decimalCount, decimals));
  } else {
    return amount && Amount.valueAs(unit, amount).toFixed(amount.decimalCount);
  }
}

export function convertTo(value: number, fromUnit: Unit.Unit<unknown>, toUnit: Unit.Unit<unknown>): number {
  return Amount.valueAs(toUnit, Amount.create(value, fromUnit));
}
