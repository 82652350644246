import { Unit, UnitFormat, UnitMap } from "uom";
import { Units, UnitsFormat } from "uom-units";
import { UnitFormatMap, getUnitFormat as getUnitFormatOld } from "uom/lib-cjs/unit-format";
import { AnyQuantity } from "../uom";

export const getUnit = <T extends AnyQuantity>(quantity: T): Unit.Unit<T> => {
  return UnitMap.getUnitsForQuantity(quantity, Units)[0] as Unit.Unit<T>;
};

export const getUnits = <T extends AnyQuantity>(
  quantity: T,
  fieldName?: string | undefined
): readonly Unit.Unit<T>[] => {
  let temp = UnitMap.getUnitsForQuantity(quantity, Units) as Unit.Unit<T>[];

  if (fieldName === "lifeExpectancy") {
    temp = temp.filter((unit) => unit.name === "Year");
  }

  if (fieldName === "operationTime") {
    temp = temp.filter((unit) => unit.name === "Hour");
  }

  if (quantity === "Dimensionless") {
    temp = temp.filter((unit) => unit.name === "Percent");
  }

  if (quantity === "Temperature") {
    temp = temp.filter((unit) => unit.name !== "Rankine");
  }

  if (quantity === "Temperature") {
    temp = temp.filter((unit) => unit.name !== "Kelvin");
  }

  return temp;
};

export function getUnitFormat(unit: Unit.Unit<unknown>, unitsFormat: UnitFormatMap): UnitFormat.UnitFormat | undefined {
  if (unit.name === "WattPerCubicMeterPerSecond") {
    return { label: "W/l/s", decimalCount: 3 };
  }

  return getUnitFormatOld(unit, unitsFormat);
}

export function getUnitSymbol(unit: Unit.Unit<AnyQuantity>): string {
  return unit ? getUnitFormat(unit, UnitsFormat)?.label || unit?.name : "";
}
