import { AhuUserInput, CalculationAhuInputs, CalculationInputs, InputTables, UserInput } from "../types";
import { calculateAnnualOperationTime } from "./misc";

export function createUserInput(calculationInputs: CalculationInputs, inputTables: InputTables | undefined): UserInput {
  const {
    expectedReturn,
    currentRealEstateValue,
    currentEnergyPrice,
    currentHeatingPrice,
    currency,
    expectedIncreaseEnergyPrice,
    expectedIncreaseHeatingPrice,
    realCalculatedInterest,
    lifeExpectancy,
    annualAverageTemperature,
    carbonEmissionCountry,
  } = calculationInputs;
  const annualOperationTime = calculateAnnualOperationTime(
    calculationInputs.annualOperationTimeInput,
    calculationInputs.annualOperationTime,
    calculationInputs.annualOperationTimeWorkdays,
    calculationInputs.annualOperationTimeWeekends
  );
  const carbonEmission =
    inputTables?.CarbonEmissionPerCountry &&
    inputTables.CarbonEmissionPerCountry.find((row) => row.id === calculationInputs.carbonEmissionCountry);

  const supplyAirTemperature = calculationInputs.supplyAirTemperature;
  const exhaustAirTemperature = calculationInputs.exhaustAirTemperature;
  const projectName = calculationInputs.projectName || "";

  const capitalizeFirstLetterInCountryName = calculationInputs?.annualAverageTemperatureCountry
    ?.toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .split("-")
    .join(" ");

  const removalOfDashInLocationName = calculationInputs?.annualAverageTemperatureLocation?.split("-").join(" ");

  return {
    projectName,
    expectedReturn,
    currentRealEstateValue,
    currentEnergyPrice,
    currentHeatingPrice,
    currency,
    expectedIncreaseEnergyPrice,
    expectedIncreaseHeatingPrice,
    realCalculatedInterest,
    lifeExpectancy,
    annualOperationTime,
    annualAverageTemperature,
    carbonEmissionCountry,
    supplyAirTemperature,
    exhaustAirTemperature,
    carbonEmissionElectricityFactor: carbonEmission?.electricity || undefined,
    carbonEmissionHeatingFactor: carbonEmission?.heating ? carbonEmission?.heating * 500 : undefined,
    countrySelection: capitalizeFirstLetterInCountryName || undefined,
    locationSelection: removalOfDashInLocationName || undefined,
  };
}

export function createAhuUserInput(
  ahuInputs: CalculationAhuInputs,
  supplyFanLCC: number | undefined,
  extractFanLCC: number | undefined
): AhuUserInput {
  const { unitName, offerAmount, correctionVariableFlowTemp, fanFlowType } = ahuInputs;

  const correctionVariableFlow = fanFlowType === "variable" ? ahuInputs.correctionVariableFlow : undefined;

  const nominalFlowSupply = supplyFanLCC ? ahuInputs.nominalFlowSupply : undefined;
  const pressureDropSupply = supplyFanLCC ? ahuInputs.pressureDropSupply : undefined;
  const specificFanPowerSupply = supplyFanLCC ? ahuInputs.specificFanPowerSupply : undefined;

  const nominalFlowExtract = extractFanLCC ? ahuInputs.nominalFlowExtract : undefined;
  const pressureDropExtract = extractFanLCC ? ahuInputs.pressureDropExtract : undefined;
  const specificFanPowerExtract = extractFanLCC ? ahuInputs.specificFanPowerExtract : undefined;

  const heating = ahuInputs.heatingType && ahuInputs.heatingType !== "none";
  const heatingType = heating ? ahuInputs.heatingType : undefined;
  const tMinOutletExhaustExchanger = ahuInputs.tMinOutletExhaustExchanger;
  const temperatureEfficiency = heating ? ahuInputs.temperatureEfficiency : undefined;
  const selectedForCompare = ahuInputs.selectedForCompare;

  return {
    unitName,
    offerAmount,
    selectedForCompare,
    correctionVariableFlow,
    correctionVariableFlowTemp,
    specificFanPowerSupply,
    nominalFlowSupply,
    pressureDropSupply,
    tMinOutletExhaustExchanger,
    specificFanPowerExtract,
    nominalFlowExtract,
    pressureDropExtract,
    temperatureEfficiency,
    fanFlowType,
    heatingType,
  };
}
